import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PageIndex";
import { imageUrl } from "../../../../../config/dataService";
import ReactPlayer from "react-player";
import { getJoinedContest } from "../../../../../redux/slices/adminService";
import numeral from "numeral";

const UserJoindContest = ({ userId }) => {
    //all state
    const [pageData, setPageData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const [currentVideoId, setCurrentVideoId] = useState(null);
    const [loader, setLoader] = useState(true)
    const [contestData, setContestData] = useState([])


    const getSingleJoinedContest = () => {
        getJoinedContest({ userId: userId }).then((response) => {
            if (response?.status == 200) {
                setContestData(response?.data);
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
            } else {
                setLoader(false);
                setContestData({});
            }
        });
    };

    useEffect(() => {
        getSingleJoinedContest()
    }, [])

    useEffect(() => {
        const filterData = contestData?.filter((item) => {
            return search
                ? item?.contestName
                    ?.toLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                new Date(item?.endDate)
                    ?.toLocaleDateString("en-GB")
                    ?.toString()
                    .includes(search?.toLowerCase()) ||
                new Date(item?.startDate)
                    ?.toLocaleDateString("en-GB")
                    ?.toString()
                    .includes(search?.toLowerCase()) ||
                new Date(item?.resultDate)
                    ?.toLocaleDateString("en-GB")
                    ?.toString()
                    .includes(search?.toLowerCase()) ||
                item?.contestID
                    ?.toLowerCase()
                    .includes(search?.toLocaleLowerCase()) ||
                item?.fiatType?.coins
                    ?.toLowerCase()
                    .includes(search?.toLocaleLowerCase())

                : item;
        });
        setFilterData(filterData);
    }, [search, contestData]);


    const handleVideoClick = (row) => {
        if (row === currentVideoId) {
            setCurrentVideoId(null);
        } else {
            if (currentVideoId !== null) {
                setCurrentVideoId(null);
            }

            setCurrentVideoId(row);
        }
    };


    function formatDate(originalDateStr) {
        const originalDate = new Date(originalDateStr);
        const day = originalDate.getDate().toString().padStart(2, '0');
        const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
        const year = originalDate.getFullYear().toString()
        const formattedDateStr = `${day}/${month}/${year}`;
        return formattedDateStr;
    }
    return (
        <>
            <Index.Box className="dashboard-content">
                <Index.Box className="user-list-flex">
                    <Index.Box className="admin-page-title-main">
                        <Index.Typography
                            className="admin-page-title"
                            component="h2"
                            variant="h2"
                        >
                            Joined Contest
                        </Index.Typography>
                    </Index.Box>
                    <Index.Box className="userlist-btn-flex">
                        <Index.Box className="user-search-main">
                            <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder="Search"
                                        name="search"
                                        value={search}
                                        onChange={(e) => {
                                            const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                            setSearch(newValue);
                                        }}
                                    />
                                    <span className="search-icon-box">
                                        <img src={Index.Svg.search} className="search-icon" />
                                    </span>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="admin-dashboard-list-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                            display="grid"
                            className="display-row-userlist"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                            <Index.Box
                                gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                }}
                                className="grid-column"
                            >
                                <Index.Box className="admin-dash-box">
                                    <Index.Box className="page-table-main user-joined-table ">
                                        <Index.TableContainer
                                            component={Index.Paper}
                                            className="table-container"
                                        >
                                            <Index.Table
                                                sx={{ minWidth: 650 }}
                                                aria-label="simple table"
                                                className="table"
                                            >
                                                <Index.TableHead className="table-head cus-table-head">
                                                    <Index.TableRow className="table-row">
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            S.No.
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Media
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Contest Id
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Contest Name
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Contest Date
                                                        </Index.TableCell>

                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Contest End
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Contest Result
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Contest Status
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Courency Type
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Prize
                                                        </Index.TableCell>
                                                    </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody className="table-body">
                                                    {!loader ? (
                                                        pageData?.length > 0 ? (
                                                            pageData?.map((row, index) => (
                                                                <Index.TableRow
                                                                    key={row._id}
                                                                    sx={{
                                                                        "&:last-child td, &:last-child th": {
                                                                            border: 0,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        scope="row"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.sNo}
                                                                    </Index.TableCell>

                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        scope="row"
                                                                        className="table-td "
                                                                        align="center"
                                                                        onClick={() => handleVideoClick(row?._id)}
                                                                    >
                                                                        <Index.Box className='video-swiper' >
                                                                            <ReactPlayer
                                                                                component="video"
                                                                                controls
                                                                                width={150}
                                                                                height={65}
                                                                                className="react-player"
                                                                                url={`${imageUrl}${row?.spots[0]?.reelId?.media}`}
                                                                                playing={row.media === currentVideoId ? true : false}
                                                                                onPlay={() => handleVideoClick(row?._id)}
                                                                            />
                                                                        </Index.Box>
                                                                    </Index.TableCell>

                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >

                                                                        {row?.contestID ? row?.contestID : "-"}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.contestName ? row?.contestName : "-"}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.startDate ? formatDate(row?.startDate) + ' ' : ''}
                                                                        {row?.startEntryTime ? row?.startEntryTime : ''}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.resultDate ? formatDate(row?.resultDate) + ' ' : ''}
                                                                        {row?.resultTime ? row?.resultTime : ''}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.endDate ? formatDate(row?.endDate) + ' ' : ''}
                                                                        {row?.endEntryTime ? row?.endEntryTime : ''}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {!row?.isClosed && row?.isCancel
                                                                            ? "Cancelled"
                                                                            : row?.isAnnounceWinner
                                                                                ? "Completed"
                                                                                : "Started"}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >

                                                                        {row?.fiatType?.coins}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >

                                                                        {/* {row?.coin} */}
                                                                        {row?.coin
                                                                            ? parseFloat(row?.coin?.toFixed(8)): 0}
                                                                        {/* {row?.coin
                                                                            ? Number.isInteger(row?.coin)
                                                                                ? numeral(row?.coin).format('0')
                                                                                : numeral(row?.coin).format('0.000a')
                                                                            : 0} */}
                                                                    </Index.TableCell>
                                                                </Index.TableRow>
                                                            ))
                                                        ) : (
                                                            <PageIndex.RecordNotFound colSpan={9} />
                                                        )
                                                    ) : (
                                                        <PageIndex.TableLoader colSpan={9} />
                                                    )}
                                                </Index.TableBody>
                                            </Index.Table>
                                        </Index.TableContainer>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            {/* Pagination */}
            <PageIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
            />
        </>
    );
};

export default UserJoindContest;
