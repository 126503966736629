import React from 'react';
import Index from '../../../Index';


const LoadingSpinner = () => {
  return (
    <Index.Box  className='spinner-main'>
      <Index.Box className="loader"></Index.Box>
      <Index.Typography className="loading-text">Please wait...</Index.Typography>
    </Index.Box>
  );
};

export default LoadingSpinner;
