import React, { useEffect } from "react";
import Index from "../../../Index";
import { useState } from "react";
import PageIndex from "../../../PageIndex";
import {
  addSubAdmin,
  editSubAdmin,
  getAllRoleMaster,
} from "../../../../redux/slices/adminService";


const AddSubAdmin = () => {
  const navigate = PageIndex.useNavigate();
  const params = PageIndex.useLocation();
  const editData = params?.state;

  //all state
  const [showPassword, setShowPassword] = useState(false);
  const [confirmshowPassword, setconfirmShowPassword] = useState(false);
  const [roleTypeData, setRoleTypeData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true)
  let initialValues;
  if (editData) {
    initialValues = {
      name: editData ? editData?.name : "",
      email: editData ? editData?.email : "",
      phoneNumber: editData ? editData?.phoneNumber : "",
      address: editData ? editData?.address : "",
      roleType: editData ? editData?.roleType?._id : "",
      isEdit: true,
    };
  } else {
    initialValues = {
      name: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      roleType: "",
      isEdit: false,
      address: '',
    };
  }
  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((show) => !show);
  };
  const handleClickConfirmShowPassword = (e) => {
    e.preventDefault();
    setconfirmShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (values) => {
    setLoader(true);
    const urlencoded = new URLSearchParams();
    if (editData) {
      urlencoded.append("subAdminId", editData?._id);
    }
    urlencoded.append("name", values?.name);
    urlencoded.append("email", values?.email);
    urlencoded.append("phoneNumber", values?.phoneNumber);
    urlencoded.append("roleType", values?.roleType);
    urlencoded.append("address", values?.address);
    if (editData == undefined) {
      urlencoded.append("password", values?.password);
      urlencoded.append("confirmPassword", values?.confirmPassword);
    }

    if (editData) {
      editSubAdmin(urlencoded).then((data) => {
        if (data?.status == 200) {
          setLoader(false);
          navigate("/dashboard/admin-master-list");
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 3000);
        }
      });
    } else {
      addSubAdmin(urlencoded).then((data) => {
        if (data?.status == 200) {
          setLoader(false);
          navigate("/dashboard/admin-master-list");
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 3000);
        }
      });
    }
  };

  const listOfRoleMaster = () => {
    getAllRoleMaster().then((res) => {
      if (res?.status === 200) {
        setRoleTypeData(res?.data);
        setLoading(false)
      } else {
        setRoleTypeData([]);
        setLoading(false)
      }
    });
  };

  useEffect(() => {
    listOfRoleMaster();
  }, []);

  return (
    <>
      {!loading ? <Index.Box className="dashboard-content add-user-containt">
        <PageIndex.BackButton onClick={() => navigate("/dashboard/admin-master-list")} />
        <Index.Box className="barge-common-box">
          <Index.Typography
            className="admin-page-title cus-heading"
            component="h2"
            variant="h2"
          >
            {editData ? "Edit" : "Add"} Admin Master
          </Index.Typography>

          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={PageIndex.addSubAdminSchema(initialValues)}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              setFieldValue,
              touched,
            }) => (
              <PageIndex.Form>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Name <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={values.name}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  // .replace(/[^a-zA-Z]+/g, "")
                                  .replace(/\s\s+/g, " ");
                                setFieldValue("name", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.name ? touched?.name : undefined
                              )}
                              helperText={
                                touched?.name ? errors?.name : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Email <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth" 
                              className="form-control email-input "
                              placeholder="Email"
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("email", newValue);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.email ? touched?.email : undefined
                              )}
                              helperText={
                                touched?.email ? errors?.email : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Phone Number{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Phone Number"
                              name="phoneNumber"
                              value={values.phoneNumber}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\D+/g,
                                  ""
                                );
                                if (newValue.length <= 10) {
                                  setFieldValue("phoneNumber", newValue);
                                }
                              }}
                              error={Boolean(
                                errors?.phoneNumber
                                  ? touched?.phoneNumber
                                  : undefined
                              )}
                              helperText={
                                touched?.phoneNumber
                                  ? errors?.phoneNumber
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      {!editData && (
                        <>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Password{" "}
                                <span className="mandatory-asterisk">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  size="small"
                                  name="password"
                                  placeholder="Password"
                                  className="form-control input-with-radius password-form-control"
                                  type={showPassword ? "text" : "password"}
                                  // label="Password"
                                  variant="outlined"
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props",
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props",
                                  }}
                                  // onBlur={handleBlur}
                                  value={values.password}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /\s/g,
                                      ""
                                    );
                                    setFieldValue("password", newValue);
                                  }}
                                  InputProps={{
                                    // <-- This is where the toggle button is added.
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          className="passwrd-eye"
                                          aria-label="toggle password visibility"
                                          onClick={(e) =>
                                            handleClickShowPassword(e)
                                          }
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {showPassword ? (
                                            <Index.Visibility />
                                          ) : (
                                            <Index.VisibilityOff />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                  error={Boolean(
                                    errors?.password
                                      ? touched?.password
                                      : undefined
                                  )}
                                  helperText={
                                    touched?.password
                                      ? errors?.password
                                      : undefined
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box add-user-input">
                              <Index.FormHelperText className="form-lable">
                                Confirm Password{" "}
                                <span className="mandatory-asterisk">*</span>
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  size="small"
                                  name="confirmPassword"
                                  placeholder="Confirm Password"
                                  className="form-control input-with-radius password-form-control"
                                  type={
                                    confirmshowPassword ? "text" : "password"
                                  }
                                  // label="Password"
                                  variant="outlined"
                                  autoComplete="off"
                                  inputProps={{
                                    className: "input_props",
                                  }}
                                  InputLabelProps={{
                                    className: "add-formlabel",
                                  }}
                                  FormHelperTextProps={{
                                    className: "input_label_props",
                                  }}
                                  // onBlur={handleBlur}
                                  value={values.confirmPassword}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      /\s/g,
                                      ""
                                    );
                                    setFieldValue("confirmPassword", newValue);
                                  }}
                                  InputProps={{
                                    endAdornment: (
                                      <Index.InputAdornment position="end">
                                        <Index.IconButton
                                          className="passwrd-eye"
                                          aria-label="toggle password visibility"
                                          onClick={(e) =>
                                            handleClickConfirmShowPassword(e)
                                          }
                                          onMouseDown={handleMouseDownPassword}
                                          edge="end"
                                        >
                                          {confirmshowPassword ? (
                                            <Index.Visibility />
                                          ) : (
                                            <Index.VisibilityOff />
                                          )}
                                        </Index.IconButton>
                                      </Index.InputAdornment>
                                    ),
                                  }}
                                  error={Boolean(
                                    errors?.confirmPassword
                                      ? touched?.confirmPassword
                                      : undefined
                                  )}
                                  helperText={
                                    touched?.confirmPassword
                                      ? errors?.confirmPassword
                                      : undefined
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </>
                      )}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Role Type{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.FormControl className="radio-main">
                              <Index.Select
                                className="form-control select-drop-list"
                                name="roleType"
                                value={values?.roleType}
                                defaultValue={values ? values?.roleType : ""}
                                onChange={(e) => {
                                  setFieldValue("roleType", e.target.value);
                                }}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <Index.MenuItem
                                  value=""
                                  // disabled
                                  className="menuitem"
                                >
                                  Select Role Type
                                </Index.MenuItem>

                                {roleTypeData &&
                                  roleTypeData?.map((val) => (
                                    <Index.MenuItem
                                      value={val?._id}
                                      key={val?._id}
                                      className="menuitem"
                                    >
                                      {val?.roleName}
                                    </Index.MenuItem>
                                  ))}
                              </Index.Select>
                            </Index.FormControl>
                            {errors.roleType && touched.roleType && (
                              <p className="error-text">{errors.roleType}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>


                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Address{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextareaAutosizeed
                              fullWidth
                              id="fullWidth"
                              // type="text"
                              className="form-control contest-text-autosize"
                              placeholder="Address"
                              name="address"
                              value={values.address}
                              onChange={handleChange}
                            />
                          </Index.Box>
                          {errors?.address && touched?.address && (
                            <p className="custom-error-tag">
                              {errors?.address}
                            </p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="user-btn-flex">
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.Button
                        className="save-user-btn border-btn"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? (
                          <PageIndex.ButtonLoader
                            color="white"
                            size={14}
                            loading={loader}
                          />
                        ) : (
                          <>
                            <img
                              src={Index.Svg.save}
                              className="user-save-icon"
                              alt="Save Icon"
                            />
                            {editData ? "Update" : "Save"}
                          </>
                        )}
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Box>
        : <PageIndex.Loading />}
    </>
  );
};

export default AddSubAdmin;
