import dummyImage from "./images/jpg/dummy-image.jpg";
import login12 from "./images/jpg/login12.jpg";
import authlogo from "./images/jpg/authLogo.png";
import banner_image from "./images/jpg/contest_banner.jpg";
const Jpg = {
  dummyImage,
  login12,
  authlogo,
  banner_image,
};
export default Jpg;
