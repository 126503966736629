import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { autoLogout, deleteUserMaster, getSingleUserByAdmin, suspendUser } from "../../../../redux/slices/adminService";
import UserPostData from "./UserRequest/UserPostData";
import UserHighlightData from "./UserRequest/UserHighlightData";
import UserReelsData from "./UserRequest/UserReelsData";
import UserActivity from "./UserRequest/UserActivity";
import { imageUrl } from "../../../../config/dataService";
import UserJoindContest from "./UserRequest/UserJoinedContest";
import { logout, updateRolePermission } from "../../../../redux/slices/AdminSlice";
import numeral from "numeral";


const UserView = () => {
  const params = PageIndex.useLocation();
  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch()
  const userId = params?.state?.data;
  const tabValue = params?.state?.btnValue;
  //all state
  const [value, setValue] = React.useState(0);
  const [valueSecondRowTab, setValueSecondRowTab] = useState()
  const [userdata, setUserData] = useState({});
  const [loader, setLoader] = useState(true);
  const { rolePermission, token } = PageIndex.useSelector((state) => state.admin);
  const [deleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = useState(false)
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false)
  const [openSuspend, setOpenSuspend] = useState(false);
  const [openDiamondTickModel, setOpenDiamondTickModel] = useState(false);
  const [suspendUserId, setSuspendUserId] = useState();
  const [imagePath, setImagePath] = useState('')
  const [openImage, setImageOpen] = useState(false)

  //api
  const handleImageClose = () => {
    setImageOpen(false)
    setImagePath('')

  }
  const handleOpenImage = (image) => {
    if (image) {
      setImageOpen(true)
      setImagePath(image)
    }
  }

  const getSingleUserByAdmindata = () => {
    getSingleUserByAdmin(userId).then((response) => {
      if (response?.status == 200) {
        setUserData(response?.data?.user);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      } else {
        setLoader(false);
        setUserData({});
      }

    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }



  const handleChange = (event, newValue) => {
    setValue(newValue);
    setValueSecondRowTab()
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      }
      else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    })
  };


  const handleChangeSecondRowTab = (event, newValue) => {
    setValueSecondRowTab(newValue);
    setValue()
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleOpenSuspend = (id) => {
    setOpenSuspend(true);
    setSuspendUserId(id);
  };
  const handleCloseSuspend = () => setOpenSuspend(false);
  const handleOpenDiamondTickModel = (id) => {
    setOpenDiamondTickModel(true);
    setSuspendUserId(id)

  };
  const handleCloseDiamondTickModel = () => {
    setOpenDiamondTickModel(false);
  };

  const deleteUser = (id) => {
    setIsDeleteButtonDisabled(true);
    deleteUserMaster({ id: deleteId }).then((res) => {
      handleCloseDelete();
      setIsDeleteButtonDisabled(false);
      if (res === 200) {
        navigate("/dashboard/user-list");
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };

  const suspendUserByAdmin = () => {
    setIsDeleteButtonDisabled(true)
    suspendUser(suspendUserId).then((res) => {

      setIsDeleteButtonDisabled(false)
      if (res?.status === 200) {
        handleCloseSuspend();
        setUserData(prevData => ({
          ...prevData,
          isSuspended: res?.data?.isSuspended
        }))
      }
      else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false)
        }, 3000);
      }
    });
  };




  useEffect(() => {
    getSingleUserByAdmindata();
  }, []);
  return (
    <>
      <Index.Box className='user-details-back-btn'>

        <PageIndex.BackButton onClick={() => navigate(params?.state?.pathName !== "/dashboard/user-view" ?
          params?.state?.pathName : '/dashboard/user-list', {
          state: {
            data: userId,
            postId: params?.state?.postId,
            reportId: params?.state?.reportId,
            btnValue: tabValue
          }
        })} />
      </Index.Box>
      <Index.Box className="user-detail-section">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
          // gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 3",
              }}
              className="grid-column"
            >
              <Index.Box className="left-wrape">
                <Index.Box className="profile-wrape">
                  <Index.Box className="image-wrape"
                    onClick={() => { handleOpenImage(userdata?.profile) }}
                  >
                    <img
                      src={userdata?.profile ? `${imageUrl}${userdata?.profile}` : PageIndex?.Jpg?.dummyImage}
                      className="user-post-post-img"
                    />

                  </Index.Box>
                </Index.Box>
                <Index.Box className="detail">
                  <label>Full Name</label>
                  <p> {userdata?.name ? userdata?.name : "-"}</p>
                  <label> Email</label>
                  <p> {userdata?.email ? userdata?.email : "-"}</p>
                  <label> Username</label>
                  <p>
                    {userdata?.userName ? userdata?.userName : "-"}
                  </p>
                  <label> Phone Number</label>
                  <p>
                    {userdata?.phoneNumber ? `${userdata?.countryCode ? userdata?.countryCode : ''} ${userdata?.phoneNumber}` : "-"}
                  </p>
                  <label>Engagement Ratio</label>
                  <p> {userdata?.allLikeComment ? `${userdata?.allLikeComment?.toFixed(2)}%` : '-'}</p>
                  <label>Country</label>
                  <p> {userdata?.country ? userdata?.country : "-"}</p>
                  <label>Bio</label>
                  <p>
                    {userdata?.Bio &&
                      userdata?.Bio?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={userdata?.Bio}
                      >
                        {userdata?.Bio.slice(0, 25) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      userdata?.Bio || "-"
                    )}
                  </p>
                  <label>Links</label>
                  <p>
                    {userdata?.links &&
                      userdata?.links?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={userdata?.links?.split(',').map((item) => <p>{item}</p>)}
                      >
                        {/* {userdata?.links?.split(',').map((item)=><span>{item.slice(0, 20) + "..."}</span>)} */}
                        {userdata?.links.slice(0, 25) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      userdata?.links?.Bio || "-"
                    )}

                  </p>

                  <label> Diamond Tick</label>
                  <p>{userdata?.diamondTick === true ? "Yes" : "No"}</p>

                  {userdata?.diamondTick === true ? (
                    <>
                      <label> Diamond Tick Validity</label>
                      <p>{userdata?.validityDate ? userdata?.validityDate : "-"}</p></>) : ""}


                  <label> Suspended</label>
                  <p>{userdata?.isSuspended == true ? "Yes" : "No"}</p>

                  <label> Following Count</label>
                  <p>

                    {userdata?.followingCount
                      ? Number.isInteger(userdata?.followingCount) && userdata?.followingCount < 1000
                        ? numeral(userdata?.followingCount).format('0')
                        : numeral(userdata?.followingCount).format('0.000a')
                      : 0}
                  </p>
                  <label>  Followers Count</label>
                  <p>
                    {/* {userdata?.followersCount} */}
                    {userdata?.followersCount && userdata?.followingCount < 1000
                      ? Number.isInteger(userdata?.followersCount)
                        ? numeral(userdata?.followersCount).format('0')
                        : numeral(userdata?.followersCount).format('0.000a')
                      : 0}

                  </p>
                </Index.Box>
                {
                  (rolePermission &&
                    rolePermission?.roleType?.rolePermission?.includes(
                      "UserReport_edit"
                    )) ||
                    rolePermission?.roleType?.rolePermission?.includes(
                      "UserReport_delete"
                    ) ||
                    (rolePermission && rolePermission?.isAdmin === true) ? (
                    <>
                      <Index.Box className="userdata-btn-flex">

                        {rolePermission?.roleType?.rolePermission?.includes(
                          "UserMaster_delete"
                        ) ||
                          (rolePermission && rolePermission?.isAdmin === true) ? (
                          <>
                            <PageIndex.LightTooltip title="Delete">
                              <Index.IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                onClick={() => {
                                  handleOpenDelete(userdata?._id);
                                }}
                              >
                                <Index.DeleteIcon className="usermailwarning-img" />
                              </Index.IconButton>
                            </PageIndex.LightTooltip>

                          </>
                        ) : (
                          ""
                        )}

                        {(rolePermission && rolePermission?.isAdmin === true) ? (
                          <PageIndex.LightTooltip title={userdata?.isSuspended != true ? "Suspend" : "Unsuspend"}>
                            <Index.IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                              // disabled={userdata?.isSuspended === true}
                              onClick={() => {
                                handleOpenSuspend(userdata?._id);
                              }}
                            >

                              <img
                                src={userdata?.isSuspended != true ? Index.Png.blockUser : Index.Png.unBlockUser}
                                className="dash-icons"
                                alt="dashboard icon"
                              />
                              {/* <Index.DeleteIcon className="usermailwarning-img" /> */}
                            </Index.IconButton>
                          </PageIndex.LightTooltip>) : ""}

                        {!userdata?.isSuspended && <PageIndex.LightTooltip title="Provide Diamond Tick">
                          <Index.IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            onClick={() => {
                              handleOpenDiamondTickModel(userdata?._id);
                            }}
                          >

                            <Index.DiamondIcon className={userdata?.diamondTick !== true ? "usermailwarning-img" : "diamond-tick-active"} />
                          </Index.IconButton>
                        </PageIndex.LightTooltip>}
                      </Index.Box>
                    </>
                  ) : (
                    ""
                  )}
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 9",
              }}
              className="grid-column"
            >
              <Index.Box className="right-wrape">

                <Index.Box className="table-wrape">
                  <Index.Box className="external-tab-box">




                    <Index.Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="admin-tabs-main"
                    >
                      <Index.Tab
                        label="Post"
                        {...a11yProps(0)}
                        className="admin-tab  user-view-tab"
                      />

                      <Index.Tab
                        label="Highlights"
                        {...a11yProps(1)}
                        className="admin-tab  user-view-tab"
                      />
                      <Index.Tab
                        label="Cryp"
                        // label="Reel"
                        {...a11yProps(2)}
                        className="admin-tab  user-view-tab"
                      />


                      <Index.Tab
                        // title="Reel"
                        label="User Activity"
                        {...a11yProps(3)}
                        className="admin-tab  user-view-tab"
                      />
                      <Index.Tab
                        // title="Reel"
                        label="Fiat wallet"
                        {...a11yProps(4)}
                        className="admin-tab  user-view-tab"
                        onClick={() => navigate('/dashboard/view-user-fiat-wallet', {
                          state: {
                            data: params?.state?.data,
                            pathName: params.pathname,
                            TypeOfCurrency: "Fiat"
                          }
                        })}

                      />

                      <Index.Tab
                        // title="Reel"
                        label="Crypto  wallet"
                        {...a11yProps(5)}
                        className="admin-tab user-view-tab"
                        onClick={() => navigate('/dashboard/view-user-crypto-wallet', {
                          state: {
                            data: params?.state?.data,
                            pathName: params.pathname,
                            TypeOfCurrency: "Crypto"
                          }
                        })}
                      />

                    </Index.Tabs>

                    <Index.Tabs
                      value={valueSecondRowTab}
                      onChange={handleChangeSecondRowTab}
                      aria-label="basic tabs example"
                      className="admin-tabs-main"
                    >
                      <Index.Tab
                        label="Contest"
                        {...a11yProps(0)}
                        className="admin-tab  user-view-tab"
                      />

                    </Index.Tabs>
                  </Index.Box>
                  <Index.Box className="barge-common-box">
                    <Index.Box className="common-box">
                      {value == 0 ? (
                        <UserPostData
                          postdata={userdata?.posts}
                          loader={loader}
                          userName={userdata?.userName}
                          setImageOpen={setImageOpen}
                          setImagePath={setImagePath}

                        />
                      ) : value == 1 ? (
                        <UserHighlightData
                          hightLightData={userdata?.HighlightsData}
                          loader={loader}
                          setImageOpen={setImageOpen}
                          setImagePath={setImagePath}
                          userName={userdata?.userName}
                        />
                      ) : value == 2 ? (
                        <UserReelsData reelsData={userdata?.ReelsData}
                          userName={userdata?.userName}
                        />
                      ) : valueSecondRowTab == 0 ?
                        <UserJoindContest userId={userId} /> :
                        <UserActivity userId={userId} loader={loader} />
                      }
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteUser}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
      <PageIndex.SuspendModal
        openSuspend={openSuspend}
        handleCloseSuspend={handleCloseSuspend}
        handleSuspendRecord={suspendUserByAdmin}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        setUserData={setUserData}
        userData={userdata}
      />
      <PageIndex.DiamondTickModel
        openDiamondTickModel={openDiamondTickModel}
        handleCloseDiamondTickModel={handleCloseDiamondTickModel}
        userData={userdata}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        setIsDeleteButtonDisabled={setIsDeleteButtonDisabled}
        setUserData={setUserData}
      />
      <PageIndex.ImageViewModel
        handleClose={handleImageClose}
        open={openImage}
        image={imagePath}
      />
    </>
  );
};

export default UserView;
