import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { getAllFollowingListOfUser } from "../../../../../redux/slices/adminService";
import PageIndex from "../../../../PageIndex";
import Index from "../../../../Index";

const UserFollowingList = ({ userId }) => {
  const [followingListData, setUserFollowingListData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);

  const fetchAllSubAdminList = () => {
    setLoader(true);

    getAllFollowingListOfUser({ currentPage, search, rowsPerPage, userId }).then(
      (data) => {
        if (data?.status == 200) {
          if (data?.status == 200) {
            setUserFollowingListData(data?.data);
            if (data?.currentPage) {
              setCurrentPage(data?.currentPage);
            }
            setPageCount(data?.totalCount ? data?.totalCount : 0);
            setLoader(false);
          }
          // setLoader(false);
          // setUserFollowingListData(response?.data);
          // setCurrentPage(response?.currentPage);
          // setPageCount(response?.totalCount ? response?.totalCount : 0);
        } else {
          setLoader(false);
          setUserFollowingListData([]);
        }
      }
    );
  };

  useEffect(() => {
    const debouncedFetch = debounce(fetchAllSubAdminList, 300);
    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchAllSubAdminList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                User Following List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      // name="search"
                      autoComplete="search"
                      value={search}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setCurrentPage(1);
                        setSearch(newValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img src={Index.Svg.search} className="search-icon" />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="admin-sub-master-table-main action-column page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head cus-table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                S.No
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Username
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Email
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Phone Number
                              </Index.TableCell>
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {!loader ? (
                              followingListData?.length > 0 ? (
                                followingListData?.map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.following_to?.name}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.following_to?.userName}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.following_to?.email ? row?.following_to?.email : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.following_to?.phoneNumber ? row?.following_to?.phoneNumber : "-"}
                                    </Index.TableCell>

                                  </Index.TableRow>
                                ))
                              ) : (
                                <PageIndex.RecordNotFound colSpan={7} />
                              )
                            ) : (
                              <PageIndex.TableLoader colSpan={7} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {followingListData?.length ? (
            <Index.Box className="pagination-main">
              <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default UserFollowingList;
