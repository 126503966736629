import React, { useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";

const ViewRule = () => {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const viewData = location?.state?.row;

  return (
    <>
      {!loading ? (
        <Index.Box className="dashboard-content add-user-containt">
          <PageIndex.BackButton
            onClick={() => navigate("/dashboard/rule-list")}
          />

          <Index.Box className="barge-common-box">
            <Index.Box className="flex-all user-list-inner-flex">
              <Index.Typography
                className="admin-page-title cus-heading"
                component="h2"
                variant="h2"
              >
                View Rule
              </Index.Typography>
            </Index.Box>

            <Index.Box className="add-user-data-main">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-add-user"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="input-box add-user-input">
                      <Index.FormHelperText className="form-lable">
                        Rule Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="form-control"
                          value={viewData?.ruleName}
                          disabled
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box sx={{ width: 1 }} className="grid-main">
                {viewData?.rules.map((row, index) => (
                  <Index.Box
                    display="grid"
                    className="display-row-add-user"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    key={index}
                  >
                    {console.log(row, 766)}
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Total Number of Entries{" "}
                        </Index.FormHelperText>
                        <Index.Box className="form-group rule-flex-box">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control email-input "
                            value={row?.numberOfEnteries}
                            disabled
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Total Number of Winners{" "}
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            className="form-control"
                            value={row?.numberOfWinners}
                            disabled
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    {row?.winningPrice?.map((item) => (
                      <>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Rank
                            </Index.FormHelperText>
                            <Index.Box className="form-group rule-flex-box">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                type="text"
                                className="form-control"
                                value={item.rank}
                                disabled
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Price Distribution
                            </Index.FormHelperText>
                            <Index.Box className="form-group rule-flex-box">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                type="text"
                                className="form-control"
                                value={item.prize}
                                disabled
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ))}
                  </Index.Box>
                ))}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <PageIndex.Loading />
      )}
    </>
  );
};

export default ViewRule;
