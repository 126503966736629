import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  addUpdatePlatformKey,
  addUserMaster,
  getPlatformFee,
} from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";

export default function AddPlatformFee() {
  
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [platformFee, setPlatformFee] = useState({});
  const [loader, setLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  let initialValues;
  if (platformFee) {
    initialValues = {
      withdrawalFee: platformFee?.withdrawalFee,
      sendFiatFee: platformFee?.sendFiatFee,
      sendCryptoFee: platformFee?.sendCryptoFee,
      convertToFiat: platformFee?.convertToFiat,
      convertToCrypto: platformFee?.convertToCrypto,
      thirdPartyURL: platformFee?.thirdPartyURL,
      apiKey: platformFee?.apiKey,
      isEdit: false,
    };
  } else {
    initialValues = {
      platformFee: "",
      sendFiatFee: "",
      sendCryptoFee: "",
      convertToFiat: "",
      convertToCrypto: "",
      thirdPartyURL: "",
      apiKey: "",
      isEdit: false,
    };
  }

  const handleSubmit = async (values) => {
    setIsDisabled(true);
    addUpdatePlatformKey(values).then((data) => {
      if (data?.status === 200) {
        setLoader(false);
        setPlatformFee(data?.data?.platformFee);
        setTimeout(() => {
          setIsDisabled(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);
      }
    });
  };
  const fetchUserList = (search) => {
    setLoader(true);
    getPlatformFee().then((data) => {
      if (data?.status == 200) {
        setPlatformFee(data?.data?.[0]);
        setTimeout(() => {
          setLoader(false);
        }, 0);
      } else {
        setPlatformFee("");
        setTimeout(() => {
          setLoader(false);
        }, 20);
      }
    });
  };
  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <>
      {!loader ? (
        <Index.Box className="dashboard-content ">
          
          <Index.Box className="barge-common-box">
            {/* <Index.Box className="page-border"> */}

            <Index.Typography
              className="admin-page-title cus-heading"
              component="h2"
              variant="h2"
            >
              Configuration
            </Index.Typography>
            <PageIndex.Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={PageIndex.addPlatformFeeSchema}
            >
              {({
                values,
                handleBlur,
                errors,
                setFieldValue,
                touched,
              }) => (
                <PageIndex.Form>
                  <Index.Box className="add-user-data-main">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Typography
                            className="admin-page-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            Sending Charges
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Platform charge for sending (Fiat)(%){" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Send Fiat Charge"
                                name="sendFiatFee"
                                type="text"
                                value={values.sendFiatFee}
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  inputValue = inputValue.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );

                                  if (
                                    inputValue.startsWith("0") &&
                                    inputValue.indexOf(".") !== 1
                                  ) {
                                    inputValue = "0";
                                  }

                                  if (inputValue.startsWith(".")) {
                                    inputValue = "0" + inputValue;
                                  }

                                  const match = inputValue.match(
                                    /^\d{0,2}(\.\d{0,2})?$/
                                  );
                                  if (match) {
                                    setFieldValue("sendFiatFee", match[0]);
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors?.sendFiatFee
                                    ? touched?.sendFiatFee
                                    : undefined
                                )}
                                helperText={
                                  touched?.sendFiatFee
                                    ? errors?.sendFiatFee
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Platform charge for sending (Crypto)(%){" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Send Crypto Charge"
                                name="sendCryptoFee"
                                type="text"
                                value={values.sendCryptoFee}
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  inputValue = inputValue.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );

                                  if (
                                    inputValue.startsWith("0") &&
                                    inputValue.indexOf(".") !== 1
                                  ) {
                                    inputValue = "0";
                                  }

                                  if (inputValue.startsWith(".")) {
                                    inputValue = "0" + inputValue;
                                  }

                                  const match = inputValue.match(
                                    /^\d{0,2}(\.\d{0,2})?$/
                                  );
                                  if (match) {
                                    setFieldValue("sendCryptoFee", match[0]);
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors?.sendCryptoFee
                                    ? touched?.sendCryptoFee
                                    : undefined
                                )}
                                helperText={
                                  touched?.sendCryptoFee
                                    ? errors?.sendCryptoFee
                                    : undefined
                                }
                                onWheel={(e) => e.currentTarget.blur()}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Typography
                            className="admin-page-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            Converting Charges
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Platform charge for converting Crypto to Fiat (%){" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Convert To Fiat Charge"
                                name="convertToFiat"
                                type="text"
                                value={values.convertToFiat}
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  inputValue = inputValue.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );

                                  if (
                                    inputValue.startsWith("0") &&
                                    inputValue.indexOf(".") !== 1
                                  ) {
                                    inputValue = "0";
                                  }

                                  if (inputValue.startsWith(".")) {
                                    inputValue = "0" + inputValue;
                                  }

                                  const match = inputValue.match(
                                    /^\d{0,2}(\.\d{0,2})?$/
                                  );
                                  if (match) {
                                    setFieldValue("convertToFiat", match[0]);
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors?.convertToFiat
                                    ? touched?.convertToCrypto
                                    : undefined
                                )}
                                helperText={
                                  touched?.convertToFiat
                                    ? errors?.convertToFiat
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Platform charge for converting Fiat to Crypto (%){" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Convert To Crypto Charge"
                                name="convertToCrypto"
                                type="text"
                                value={values.convertToCrypto}
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  inputValue = inputValue.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );

                                  if (
                                    inputValue.startsWith("0") &&
                                    inputValue.indexOf(".") !== 1
                                  ) {
                                    inputValue = "0";
                                  }

                                  if (inputValue.startsWith(".")) {
                                    inputValue = "0" + inputValue;
                                  }

                                  const match = inputValue.match(
                                    /^\d{0,2}(\.\d{0,2})?$/
                                  );
                                  if (match) {
                                    setFieldValue("convertToCrypto", match[0]);
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors?.convertToCrypto
                                    ? touched?.convertToCrypto
                                    : undefined
                                )}
                                helperText={
                                  touched?.convertToCrypto
                                    ? errors?.convertToCrypto
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Typography
                            className="admin-page-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            Withdrawal Charges
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Platform charge for Withdrawal (%){" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Withdrawal Charge"
                                name="withdrawalFee"
                                type="text"
                                value={values.withdrawalFee}
                                onChange={(e) => {
                                  let inputValue = e.target.value;

                                  inputValue = inputValue.replace(
                                    /[^0-9.]/g,
                                    ""
                                  );

                                  if (
                                    inputValue.startsWith("0") &&
                                    inputValue.indexOf(".") !== 1
                                  ) {
                                    inputValue = "0";
                                  }

                                  if (inputValue.startsWith(".")) {
                                    inputValue = "0" + inputValue;
                                  }

                                  const match = inputValue.match(
                                    /^\d{0,2}(\.\d{0,2})?$/
                                  );
                                  if (match) {
                                    setFieldValue("withdrawalFee", match[0]);
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors?.withdrawalFee
                                    ? touched?.withdrawalFee
                                    : undefined
                                )}
                                helperText={
                                  touched?.withdrawalFee
                                    ? errors?.withdrawalFee
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Typography
                            className="admin-page-sub-title"
                            component="h2"
                            variant="h2"
                          >
                            {/* {editData ? "Edit" : "Add"} User */}
                            Buying Crypto
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Third party URL for buying Crypto
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="URL"
                                name="thirdPartyURL"
                                type="text"
                                value={values.thirdPartyURL}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\s/g,
                                    ""
                                  );
                                  setFieldValue("thirdPartyURL", newValue);

                                  
                                }}
                                onBlur={handleBlur}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              API key
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="API key"
                                name="apiKey"
                                type="text"
                                value={values.apiKey}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\D+/g,
                                    ""
                                  );
                                  if (newValue.length <= 2) {
                                    setFieldValue("apiKey", newValue);
                                  }
                                }}
                                onBlur={handleBlur}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="user-btn-flex">

                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "PlatformFee_edit"
                      )) ||
                    (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                      <Index.Box className="save-btn-main border-btn-main">
                        <Index.Button
                          className="save-user-btn border-btn"
                          type="submit"
                          disabled={isDisabled}
                        >
                          {isDisabled ? (
                            <PageIndex.ButtonLoader
                              color="white"
                              size={14}
                              loading={isDisabled}
                            />
                          ) : (
                            <>
                              <img
                                src={Index.Svg.save}
                                className="user-save-icon"
                                alt="Save Icon"
                              />
                              {values?.convertToCrypto ? "Update" :"Save"}
                            </>
                          )}
                        </Index.Button>
                      </Index.Box>
                      </>
                    ) : (
                      ""
                    )}
                    </Index.Box>
                  </Index.Box>
                </PageIndex.Form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      ) : (
        <PageIndex.Loading />
      )}

      {/* </Index.Box> */}
    </>
  );
}
