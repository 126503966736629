import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import DataService, { dataService } from "../config/dataService";

const ResetPrivateRoutes = () => {
  const { isReset } = useSelector((state) => state.admin);

  const isAuthenticate = (token) => {
    if (!token) return true;
    return false;
  };


  return isAuthenticate(isReset) ? <Outlet /> : <Navigate to="/" replace />;
};

export default ResetPrivateRoutes;
