// import logo from './images/svg/logo.svg';
import dashboard from "./images/svg/dashboard.svg";
import userlist from "./images/svg/user-list.svg";
import adduser from "./images/svg/add-user.svg";
import editprofile from "./images/svg/edit-profile.svg";
import changepassword from "./images/svg/change-password.svg";
import addusermodal from "./images/svg/add-user-modal.svg";
// import settings from "./images/svg/setting.svg";
import notification from "./images/svg/notification.svg";
import search from "./images/svg/search.svg";
import save from "./images/svg/save.svg";
import close from "./images/svg/close.svg";
import plus from "./images/svg/plus.svg";
import blueedit from "./images/svg/blue-edit.svg";
import modalclose from "./images/svg/modal-close.svg";
import logout from "./images/svg/logout.svg";
import bergurmenu from "./images/svg/bergur-menu.svg";
import edit from "./images/svg/edit.svg";
import upload from "./images/svg/upload.svg";
import cms from "./images/svg/cms.svg";

import settings from "./images/svg/settings.svg";
import backarrow from "./images/svg/back-arrow-svgrepo-com.svg";
import cryptoIcon from "./images/svg/crypto-icon.svg";
import archiveIcon from "./images/svg/archive.svg";
import deleteIcon from "./images/svg/delete.svg";
import restoreIcon from "./images/svg/restore.svg";
import sampleImage from "./images/svg/image.svg";
import connectIcon from "./images/svg/connect.svg";
import insightIcon from "./images/svg/insight.svg";
import jobIcon from "./images/svg/job.svg";
import contestIcon from "./images/svg/contest.svg";
import youtube from "./images/svg/youtube.svg";
import facebook from "./images/svg/facebook.svg";
import instagram from "./images/svg/instagram.svg";
import personIcon from "./images/svg/person.svg";
import appleIcon from "./images/svg/apple-icon.svg";
import playStore from "./images/svg/playstore-svgrepo-com.svg";
import callIcon from "./images/svg/call.svg";
import mailIcon from "./images/svg/inbox.svg";
import locationIcon from "./images/svg/location.svg";

const Svg = {
  callIcon,
  mailIcon,
  locationIcon,
  playStore,
  appleIcon,
  personIcon,
  connectIcon,
  insightIcon,
  restoreIcon,
  jobIcon,
  youtube,
  facebook,
  instagram,
  contestIcon,
  sampleImage,
  deleteIcon,
  archiveIcon,
  dashboard,
  userlist,
  // logo,
  editprofile,
  adduser,
  changepassword,
  addusermodal,
  settings,
  notification,
  search,
  save,

  plus,
  close,
  blueedit,
  modalclose,
  bergurmenu,
  logout,
  edit,
  upload,
  cms,
  backarrow,
  cryptoIcon,
};

export default Svg;
