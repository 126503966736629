import React from "react";
import Index from "../../../Index";
import { useNavigate } from "react-router-dom";

const Footer = ({ handleScrollOrNavigate }) => {
  const navigate = useNavigate();
  return (
    <>
      <Index.Box className="main-footer-flex-box">
        <Index.Box className="custom-footer-flex-box">
          <Index.Box className="main-footer-left-heading-box">
            <Index.Typography className="footer-left-heading">
              Copyright © 2024 <span>CRYPCONECT</span> . All rights reserved.
            </Index.Typography>
          </Index.Box>
          <Index.Box className="footer-center-heading-box">
            <Index.Typography
              className="footer-center-heading"
              onClick={() => {
                handleScrollOrNavigate(null, "terms-condition");
              }}
            >
              Terms and conditions
            </Index.Typography>
            <Index.Typography
              className="footer-center-heading"
              onClick={() => {
                handleScrollOrNavigate(null, "privacy-policy");
              }}
            >
              Privacy Policy
            </Index.Typography>
          </Index.Box>
          <Index.Box className="footer-right-heading-box">
            <Index.Box className="facebook-icon-box">
              <img src={Index.Svg.facebook} className="facebook-icon" />
            </Index.Box>
            <Index.Box className="yt-icon-box">
              <img src={Index.Svg.youtube} className="yt-icon" />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Footer;
