import React, { useState } from "react";
import { resendOTP,verifyOtp,} from "../../../redux/slices/adminService";
import Index from "../../../component/Index";
import PageIndex from "../../PageIndex";
import OTPInput from "react-otp-input";
import OTPTimer from "./OTPTimer";
import { isVerifyOTP } from "../../../redux/slices/AdminSlice";

export default function Otp() {
  const { state } = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const dispatch = PageIndex.useDispatch();
  let initialValues = {
    otp: "",
  };

  const handleResendOTP = () => {
    resendOTP({ id: state?.id }).then((val) => {
      if (val?.status === 200) {
        setSeconds(59);

        setIsDisabled(false);
      } else {
        setTimeout(() => {
          setIsDisabled(false);
        }, 3000);
      }
    });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    verifyOtp({ ...values, id: state?.id }).then((val) => {
      if (val?.status === 200) {
        navigate("/reset-password", { state: { id: val?.data?._id } });
        dispatch(isVerifyOTP(false));
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      }
    });
  };
  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg">
            <Index.Box className="auth-logo-box">
              <img
                alt="logo_img"
                src={Index.Jpg.authlogo}
                className="auth-logo"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  OTP!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter the OTP sent to your registered email!
                </Index.Typography>
                <PageIndex.Formik
                  validationSchema={PageIndex.adminSendOtpSchema}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                    setFieldValue,
                  }) => (
                    <PageIndex.Form>
                      <Index.Box className="otp-box-main">
                        <OTPInput
                          value={values.otp}
                          onChange={(e) => setFieldValue("otp", e)}
                          onBlur={handleBlur}
                          numInputs={4}
                          inputType="number"
                          shouldAutoFocus={true}
                          // className="otp-box"
                          // className={`otp-box ${errors.otp  && values.otp.length!=4 ? 'empty' : ''}`}
                          error={Boolean(errors.otp)}
                          renderInput={(props,index) => (
                            <input
                              {...props}
                              // className="mui-otp"
                              className={`mui-otp ${!values.otp[index] && errors.otp ? 'empty' : ''}`}
                              // placeholder="0"
                            />
                          )}
                        />
                        {errors?.otp && touched?.otp ? (
                          <Index.Typography className="error-msg">
                            {errors?.otp}
                          </Index.Typography>
                        ) : null}
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn forgot-button"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <PageIndex.ButtonLoader
                              color="white"
                              loading={loading}
                            />
                          ) : (
                            "Verify"
                          )}
                        </Index.Button>
                        <Index.Box className="condition-content">
                          <Index.Typography className="condition-text">
                            {" "}
                            {/* Didn't get OTP ?{" "} */}
                            <span className="condition-span">
                              <OTPTimer
                                onResend={handleResendOTP}
                                seconds={seconds}
                                setSeconds={setSeconds}
                                isDisabled={isDisabled}
                                setIsDisabled={setIsDisabled}
                              />
                            </span>
                          </Index.Typography>
                        </Index.Box>
                        <Index.Typography
                          component="span"
                          variant="span"
                          align="center"
                          className="admin-sign-para common-para admin-verify-btn back-text"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Go to Login page?
                        </Index.Typography>
                      </Index.Box>
                    </PageIndex.Form>
                  )}
                </PageIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
