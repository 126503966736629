import React from "react";
import Index from "../../../Index";

const ContactUs = () => {
  return (
    <>
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box className="hero-section-grid-box contact-section-grid-box">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-add-user"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="hero-section-content-box section-content-box-about contact-box">
                  <Index.Box className="hero-section-main-heading-box">
                    <Index.Typography className="hero-section-main-heading">
                      Contact Us
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="hero-section-sub-heading-box">
                    <Index.Typography className="hero-section-sub-heading">
                      We’re Here and Happy to Assist You!
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="contact-container">
                    <Index.Box className="call-box">
                      <Index.Box className="contact-image-box">
                        <img
                          src={Index.Svg.callIcon}
                          className="contact-icon"
                        />
                      </Index.Box>
                      <Index.Box className="contact-info">
                        <Index.Box className="contact-type">
                          <Index.Typography className="contact-type-text">
                            Call:
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="contact-address">
                          <Index.Typography className="contact-add-text">
                            9876543210
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="call-box">
                      <Index.Box className="contact-image-box">
                        <img
                          src={Index.Svg.mailIcon}
                          className="contact-icon"
                        />
                      </Index.Box>
                      <Index.Box className="contact-info">
                        <Index.Box className="contact-type">
                          <Index.Typography className="contact-type-text">
                            Email:
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="contact-address">
                          <Index.Typography className="contact-add-text">
                            info@example.com
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="call-box">
                      <Index.Box className="contact-image-box">
                        <img
                          src={Index.Svg.locationIcon}
                          className="contact-icon"
                        />
                      </Index.Box>
                      <Index.Box className="contact-info">
                        <Index.Box className="contact-type">
                          <Index.Typography className="contact-type-text">
                            Address:
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="contact-address">
                          <Index.Typography className="contact-add-text">
                            Lorem ipsum dolor sit amet
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="follow-container">
                    <Index.Box className="follow-text-box">
                      <Index.Typography className="follow-text">
                        Follow:
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="follow-image-flex">
                      <Index.Box className="follow-image-box">
                        <img src={Index.Svg.facebook} className="follow-icon" />
                      </Index.Box>
                      <Index.Box className="follow-image-box">
                        <img src={Index.Svg.youtube} className="follow-icon" />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="hero-section-image-box contact-section-image">
                  <img
                    src={Index.Svg.sampleImage}
                    className="hero-section-image"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default ContactUs;
