import React from "react";

import PageIndex from "../../container/PageIndex";
import Index from "../../container/Index";
import { tokenLogo } from "../../config/dataService";
import numeral from "numeral";
const chainTypeList = [
  { id: 1, value: "BTC" },
  { id: 2, value: "BNB" },
  { id: 3, value: "ETH" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const AddModel = ({
  open,
  handleClose,
  initialValues,
  schema,
  isDeleteButtonDisabled,
  singleCrypto,
  handleSubmit,
  placeholder,
  title,
  fieldName,
  isView,
  liveCryptoList,
}) => {
  return (
    <>
      <Index.Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-add modal"
      >
        <Index.Box sx={style} className="add-modal-inner-main modal-inner ">
          <Index.Box className="modal-circle-main cus-modal-cicle-main">
            <Index.IconButton onClick={handleClose}>
              <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={schema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <PageIndex.Form>
                <Index.Typography
                  className="add-modal-title"
                  component="h2"
                  variant="h2"
                >
                  {isView ? "View" : title} Crypto Token
                  {/* {values?.cryptoName ? "Edit" : "Add"} */}
                </Index.Typography>
                <Index.Box className="add-user-data-main">
                  <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                      display="grid"
                      className="display-row-add-user"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Token ID{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Token Id"
                              name="tokenId"
                              disabled={isView}
                              value={values.tokenId}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  // .replace(/[^0-9a-zA-Z]+/g, "")
                                  .replace(/\s\s+/g, " ");

                                if (newValue.replace(/\s/g, "").length <= 50) {
                                  setFieldValue("tokenId", newValue);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.tokenId ? touched?.tokenId : undefined
                              )}
                              helperText={
                                touched?.tokenId ? errors?.tokenId : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Token Name{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Token Name"
                              name="tokenName"
                              disabled={isView}
                              value={values.tokenName}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/[^0-9a-zA-Z]+/g, "")
                                  .replace(/\s\s+/g, " ");

                                if (newValue.replace(/\s/g, "").length <= 30) {
                                  setFieldValue("tokenName", newValue);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.tokenName
                                  ? touched?.tokenName
                                  : undefined
                              )}
                              helperText={
                                touched?.tokenName
                                  ? errors?.tokenName
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Token Price{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              type="text"
                              className="form-control"
                              placeholder="Token Price"
                              name="tokenPrice"
                              disabled={isView}
                              value={
                                isView
                                  ? Number.isInteger(values.tokenPrice)
                                    ? numeral(values.tokenPrice).format("0")
                                    : numeral(values.tokenPrice).format(
                                        "0.000a"
                                      )
                                  : values.tokenPrice
                              }
                              onChange={(e) => {
                                let inputValue = e.target.value;
                                inputValue = inputValue.replace(/[^0-9.]/g, "");
                                // if (inputValue.startsWith("0") && inputValue.length === 2 && e.key === ".") {
                                //   e.preventDefault();
                                //   return;
                                // }
                                // if (inputValue.startsWith(".")) {
                                //   inputValue = "0" + inputValue;
                                // }
                                // if (
                                //   inputValue.startsWith("00") &&
                                //   inputValue.length === 2 &&
                                //   e.key === "."
                                // ) {
                                //   e.preventDefault();
                                //   return;
                                // }
                                // const parts = inputValue.split(".");
                                // if (parts[0].length > 10) {
                                //   e.preventDefault();
                                //   return;
                                // }
                                // const match = inputValue.match(/^\d{0,10}(\.\d{0,3})?$/);
                                // if (match) {
                                //   if (parts.length > 1 && parts[1].length > 1 && parseInt(parts[1]) === 0) {
                                //     e.preventDefault();
                                //     return;
                                //   }

                                // }
                                if (inputValue.length < 20) {
                                  setFieldValue("tokenPrice", inputValue);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.tokenPrice
                                  ? touched?.tokenPrice
                                  : undefined
                              )}
                              helperText={
                                touched?.tokenPrice
                                  ? errors?.tokenPrice
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Chain Type
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.FormControl className="radio-main">
                              <Index.Select
                                className="form-control select-drop-list"
                                name="chainType"
                                value={values?.chainType}
                                disabled={isView}
                                defaultValue={values ? values?.chainType : ""}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue("chainType", e.target.value);
                                }}
                                displayEmpty
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                              >
                                <Index.MenuItem
                                  value=""
                                  // disabled
                                  className="menuitem"
                                >
                                  Select Chain Type
                                </Index.MenuItem>
                                {chainTypeList &&
                                  chainTypeList?.map((val) => (
                                    <Index.MenuItem
                                      value={val?.value}
                                      key={val?.id}
                                      className="menuitem"
                                    >
                                      {val?.value}
                                    </Index.MenuItem>
                                  ))}
                              </Index.Select>
                            </Index.FormControl>
                            {errors.chainType && touched.chainType && (
                              <p className="error-text">{errors.chainType}</p>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Token Symbol{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              disabled={isView}
                              className="form-control"
                              placeholder="Token Symbol"
                              name="tokenSymbol"
                              value={values.tokenSymbol}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/^\s+/, "")
                                  .replace(/[^0-9a-zA-Z@#$]+/g, "")
                                  .replace(/\s\s+/g, " ");
                                if (newValue.replace(/\s/g, "").length <= 20) {
                                  setFieldValue("tokenSymbol", newValue);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.tokenSymbol
                                  ? touched?.tokenSymbol
                                  : undefined
                              )}
                              helperText={
                                touched?.tokenSymbol
                                  ? errors?.tokenSymbol
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Token Address{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              disabled={isView}
                              className="form-control"
                              placeholder="Token Address"
                              name="tokenAddress"
                              value={values.tokenAddress}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/\s+/g, "")
                                  .replace(/[^0-9a-zA-Z]+/g, "");

                                if (newValue.replace(/\s/g, "").length <= 50) {
                                  setFieldValue("tokenAddress", newValue);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.tokenAddress
                                  ? touched?.tokenAddress
                                  : undefined
                              )}
                              helperText={
                                touched?.tokenAddress
                                  ? errors?.tokenAddress
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Contract Address{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              disabled={isView}
                              className="form-control"
                              placeholder="Contract Address"
                              name="contractAddress"
                              value={values.contractAddress}
                              onChange={(e) => {
                                const newValue = e.target.value
                                  .replace(/\s+/g, "")
                                  .replace(/[^0-9a-zA-Z]+/g, "");

                                if (newValue.length <= 50) {
                                  setFieldValue("contractAddress", newValue);
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(
                                errors?.contractAddress
                                  ? touched?.contractAddress
                                  : undefined
                              )}
                              helperText={
                                touched?.contractAddress
                                  ? errors?.contractAddress
                                  : undefined
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Token Logo{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="profile-header crypto-icon-box">
                            <Index.Box className="profile-input-box">
                              <label htmlFor="btn-upload">
                                <img
                                  src={
                                    values?.tokenLogo
                                      ? values?.tokenLogo instanceof Blob
                                        ? URL.createObjectURL(values?.tokenLogo)
                                        : `${tokenLogo}${values?.tokenLogo}`
                                      : PageIndex.Png.cameraImage
                                  }
                                  alt="tokenLogo img"
                                  className={
                                    values?.tokenLogo
                                      ? "crypto-img"
                                      : "defaultcrypto-img"
                                  }
                                />

                                <input
                                  id="btn-upload"
                                  name="tokenLogo"
                                  disabled={isDeleteButtonDisabled || isView}
                                  className="profile-upload-btn"
                                  type="file"
                                  accept="image/*"
                                  // hidden
                                  onChange={(e) => {
                                    setFieldValue(
                                      "tokenLogo",
                                      e.target.files[0]
                                    );
                                  }}
                                />
                                <Index.Box className="profile-edit-main">
                                  <Index.Box className="edit-div" title="Edit">
                                    {" "}
                                    <img
                                      className="profile-edit-img"
                                      src={PageIndex.Png.editLogo}
                                      alt=""
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </label>
                            </Index.Box>
                          </Index.Box>
                          {errors.tokenLogo && touched.tokenLogo && (
                            <p className="error-text">{errors.tokenLogo}</p>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="make-pupular-box">
                  <Index.Box className="make-popular">
                    <Index.FormControlLabel
                      control={
                        <Index.Checkbox
                          name="isPopular"
                          disabled={isView}
                          checked={values.isPopular}
                          onChange={handleChange}
                          color="secondary"
                          className="checkbox-lable"
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Make Popular"
                      className="checkbox-lable"
                    />
                  </Index.Box>
                </Index.Box>

                {!isView ? (
                  <Index.Box className="delete-modal-btn-flex">
                    <Index.Button
                      className="modal-cancel-btn modal-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </Index.Button>
                    <Index.Button
                      type="submit"
                      className="modal-delete-btn modal-btn"
                      disabled={isDeleteButtonDisabled}
                    >
                      {isDeleteButtonDisabled ? (
                        <PageIndex.ButtonLoader
                          color="white"
                          size={14}
                          loading={isDeleteButtonDisabled}
                        />
                      ) : title == "Add" ? (
                        "Submit"
                      ) : (
                        "Update"
                      )}
                    </Index.Button>
                  </Index.Box>
                ) : (
                  ""
                )}
              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AddModel;
