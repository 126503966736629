import React, { useEffect, useState } from "react";
import { getPrivacyPolicy } from "../../../../redux/slices/adminService";
import Index from "../../../Index";

const PrivacyPolicy = () => {
  const [cmsText, setCmsText] = useState("");
  const fetchPrivacyPolicy = () => {
    getPrivacyPolicy().then((data) => {
      if (data?.status == 200) {
        setCmsText(data?.data?.description);
      } else {
      }
    });
  };
  useEffect(() => {
    fetchPrivacyPolicy();
  }, []);
  return (
    <>
      <Index.Box className="main-privacy">
        <Index.Box className="cus-container">
          <Index.Box className="privacy-header">
            <Index.Typography
              variant="h1"
              component="h1"
              className="privacy-header-title"
            >
              Privacy Policy
            </Index.Typography>
          </Index.Box>
          <Index.Box className="privacy-body">
            <Index.Box dangerouslySetInnerHTML={{ __html: cmsText }} />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default PrivacyPolicy;
