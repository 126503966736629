import React from "react";
import Index from "../../../Index";

const Connection = () => {
  return (
    <>
      <Index.Box className="connection-main-box">
        <Index.Box className="custom-connection-main">
          <Index.Box className="connection-image-box">
            <img src={Index.Svg.sampleImage} />
          </Index.Box>
          <Index.Box className="connection-text-box">
            <Index.Box className="connection-heading-box">
              <Index.Typography className="connection-heading">
                Connect, Engage, and Grow Your Social Presence!
              </Index.Typography>
            </Index.Box>
            <Index.Box className="connection-paragraph-box">
              <Index.Typography className="connection-para">
                Engage with others by liking, commenting, and sharing videos.
                Help others get more likes on their videos while also increasing
                your own social presence in the community. Make new friends,
                follow makers, and join the Crypconect community.
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Connection;
