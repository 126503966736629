import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import Index from "../../container/Index";
import PageIndex from "../../container/PageIndex";
import { addEditStreakNotification, provideDiamondTickByUser, removeDiamondTick } from "../../redux/slices/adminService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const DiamondTickModel = ({
  openDiamondTickModel,
  userData,
  handleCloseDiamondTickModel,
  getNotification,
  setIsDeleteButtonDisabled,
  isDeleteButtonDisabled,
  setUserData,
}) => {
  // const [addEditData,setAddEditData]=useState({})



  let initialValues
  if (!userData?.diamondTick) {
    initialValues = {
      validity: "",
    };
  } else {
    initialValues = {
      reason: "",
    };
  }


  const handleSubmit = (values) => {
    setIsDeleteButtonDisabled(true);
    var urlencoded = new URLSearchParams();
    if (!userData?.diamondTick) {
      urlencoded.append("validityDate", Index.moment(values?.validity).format("DD/MM/YYYY"));
      urlencoded.append("purchaseTime", new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: "numeric",
        minute: "numeric"
      }));
      urlencoded.append("userId", userData?._id);
      urlencoded.append("planType", "Admin");
    } else {
      urlencoded.append("userId", userData?._id);
      urlencoded.append("reason", values.reason);
    }


    (!userData?.diamondTick ? provideDiamondTickByUser(urlencoded)
      : removeDiamondTick(urlencoded))
      .then((res) => {
        setUserData((data) => ({
          ...data,
          diamondTick: res?.data?.diamondTick,
          validityDate: dayjs(values?.validity).format("DD/MM/YYYY")
        }));
        setIsDeleteButtonDisabled(false);
        handleCloseDiamondTickModel();
      })

      .catch((err) => {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
          handleCloseDiamondTickModel();
        }, 3000);
      });
  };



  return (
    <>
      <Index.Modal
        open={openDiamondTickModel}
        // onClose={handleCloseDiamondTickModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner ">
          <Index.Box className="modal-circle-main cus-modal-margin">
            <Index.IconButton onClick={handleCloseDiamondTickModel}>
              <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <PageIndex.Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={!userData?.diamondTick ? PageIndex.diamondTick : PageIndex.removeDiamondTickSchema}
          >
            {({
              values,
              handleBlur,
              handleChange,
              errors,
              touched,
              setFieldValue,
            }) => (
              <PageIndex.Form>
                <Index.Typography
                  className="delete-modal-title setting-modal"
                  component="h2"
                  variant="h2"
                >
                  {!userData?.diamondTick ? "Provide Diamond Tick" : "Remove Diamond Tick"}
                </Index.Typography>

                <Index.Typography
                  className="delete-modal-para common-para"
                  component="p"
                  variant="p"
                >
                  {
                    !userData?.diamondTick ? `You are going to provide a "Diamond Tick" to ${userData?.userName}. Do you want to continue ?`
                      : `Do you really want to remove diamond tick from this user? This process cannot be undone.`
                  }

                </Index.Typography>

                {!userData?.diamondTick ? <Index.Box className="input-box add-user-input">
                  <Index.FormHelperText className="form-lable">
                    Validity <span className="mandatory-asterisk">*</span>
                  </Index.FormHelperText>
                  <Index.Box className="form-group custom-datepicker">
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      className="date-adapter-main"
                    >
                      <DemoContainer
                        components={["DatePicker", "DatePicker"]}
                        className="date-adapter-container"
                      >
                        <Index.MobileDatePicker
                          className="date-adapter-picker"
                          name="validity"
                          value={dayjs(values?.validity)}
                          onChange={(selectedTime) => {
                            const newStartTime = selectedTime.$d;
                            setFieldValue("validity", newStartTime);
                          }}
                          // disablePast
                          disablePast
                          format="DD/MM/YYYY"
                          onBlur={handleBlur}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {errors.validity && touched.validity && (
                      <p className="error-text">{errors.validity}</p>
                    )}
                  </Index.Box>
                </Index.Box> :
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Reason   <span className="mandatory-asterisk">*</span>
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="reason"
                        name="reason"
                        className="form-control"
                        value={values?.reason}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/[^A-Za-z]/g, " ")
                            .replace(/\s\s+/g, " ");
                          setFieldValue("reason", newValue);
                        }}
                        onBlur={handleBlur}
                        placeholder="Reason"
                        error={Boolean(errors.reason && touched.reason)}
                        helperText={touched.reason && errors.reason}
                      />
                    </Index.Box>
                  </Index.Box>}
                <Index.Box className="delete-modal-btn-flex">
                  <Index.Button
                    className="modal-cancel-btn modal-btn"
                    onClick={handleCloseDiamondTickModel}
                  >
                    Cancel
                  </Index.Button>

                  <Index.Button
                    type="submit"
                    className="modal-delete-btn modal-btn"
                    disabled={isDeleteButtonDisabled}
                  >
                    {isDeleteButtonDisabled ? (
                      <PageIndex.ButtonLoader
                        color="white"
                        size={14}
                        loading={isDeleteButtonDisabled}
                      />
                    ) :
                      "Submit"}

                  </Index.Button>
                </Index.Box>


              </PageIndex.Form>
            )}
          </PageIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};
