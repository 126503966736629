import React from 'react'
import Index from '../Index'

const BackButton = ({onClick}) => {
  return (
    <Index.Box className="adduser-btn-main btn-main-primary back-btn-box" >
    <Index.Button
      className="adduser-btn btn-primary back-btn"
      onClick={onClick}
      startIcon={<img
        src={Index.Png.backarrow}
        className="plus-icon"
       
      />}
    >
      {/* <img
        src={Index.Png.backarrow}
        className="plus-icon"
        alt="plus icon"
      /> */}
      Back
    </Index.Button>
    </Index.Box>
  )
}

export default BackButton