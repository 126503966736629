import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  addDiamondPrice,
  getDiamondPrice,
} from "../../../../redux/slices/adminService";

export const AddDiamondTickPrice = () => {
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [addEditData, setAddEditData] = useState();

  const SubCategoryListData = [
    {
      id: "1",
      name: "USD",
      value: { currencySymbol: "$", currencyName: "USD" },
    },
  ];
  const SubCategoryCryptoListData = [
    {
      id: "1",
      name: "USDT",
      value: { currencySymbolInUsdt: "$", currencyNameInUsdt: "USDT" },
    },
  ];

  const initialValues = {
    monthlyPrice: addEditData ? addEditData?.monthlyPlan?.monthlyPrice : "",
    quarterlyPrice: addEditData
      ? addEditData?.quarterlyPlan?.quarterlyPrice
      : "",
    yearlyPrice: addEditData ? addEditData?.yearlyPlan?.yearlyPrice : "",
    monthlyDescription: addEditData
      ? addEditData?.monthlyPlan?.monthlyDescription
      : "",
    quarterlyDescription: addEditData
      ? addEditData?.quarterlyPlan?.quarterlyDescription
      : "",
    yearlyDescription: addEditData
      ? addEditData?.yearlyPlan?.yearlyDescription
      : "",
    currency: addEditData ? addEditData?.currency : "",
    monthlyPriceInUsdt: addEditData ? addEditData?.monthlyPlanInUsdt?.monthlyPriceInUsdt : "",
    quarterlyPriceInUsdt: addEditData ? addEditData?.quarterlyPlanInUsdt?.quarterlyPriceInUsdt : "",
    yearlyPriceInUsdt: addEditData ? addEditData?.yearlyPlanInUsdt?.yearlyPriceInUsdt : "",
    monthlyDescriptionInUsdt: addEditData
      ? addEditData?.monthlyPlanInUsdt?.monthlyDescriptionInUsdt
      : "",
    quarterlyDescriptionInUsdt: addEditData
      ? addEditData?.quarterlyPlanInUsdt?.quarterlyDescriptionInUsdt
      : "",
    yearlyDescriptionInUsdt: addEditData
      ? addEditData?.yearlyPlanInUsdt?.yearlyDescriptionInUsdt
      : "",
    currencyInUsdt: addEditData ? addEditData?.currencyInUsdt : "",
  };
  const handleSubmitForm = (values) => {
    setBtnLoader(true);
    addDiamondPrice(values).then((res) => {
      if (res?.status === 200) {
        setTimeout(() => {
          setBtnLoader(false);
        }, 3000);
        getPrice();
      } else {
        setTimeout(() => {
          setBtnLoader(false);
        }, 3000);
      }
    });
  };

  const getPrice = () => {
    setLoader(true)
    getDiamondPrice()
      .then((res) => {
        setAddEditData(res?.data[0]);
        setTimeout(() => {
          setLoader(false);
        }, 500);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoader(false);
        }, 500);
      });
  };

  useEffect(() => {
    getPrice();
  }, []);
  return (
    <>
      {!loader ? (
        <Index.Box className="dashboard-content">
          <Index.Box className="barge-common-box">
            <Index.Box className="admin-page-title-main cus-change-password-box">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Diamond Tick Price
              </Index.Typography>
            </Index.Box>
            <PageIndex.Formik
              validationSchema={PageIndex.diamondPriceSchema}
              initialValues={initialValues}
              onSubmit={handleSubmitForm}
            >
              {({
                values,
                errors,
                handleBlur,
                touched,
                setFieldValue,
              }) => (
                <PageIndex.Form>
                  <Index.Box className="add-user-data-main flex-contactus">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row-edit-profile"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 1, sm: 1, md: 1, lg: 1 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Fiat Currency{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.FormControl className="radio-main">
                                <Index.Select
                                  className="form-control select-drop-list cus-diamondtick-main"
                                  name="currencyType"
                                  value={values?.currency?.currencyName}
                                  onChange={(e) => {
                                    const selectedCurrency =
                                      SubCategoryListData.find(
                                        (item) =>
                                          item.value.currencyName ===
                                          e.target.value
                                      )?.value;
                                    setFieldValue(
                                      "currency",
                                      selectedCurrency || ""
                                    );
                                  }}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                >
                                  <Index.MenuItem
                                    value=""
                                    disabled={addEditData ? true : false}
                                    className="menuitem"
                                  >
                                    Select Currency
                                  </Index.MenuItem>
                                  {SubCategoryListData &&
                                    SubCategoryListData?.map((val) => (
                                      <Index.MenuItem
                                        value={val?.value.currencyName}
                                        key={val?.id}
                                        className="menuitem"
                                      >
                                        {val?.value?.currencyName}
                                      </Index.MenuItem>
                                    ))}
                                </Index.Select>
                              </Index.FormControl>
                              {errors.currency && touched.currency && (
                                <p className="error-text">{errors.currency}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Crypto Currency{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.FormControl className="radio-main">
                                <Index.Select
                                  className="form-control select-drop-list cus-diamondtick-main"
                                  name="currencyInUsdt"
                                  value={values?.currencyInUsdt?.currencyNameInUsdt}
                                  onChange={(e) => {
                                    const selectedCurrency =
                                      SubCategoryCryptoListData.find(
                                        (item) =>
                                          item.value.currencyNameInUsdt ===
                                          e.target.value
                                      )?.value;
                                    setFieldValue(
                                      "currencyInUsdt",
                                      selectedCurrency || ""
                                    );
                                  }}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                >
                                  <Index.MenuItem
                                    value=""
                                    disabled={addEditData ? true : false}
                                    className="menuitem"
                                  >
                                    Select Currency
                                  </Index.MenuItem>
                                  {SubCategoryCryptoListData &&
                                    SubCategoryCryptoListData?.map((val) => (
                                      <Index.MenuItem
                                        value={val?.value.currencyNameInUsdt}
                                        key={val?.id}
                                        className="menuitem"
                                      >
                                        {val?.value?.currencyNameInUsdt}
                                      </Index.MenuItem>
                                    ))}
                                </Index.Select>
                              </Index.FormControl>
                              {errors.currencyInUsdt && touched.currencyInUsdt && (
                                <p className="error-text">{errors.currencyInUsdt}</p>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Fiat Monthly Price
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                placeholder="Monthly Price"
                                id="fullWidth"
                                name="monthlyPrice"
                                className="form-control cus-diamondtick-main"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                value={values.monthlyPrice}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\D+/g,
                                    ""
                                  );
                                  if (newValue.length <= 10) {
                                    setFieldValue("monthlyPrice", newValue);
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors?.monthlyPrice
                                    ? touched?.monthlyPrice
                                    : undefined
                                )}
                                helperText={
                                  touched?.monthlyPrice
                                    ? errors?.monthlyPrice
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Monthly Plan Description{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosizeed
                                fullWidth
                                id=""
                                className="form-control contest-text-autosize cus-diamondtick-main"
                                placeholder="Description"
                                name="monthlyDescription"
                                value={values.monthlyDescription}
                                onChange={(e) => {
                                  if (e.target.value.length <= 100) {
                                    setFieldValue(
                                      "monthlyDescription",
                                      e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ")
                                    );
                                  }
                                 
                                }}
                                onBlur={handleBlur}
                              />
                            </Index.Box>
                            {errors?.monthlyDescription &&
                              touched?.monthlyDescription && (
                                <p className="custom-error-tag">
                                  {errors?.monthlyDescription}
                                </p>
                              )}
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Crypto Monthly Price
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                fullWidth
                                placeholder="Crypto Monthly Price"
                                id="fullWidth"
                                name="monthlyPriceInUsdt"
                                className="form-control cus-diamondtick-main"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                value={values.monthlyPriceInUsdt}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\D+/g,
                                    ""
                                  );
                                  if (newValue.length <= 10) {
                                    setFieldValue("monthlyPriceInUsdt", newValue);
                                  }
                                }}
                                onBlur={handleBlur}
                                error={Boolean(
                                  errors?.monthlyPriceInUsdt
                                    ? touched?.monthlyPriceInUsdt
                                    : undefined
                                )}
                                helperText={
                                  touched?.monthlyPriceInUsdt
                                    ? errors?.monthlyPriceInUsdt
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Monthly Plan Description{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosizeed
                                fullWidth
                                id=""
                                className="form-control contest-text-autosize cus-diamondtick-main"
                                placeholder="Description"
                                name="monthlyDescriptionInUsdt"
                                value={values.monthlyDescriptionInUsdt}
                                onChange={(e) => {
                                  if (e.target.value.length <= 100) {
                                    setFieldValue(
                                      "monthlyDescriptionInUsdt",
                                      e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ")
                                    );
                                  }
                                }}
                                
                                onBlur={handleBlur}
                              />
                            </Index.Box>
                            {errors?.monthlyDescriptionInUsdt &&
                              touched?.monthlyDescriptionInUsdt && (
                                <p className="custom-error-tag">
                                  {errors?.monthlyDescriptionInUsdt}
                                </p>
                              )}
                          </Index.Box>
                        </Index.Box>




                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Fiat Quarterly Price
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                placeholder="Quarterly Price"
                                fullWidth
                                size="small"
                                name="quarterlyPrice"
                                className="form-control input-with-radius password-form-control cus-diamondtick-main"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                value={values.quarterlyPrice}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\D+/g,
                                    ""
                                  );
                                  if (newValue.length <= 10) {
                                    setFieldValue("quarterlyPrice", newValue);
                                  }
                                }}
                                error={Boolean(
                                  errors?.quarterlyPrice
                                    ? touched?.quarterlyPrice
                                    : undefined
                                )}
                                helperText={
                                  touched?.quarterlyPrice
                                    ? errors?.quarterlyPrice
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>

                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box" >
                            <Index.FormHelperText className="form-lable">
                              Quarterly Plan Description{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosizeed
                                fullWidth
                                id=""
                                className="form-control contest-text-autosize cus-diamondtick-main"
                                placeholder="Description"
                                name="quarterlyDescription"
                                value={values.quarterlyDescription}
                                onChange={(e) => {
                                  if (e.target.value.length <= 100) {
                                    setFieldValue(
                                      "quarterlyDescription",
                                      e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ")
                                    )
                                  }
                                }}
                                onBlur={handleBlur}
                              />
                            </Index.Box>
                            {errors?.quarterlyDescription &&
                              touched?.quarterlyDescription && (
                                <p className="custom-error-tag">
                                  {errors?.quarterlyDescription}
                                </p>
                              )}
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Crypto Quarterly Price
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                placeholder="Quarterly Price"
                                fullWidth
                                size="small"
                                name="quarterlyPriceInUsdt"
                                className="form-control input-with-radius password-form-control cus-diamondtick-main"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                value={values.quarterlyPriceInUsdt}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\D+/g,
                                    ""
                                  );
                                  if (newValue.length <= 10) {
                                    setFieldValue("quarterlyPriceInUsdt", newValue);
                                  }
                                }}
                                error={Boolean(
                                  errors?.quarterlyPriceInUsdt
                                    ? touched?.quarterlyPriceInUsdt
                                    : undefined
                                )}
                                helperText={
                                  touched?.quarterlyPriceInUsdt
                                    ? errors?.quarterlyPriceInUsdt
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>
 
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Quarterly Plan Description{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosizeed
                                fullWidth
                                id=""
                                className="form-control contest-text-autosize cus-diamondtick-main"
                                placeholder="Description"
                                name="quarterlyDescriptionInUsdt"
                                value={values.quarterlyDescriptionInUsdt}
                                onChange={(e) => {
                               
                                  if (e.target.value.length <= 100) {
                                    setFieldValue(
                                      "quarterlyDescriptionInUsdt",
                                      e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ")
                                    );
                                  }
                                }}
                                
                                onBlur={handleBlur}
                              />
                            </Index.Box>
                            {errors?.quarterlyDescriptionInUsdt &&
                              touched?.quarterlyDescriptionInUsdt && (
                                <p className="custom-error-tag">
                                  {errors?.quarterlyDescriptionInUsdt}
                                </p>
                              )}
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Fiat Yearly Price
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                placeholder="Yearly Price"
                                fullWidth
                                size="small"
                                name="yearlyPrice"
                                className="form-control input-with-radius password-form-control cus-diamondtick-main"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                value={values.yearlyPrice}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\D+/g,
                                    ""
                                  );
                                  if (newValue.length <= 10) {
                                    setFieldValue("yearlyPrice", newValue);
                                  }
                                }}
                                error={Boolean(
                                  errors?.yearlyPrice
                                    ? touched?.yearlyPrice
                                    : undefined
                                )}
                                helperText={
                                  touched?.yearlyPrice
                                    ? errors?.yearlyPrice
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Yearly Plan Description{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosizeed
                                fullWidth
                                id=""
                                className="form-control contest-text-autosize cus-diamondtick-main"
                                placeholder="Description"
                                name="yearlyDescription"
                                value={values.yearlyDescription}
                                onChange={(e) => {
                                
                                  if (e.target.value.length <= 100) {
                                    setFieldValue(
                                      "yearlyDescription",
                                      e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ")
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                              />
                            </Index.Box>
                            {errors?.yearlyDescription &&
                              touched?.yearlyDescription && (
                                <p className="custom-error-tag">
                                  {errors?.yearlyDescription}
                                </p>
                              )}
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Crypto Yearly Price
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                placeholder="Yearly Price"
                                fullWidth
                                size="small"
                                name="yearlyPriceInUsdt"
                                className="form-control input-with-radius password-form-control cus-diamondtick-main"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                // label="Password"
                                variant="outlined"
                                autoComplete="off"
                                value={values.yearlyPriceInUsdt}
                                onChange={(e) => {
                                  const newValue = e.target.value.replace(
                                    /\D+/g,
                                    ""
                                  );
                                  if (newValue.length <= 10) {
                                    setFieldValue("yearlyPriceInUsdt", newValue);
                                  }
                                }}
                                error={Boolean(
                                  errors?.yearlyPriceInUsdt
                                    ? touched?.yearlyPriceInUsdt
                                    : undefined
                                )}
                                helperText={
                                  touched?.yearlyPriceInUsdt
                                    ? errors?.yearlyPriceInUsdt
                                    : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="input-box add-user-input cus-diamondtick-input-box">
                            <Index.FormHelperText className="form-lable">
                              Yearly Plan Description{" "}
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosizeed
                                fullWidth
                                id=""
                                className="form-control contest-text-autosize cus-diamondtick-main"
                                placeholder="Description"
                                name="yearlyDescriptionInUsdt"
                                value={values.yearlyDescriptionInUsdt}
                                onChange={(e) => {
                               
                                  if (e.target.value.length <= 100) {
                                    setFieldValue(
                                      "yearlyDescriptionInUsdt",
                                      e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ")
                                    );
                                  }
                                }}
                                onBlur={handleBlur}
                              />
                            </Index.Box>
                            {errors?.yearlyDescriptionInUsdt &&
                              touched?.yearlyDescriptionInUsdt && (
                                <p className="custom-error-tag">
                                  {errors?.yearlyDescriptionInUsdt}
                                </p>
                              )}
                          </Index.Box>
                        </Index.Box>

                      </Index.Box>
                      <Index.Box className="user-btn-flex">
                        <Index.Box className="save-btn-main border-btn-main">
                          <Index.Button
                            className="save-user-btn border-btn"
                            type="submit"
                            disabled={btnLoader}
                          >
                            {btnLoader ? (
                              <PageIndex.ButtonLoader
                                color="white"
                                size={14}
                                loading={btnLoader}
                              />
                            ) : (
                              <>
                                <img
                                  src={Index.Svg.save}
                                  className="user-save-icon"
                                  alt="Save Icon"
                                />
                                Save
                              </>
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </PageIndex.Form>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      ):<PageIndex.Loading />}
    </>
  );
};
