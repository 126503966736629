import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  getStreakListAction,
  getStreakNotification,
} from "../../../../redux/slices/adminService";
import moment from "moment";
import { debounce } from "lodash";
import { NotificationModal } from "./NotificationModal";
import numeral from "numeral";
import FileExcel from "../../../../component/common/Exportexcel";

const StreakList = () => {
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation()
  const [streakList, setStreakList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [addEditData, setAddEditData] = useState({});
  const [excel, setExcel] = useState([]);
  // modal functions

  const handleOpen = () => {
    setModalOpen(!modalOpen);
  };
  const handleClose = () => {
    setModalOpen(!modalOpen);
  };

  //api of users feedback list
  const listOfStreak = () => {
    setLoader(true);
    const page = currentPage;
    getStreakListAction(page, search, rowsPerPage).then((res) => {
      if (res?.status === 200) {
        setLoader(false);
        setStreakList(res?.data);
        if (res?.currentPage) {
          setCurrentPage(res?.currentPage);
        }

        setPageCount(res?.totalCount ? res?.totalCount : 0);
      } else {
        setLoader(false);
        setStreakList([]);
      }
    });
  };

  useEffect(() => {
    const debouncedFetch = debounce(listOfStreak, 300);

    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      listOfStreak();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);

  const getNotification = () => {
    getStreakNotification().then((res) => {
      setAddEditData(res?.data[0]);
    });
  };

  useEffect(() => {
    getNotification();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const fileName = "User Streaks List";
  useEffect(() => {
    if (streakList?.length > 0) {
      const customHeadings = streakList?.map((item, index) => ({
        "S.No": index + 1,
        "Username": item?.userName,
        "Total Streaks Count": item?.totalStreakCount,
        "Start Date": item?.firstStreakDate?  moment(item?.firstStreakDate).format(
          "DD/MM/YYYY" 
        ) : "-",
      }));

      setExcel(customHeadings);
    }
  }, [streakList]);

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  User Streaks List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        // name="search"
                        value={search}
                        autoComplete="search"
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          setSearch(newValue);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="add-role-permission">
                  <Index.Box className="adduser-btn-main btn-main-primary">
                    {(rolePermission &&
                      rolePermission?.roleType?.rolePermission?.includes(
                        "StreakManagement_edit"
                      )) ||
                      (rolePermission && rolePermission?.isAdmin === true) ? (
                      <>
                        <Index.Button
                          className="adduser-btn btn-primary"
                          onClick={handleOpen}
                        >
                          <img
                            src={Index.Png.settings}
                            className="plus-icon"
                            alt="plus icon"
                          />
                          Streak Setting
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
                <Index.Box>
                <FileExcel apiData={excel} fileName={fileName} />
              </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="streak-table-main action-column page-table-main">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Username
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Total Streaks Count
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Start Date
                                </Index.TableCell>
                                {(rolePermission &&
                                  rolePermission?.roleType?.rolePermission?.includes(
                                    "StreakManagement_view"
                                  )) ||
                                  (rolePermission &&
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "StreakManagement_edit"
                                    )) ||
                                  (rolePermission &&
                                    rolePermission?.isAdmin === true) ? (
                                  <>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Action
                                    </Index.TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                streakList?.length > 0 ? (
                                  streakList?.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {(currentPage == undefined
                                          ? 0
                                          : currentPage - 1) *
                                          10 +
                                          index +
                                          1}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        <span className="cus-user-name-navigate"
                                          onClick={() => {
                                            navigate('/dashboard/user-view',
                                              {
                                                state: {
                                                  data: row?._id,
                                                  pathName: location.pathname
                                                },
                                              }
                                            )
                                          }}
                                        > {row?.userName ? row?.userName : "-"}</span>
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {/* {row?.totalStreakCount} */}
                                        {row?.totalStreakCount
                                          ? Number.isInteger(row?.totalStreakCount)
                                            ? numeral(row?.totalStreakCount).format('0')
                                            : numeral(row?.totalStreakCount).format('0.000a')
                                          : 0}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.firstStreakDate
                                          ? moment(row?.firstStreakDate).format(
                                            "DD/MM/YYYY"
                                          )
                                          : "-"}
                                      </Index.TableCell>

                                      {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "StreakManagement_view"
                                        )) ||
                                        (rolePermission &&
                                          rolePermission?.roleType?.rolePermission?.includes(
                                            "StreakManagement_edit"
                                          )) ||
                                        (rolePermission &&
                                          rolePermission?.isAdmin === true) ? (
                                        <>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            <Index.Box className="userdata-btn-flex">
                                              {(rolePermission &&
                                                rolePermission?.roleType?.rolePermission?.includes(
                                                  "StreakManagement_view"
                                                )) ||
                                                (rolePermission &&
                                                  rolePermission?.isAdmin ===
                                                  true) ? (
                                                <>
                                                  <PageIndex.LightTooltip title="View">
                                                    <Index.IconButton
                                                      color="primary"
                                                      // title="View"
                                                      aria-label="upload picture"
                                                      component="label"
                                                      onClick={() =>
                                                        navigate(
                                                          `/dashboard/streak-details`,
                                                          {
                                                            state: {
                                                              data: row?._id,
                                                              type: "view",
                                                            },
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <Index.RemoveRedEyeIcon />
                                                    </Index.IconButton>
                                                  </PageIndex.LightTooltip>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </Index.Box>
                                          </Index.TableCell>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={5} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={5} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {streakList?.length ? (
              <Index.Box className="pagination-main">
                  <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
              </Index.Box>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
        <Index.Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-delete modal"
        >
          <NotificationModal
            handleClose={handleClose}
            addEditData={addEditData}
            getNotification={getNotification}
          />
        </Index.Modal>
      </Index.Box>
    </>
  );
};

export default StreakList;
