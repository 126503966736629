import React, { useEffect } from "react";
import Index from "../../../../Index";
import dayjs from "dayjs";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { styled } from "@mui/material";
import numeral from "numeral";

const durationList = [
  { id: 7, name: "7 Days" },
  { id: 30, name: "1 Month" },
  { id: 90, name: "3 Months" },
  { id: 180, name: "6 Months" },
];

const FollowerDetailsMain = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  "& > div": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    flex: "1",
    margin: "0 4px",
  },
});
const FollowersGrowth = ({
  handleRangeChange,
  range,
  fromDate,
  toDate,
  handleFromDateChange,
  handleToDateChange,
  followerData,
  handleClearToDate,
  handleClearFromDate,
}) => {
  const [loader, setLoader] = React.useState(true);

  followerData = followerData?.map((item) => ({
    ...item,
    date: dayjs(item.date).format("MMM DD"), // Formats date as 'Jan 01'
  }));
  let totalFollows = 0;
  let totalUnfollows = 0;
  let totalfollwers = followerData?.forEach((item) => {
    totalFollows += item?.follow;
    totalUnfollows += item?.unfollow;
  });
  totalfollwers = totalFollows - totalUnfollows;

  // let options = {};
  const dataset = [
    {
      num: 0,
      month: "Jan",
    },
    {
      num: 0,
      month: "Feb",
    },
    {
      num: 0,
      month: "Mar",
    },
    {
      num: 0,
      month: "Apr",
    },
    {
      num: 0,
      month: "May",
    },
    {
      num: 0,
      month: "June",
    },
    {
      num: 0,
      month: "July",
    },
    {
      num: 0,
      month: "Aug",
    },
    {
      num: 0,
      month: "Sept",
    },
    {
      num: 0,
      month: "Oct",
    },
    {
      num: 0,
      month: "Nov",
    },
    {
      num: 0,
      month: "Dec",
    },
  ];

  const chartSetting = {
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
      },
    },
  };

  useEffect(() => {
    setLoader(false);
  }, [followerData]);

  return (
    <>
      {!loader ? (
        <Index.Box className="admin-dashboard-list-row follower-graph">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-userlist"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 2",
                  md: "span 2",
                  lg: "span 2",
                }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input request-input-box">
                  <Index.FormHelperText className="form-lable">
                    Range
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.FormControl className="date-range-dropdown">
                      <Index.Select
                        className="form-control select-drop-list request-drop-list"
                        name="range"
                        value={range}
                        // defaultValue={type ? type : "ALL"}
                        onChange={(e) => {
                          handleRangeChange(e.target.value);
                        }}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        <Index.MenuItem value="" className="menuitem">
                          Select
                        </Index.MenuItem>
                        {durationList &&
                          durationList?.map((val) => (
                            <Index.MenuItem
                              value={val?.id}
                              key={val?.id}
                              className="menuitem"
                            >
                              {val?.name}
                            </Index.MenuItem>
                          ))}
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 10",
                  md: "span 10",
                  lg: "span 10",
                }}
                className="grid-column"
              >
                <Index.Box className="graph-btn-main">
                  <Index.Box className="date-filter">
                    {" "}
                    <Index.Box className="input-box follower-date-filter from-input">
                      <Index.FormHelperText className="form-lable">
                        From
                      </Index.FormHelperText>
                      <Index.Box className="form-group date-picker-mui transaction-fieldset cus-datepicker">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                          className="date-adapter-main"
                        >
                          <Index.DemoContainer
                            components={["DatePicker", "DatePicker"]}
                            className="date-adapter-container date-picker-mui"
                          >
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              name="fromDate"
                              value={dayjs(fromDate)}
                              format="DD/MM/YYYY"
                              // value={values?.startTime}
                              // value={fromDate}
                              onChange={handleFromDateChange}
                              disableFuture
                              maxDate={dayjs(toDate)}
                            />
                            {fromDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearFromDate}
                              >
                                <Index.ClearIcon className="icon-clear" />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box follower-date-filter from-input">
                      <Index.FormHelperText className="form-lable">
                        To
                      </Index.FormHelperText>
                      <Index.Box className="form-group date-picker-mui transaction-fieldset cus-datepicker">
                        <Index.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                          className="date-adapter-main"
                        >
                          <Index.DemoContainer
                            components={["DatePicker", "DatePicker"]}
                            className="date-adapter-container"
                          >
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              name="toDate"
                              value={dayjs(toDate)}
                              format="DD/MM/YYYY"
                              // value={values?.startTime}
                              // value={fromDate}
                              onChange={handleToDateChange}
                              minDate={fromDate}
                              // disablePast
                              disableFuture
                            />
                            {toDate ? (
                              <Index.IconButton
                                className="clear-icon"
                                onClick={handleClearToDate}
                              >
                                <Index.ClearIcon className="icon-clear" />
                              </Index.IconButton>
                            ) : (
                              ""
                            )}
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <FollowerDetailsMain className="follower-details-main">
                  <div>
                    <Index.Typography variant="body1">Overall</Index.Typography>
                    <Index.Typography variant="body1">
                      {/* {totalfollwers} */}
                      {totalfollwers
                                    ? Number.isInteger(totalfollwers)
                                      ? numeral(totalfollwers).format('0')
                                      : numeral(totalfollwers).format('0.000a')
                                    : 0}
                    </Index.Typography>
                  </div>
                  <div>
                    <Index.Typography variant="body1">Follow</Index.Typography>
                    <Index.Typography variant="body1">
                      {/* {totalFollows} */}
                      {totalFollows
                                    ? Number.isInteger(totalFollows)
                                      ? numeral(totalFollows).format('0')
                                      : numeral(totalFollows).format('0.000a')
                                    : 0}
                    </Index.Typography>
                  </div>
                  <div>
                    <Index.Typography variant="body1">
                      Unfollow
                    </Index.Typography>
                    <Index.Typography variant="body1">
                      {/* {totalUnfollows} */}
                      {totalUnfollows
                                    ? Number.isInteger(totalUnfollows)
                                      ? numeral(totalUnfollows).format('0')
                                      : numeral(totalUnfollows).format('0.000a')
                                    : 0}
                    </Index.Typography>
                  </div>
                </FollowerDetailsMain>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                {followerData && followerData?.length > 0 ? (
                  <>
                    {/* <ReactApexChart options={chartData?.options} series={chartData?.series} type="bar" height={350} /> */}
                    <BarChart
                      dataset={followerData}
                      height={350}
                      series={[
                        {
                          data: followerData?.map(
                            (item) => item?.follow - item?.unfollow
                          ),
                          valueFormatter: function (val) {
                            const matchingData = followerData?.find(
                              (item) => item?.follow - item?.unfollow === val
                            );

                            if (matchingData) {
                              return `
                            Overall: ${
                              matchingData?.follow - matchingData?.unfollow
                            },
                            Follow: ${matchingData?.follow},
                            Unfollow: ${matchingData?.unfollow}
                          `;
                            }
                            return "$ " + val + " thousands";
                          },
                          label: "Record",
                          backgroundColor: "rgba(0, 123, 255, 0.2)",
                          borderColor: "rgba(0, 123, 255, 1)",
                          borderWidth: 0.2,
                          // color: ["#017E7C", "#FF5733"],
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: "band",
                          data: followerData?.map((item) => item?.date),
                          tickPlacement: "middle",
                          tickLabelPlacement: "middle",
                          id: "axis1",
                        },
                      ]}
                      tooltip={{
                        trigger: "axis",
                      }}
                      yAxis={[
                        {
                          // max:totalfollwers<=-2 ||totalfollwers<=10?10:null,
                          label: "Followers Growth",
                          valueFormatter: function (value) {
                            const absValue = value;
                            // if (absValue > 100) {
                            //   if (absValue <= 10) {
                            //     return absValue;
                            //   } else {
                            //     return absValue - 10;
                            //   }
                            // } else
                            if (absValue >= 1000000) {
                              return (absValue / 1000000).toFixed(1) + "m";
                            } else if (absValue >= 1000) {
                              return (absValue / 1000).toFixed(0) + "k";
                            } else {
                              return absValue.toString();
                            }
                          },
                        },
                      ]}
                      {...chartSetting}
                      // tooltip={{ trigger: 'none' }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <BarChart
                      dataset={dataset}
                      height={350}
                      series={[
                        {
                          data: dataset.map((item) => item.num),

                          label: "Record",
                          // color: ["#017E7C", "#FF5733"],
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: "band",
                          dataKey: "month",
                          tickPlacement: "middle",
                          tickLabelPlacement: "middle",
                          id: "axis1",
                        },
                      ]}
                      yAxis={[{ max: 100 }]}
                    />
                    ;
                    {/* <ReactApexChart options={chartData?.options} series={chartData?.series} type="bar" height={350} /> */}
                    <Index.Box className="user-activity-norecord-main">
                      <Index.Typography className="user-activity-norecord">
                        {" "}
                        No Record Found
                      </Index.Typography>
                    </Index.Box>
                  </>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        ""
      )}
    </>
  );
};

export default FollowersGrowth;
