import React, { useState } from "react";
import { resetPassword } from "../../../redux/slices/adminService";
import Index from "../../../component/Index";
import PageIndex from "../../PageIndex";
import { isResetPassword } from "../../../redux/slices/AdminSlice";
// for custom design of checkbox

export default function ResetPassword() {

  const { state } = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = PageIndex.useDispatch();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let initialValues = {
    password: "",
    confirm_password: "",
  }
  const ChangePassord = (values) => {
    setLoading(true);
    resetPassword({ ...values, adminId: state?.id }).then((val) => {
      if (val?.status == 200) {
        dispatch(isResetPassword(false))
        navigate("/");
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      }
    });
  };

  return (

    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main">
          <Index.Box className="login-left-bg">
            <Index.Box className="auth-logo-box">
              <img
                alt="logo_img"
                src={Index.Jpg.authlogo}
                className="auth-logo"
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Reset Password!
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  Please enter and confirm your new password below !
                </Index.Typography>
                <PageIndex.Formik
                  validationSchema={PageIndex.resetPasswordSchema}
                  initialValues={initialValues}
                  onSubmit={ChangePassord}
                >
                  {(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                         New Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye form-control"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            name="password"
                            placeholder="New Password"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.password}
                            error={Boolean(props.errors.password)}
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  className="toggle-eye-btn"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          {props?.errors?.password && props?.touched?.password && (
                            <Index.FormHelperText error>
                              {props?.errors?.password}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Confirm Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye form-control"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirm_password"
                            placeholder="Confirm Password"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.confirm_password}
                            error={Boolean(props.errors.confirm_password)}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  className="toggle-eye-btn"
                                  aria-label="toggle password visibility"
                                  
                                  onClick={handleClickShowConfirmPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showConfirmPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          {props?.errors?.confirm_password && props?.touched?.confirm_password && (
                            <Index.FormHelperText error>
                              {props?.errors?.confirm_password}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="flex-all forgot-row">
                        <Index.Box className="checkbox-main">
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn forgot-button"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <PageIndex.ButtonLoader color="white" loading={loading} />
                          ) : (
                            "Submit"
                          )}
                        </Index.Button>
                        <Index.Typography
                          component="span"
                          variant="span"
                          align="center"
                          className="admin-sign-para common-para admin-verify-btn back-text"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Go to Login page?
                        </Index.Typography>
                      </Index.Box>
                    </form>
                  )}
                </PageIndex.Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>

  );
}
