import React from 'react'
import Index from '../../container/Index'


const LinearProgresBar = () => {
  return (
    <>
          <Index.Stack
                  sx={{ width: "100%", color: "grey.500" }}
                  spacing={2}
                >
                 <Index.LinearProgress color="secondary" />
                </Index.Stack>
    </>
  )
}

export default LinearProgresBar