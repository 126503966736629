import React, { useState } from "react";
import Index from "../../../../component/Index";
import PageIndex from "../../../PageIndex";


const RoleMasterView = () => {
  const params = PageIndex.useLocation();
  const navigate=PageIndex.useNavigate()
  const editId = params?.state;

  const initialValues = {
    roleName: editId ? editId?.roleName : "",
    rolePermission: editId ? editId?.rolePermission : [],
  };

  const modules = [
    {
      tag: "UserMaster",
      title: "User Master",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "UserPost",
      title: "User Post",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "UserReport",
      title: "User Report",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "ReelsList",
      title: "Reels List",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "RoleMaster",
      title: "Role Master",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "AdminMaster",
      title: "Admin Master",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "UserFeedbackList",
      title: "User Feedback List",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "ContestList",
      title: "Contest List ",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "ContestWinnerList",
      title: "Contest Winner List",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "StreakManagement",
      title: "Streak Management",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "AddDiamondPrice",
      title: "Add Diamond Price",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "DiamondTickUsersList",
      title: "Diamond Tick Users List",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "CryptoList",
      title: "Crypto List ",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "true",
    },
    {
      tag: "WithdrawalList",
      title: "Withdrawal List ",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "ConversionList",
      title: "Conversion List ",
      isView: "true",
      isAdd: "false",   
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "FiatWalletManagement",
      title: "Fiat Wallet Management ",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "CryptoWalletManagement",
      title: "Crypto Wallet Management ",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "PlatformFee",
      title: "Platform Fee",
      isView: "true",
      isAdd: "false",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "TransactionsList",
      title: "Transactions List",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "NotificationList",
      title: "Notification List",
      isView: "true",
      isAdd: "true",
      isEdit: "true",
      isDelete: "false",
    },
    {
      tag: "ContactUsList",
      title: "Contact Us List ",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
    {
      tag: "StickersList",
      title: "Stickers List ",
      isView: "true",
      isAdd: "false",
      isEdit: "false",
      isDelete: "false",
    },
  ];

  return (
    <>
      <Index.Box className="dashboard-content">
<PageIndex.BackButton onClick={() => navigate("/dashboard/role-master")} />
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                   Role Details
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <PageIndex.Formik
              initialValues={initialValues}
            >
              {({
                values,
                handleBlur,
              }) => (
                <>
                  <Index.Box className="userlist-btn-flex cus-role-text-box">
                    <Index.Box className="input-box">
                      <Index.FormHelperText className="form-lable">
                        Role Name
                      </Index.FormHelperText>
                      <Index.Box className="form-group">
                        <Index.TextField
                          onBlur={handleBlur}
                          value={values.roleName}
                          disabled
                          name="roleName"
                          id="fullWidth"
                          className="form-control"
                          type="text"
                          autocomplete="off"
                        
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-dashboard-list-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        className="display-row-userlist"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-dash-box">
                            <Index.Box className="page-table-main role-master-add-table-main">
                              <Index.TableContainer
                                component={Index.Paper}
                                className="table-container"
                              >
                                <Index.Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                  className="table"
                                >
                                  <Index.TableHead className="table-head cus-table-head">
                                    <Index.TableRow className="table-row">
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Module
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        View
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Add
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Edit
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="th"
                                        variant="th"
                                        className="table-th"
                                        align="center"
                                      >
                                        Delete
                                      </Index.TableCell>
                                    </Index.TableRow>
                                  </Index.TableHead>
                          
                                      <Index.TableBody className="table-body">
                                    {modules.map((row) => (
                                      <Index.TableRow
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                        className="refferal-datarow-table"
                                        key={row?.tag}
                                      >
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="left"
                                        >
                                          {row?.title}
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="left"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                              
                                           disabled
                                              checked={
                                                values?.rolePermission?.includes(
                                                      row?.tag + "_view"
                                                    )
                                                    ? true
                                                   
                                                  : false
                                              }
                                         
                                            />
                                          </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                           disabled
                                              checked={values?.rolePermission?.includes(
                                                row?.tag + "_add"
                                              )}
                                        
                                            />
                                          </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                           disabled
                                              checked={values?.rolePermission?.includes(
                                                row?.tag + "_edit"
                                              )}
                                  
                                            />
                                          </Index.Box>
                                        </Index.TableCell>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          scope="row"
                                          className="table-td"
                                          align="center"
                                        >
                                          <Index.Box className="email-refferal">
                                            <Index.Checkbox
                                              name="rolePermission"
                                       disabled
                                              checked={values?.rolePermission?.includes(
                                                row?.tag + "_delete"
                                              )}
                                         
                                            />
                                          </Index.Box>
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    ))}
                                  </Index.TableBody>
                                </Index.Table>
                              </Index.TableContainer>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
             </>
              )}
            </PageIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default RoleMasterView;
