import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import dayjs from "dayjs";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { styled } from "@mui/material";
import { getLikeCommentGraphAction } from "../../../../../redux/slices/adminService";
import { debounce } from "lodash";
import numeral from "numeral";

const durationList = [
  { id: 7, name: "7 Days" },
  { id: 30, name: "1 Month" },
  { id: 90, name: "3 Months" },
  { id: 180, name: "6 Months" },
];

const FollowerDetailsMain = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  "& > div": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    flex: "1",
    margin: "0 4px",
  },
});

const TrendingPostGraph = ({ postId }) => {
  const [loader, setLoader] = useState(true);

  const [followerData, setFollowerData] = useState([]);
  const [range, setRange] = useState(7);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  const handleFromDateChange = (date) => {
    setRange("");
    setFromDate(date ? dayjs(date).format("YYYY-MM-DD") : null);
  };

  const handleToDateChange = (date) => {
    setToDate(date ? dayjs(date).format("YYYY-MM-DD") : null);
    setRange("");
  };

  const handleRangeChange = (newRange) => {
    setRange(newRange);
    setFromDate(null);
    setToDate(null);
  };

  const handleClearFromDate = () => setFromDate(null);
  const handleClearToDate = () => setToDate(null);

  const fetchUserFollowerActivity = () => {
    const params = {
      postId: postId,
      startDate: fromDate || "",
      endDate: toDate || "",
      days: range || "",
    };

    if (range || (fromDate && toDate)) {
      getLikeCommentGraphAction(params).then((data) => {
        if (data?.status === 200) {
          setFollowerData(data?.data || []);
        }
        setLoader(false);
      });
    }
  };



  useEffect(() => {
    const debouncedFetch = debounce(fetchUserFollowerActivity, 300);
    if (fromDate || toDate || range) {
      debouncedFetch();
    } else {
      setRange(7);
      fetchUserFollowerActivity();
    }
    return () => debouncedFetch.cancel();
  }, [fromDate, toDate, range]);

  useEffect(() => {
    setLoader(false);
  }, [followerData]);

  const dataset = Array.from({ length: 12 }, (_, i) => ({
    num: 0,
    month: dayjs().month(i).format("MMM"),
  }));

  const chartSetting = {
    sx: {
      [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
        transform: "translateX(-10px)",
      },
    },
  };

  const totalFollows = followerData.reduce((acc, item) => acc + item.likes, 0);
  const totalUnfollows = followerData.reduce((acc, item) => acc + item.comments, 0);
  const totalFollwers = totalFollows + totalUnfollows;

  return (
    <>
      {!loader && (
        <Index.Box className="admin-dashboard-list-row follower-graph">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-userlist"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{ xs: "span 12", sm: "span 2", md: "span 2", lg: "span 2" }}
                className="grid-column"
              >
                <Index.Box className="input-box add-user-input request-input-box">
                  <Index.FormHelperText className="form-label">Range</Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.FormControl className="date-range-dropdown">
                      <Index.Select
                        className="form-control select-drop-list request-drop-list"
                        name="range"
                        value={range}
                        onChange={(e) => handleRangeChange(e.target.value)}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <Index.MenuItem value="" className="menuitem">Select</Index.MenuItem>
                        {durationList.map((val) => (
                          <Index.MenuItem value={val.id} key={val.id} className="menuitem">
                            {val.name}
                          </Index.MenuItem>
                        ))}
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{ xs: "span 12", sm: "span 10", md: "span 10", lg: "span 10" }}
                className="grid-column"
              >
                <Index.Box className="graph-btn-main">
                  <Index.Box className="date-filter">
                    <Index.Box className="input-box follower-date-filter to-input">
                      <Index.FormHelperText className="form-label">From</Index.FormHelperText>
                      <Index.Box className="form-group date-picker-mui">
                        <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                          <Index.DemoContainer components={["DatePicker"]}>
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              name="fromDate"
                              value={dayjs(fromDate)}
                              format="DD/MM/YYYY"
                              onChange={handleFromDateChange}
                              disableFuture
                              maxDate={dayjs(toDate)}
                            />
                            {fromDate && (
                              <Index.IconButton className="clear-icon" onClick={handleClearFromDate}>
                                <Index.ClearIcon className="icon-clear" />
                              </Index.IconButton>
                            )}
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box follower-date-filter to-input ">
                      <Index.FormHelperText className="form-label">To</Index.FormHelperText>
                      <Index.Box className="form-group date-picker-mui">
                        <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
                          <Index.DemoContainer components={["DatePicker"]}>
                            <Index.MobileDatePicker
                              className="form-control datepicker"
                              name="toDate"
                              value={dayjs(toDate)}
                              format="DD/MM/YYYY"
                              onChange={handleToDateChange}
                              minDate={dayjs(fromDate)}
                              disableFuture
                            />
                            {toDate && (
                              <Index.IconButton className="clear-icon" onClick={handleClearToDate}>
                                <Index.ClearIcon className="icon-clear" />
                              </Index.IconButton>
                            )}
                          </Index.DemoContainer>
                        </Index.LocalizationProvider>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                className="grid-column"
              >
                <FollowerDetailsMain className="follower-details-main">
                  <div>
                    <Index.Typography variant="body1">Overall</Index.Typography>
                    <Index.Typography variant="body1">
                      {totalFollwers
                        ? Number.isInteger(totalFollwers)
                          ? numeral(totalFollwers).format('0')
                          : numeral(totalFollwers).format('0.000a')
                        : 0}

                    </Index.Typography>
                  </div>
                  <div>
                    <Index.Typography variant="body1">Like</Index.Typography>
                    <Index.Typography variant="body1">
                      {totalFollows
                        ? Number.isInteger(totalFollows)
                          ? numeral(totalFollows).format('0')
                          : numeral(totalFollows).format('0.000a')
                        : 0}
                    </Index.Typography>
                  </div>
                  <div>
                    <Index.Typography variant="body1">Comments</Index.Typography>
                    <Index.Typography variant="body1">
                      {totalUnfollows
                        ? Number.isInteger(totalUnfollows)
                          ? numeral(totalUnfollows).format('0')
                          : numeral(totalUnfollows).format('0.000a')
                        : 0}
                    </Index.Typography>
                  </div>
                </FollowerDetailsMain>
              </Index.Box>
              <Index.Box
                gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }}
                className="grid-column"
              >
                {followerData.length > 0 ? (
                  <BarChart
                    dataset={followerData}
                    height={350}
                    series={[
                      {
                        data: followerData.map(item => item.likes + item.comments),
                        valueFormatter: (val) => {
                          const matchingData = followerData.find(item => (item.likes + item.comments) === val);
                          return matchingData
                            ? `Overall: ${matchingData.likes + matchingData.comments}, Likes: ${matchingData.likes}, Comments: ${matchingData.comments}`
                            : "$ " + val + " thousands";
                        },
                        label: "Record",
                        backgroundColor: 'rgba(0, 123, 255, 0.2)',
                        borderColor: 'rgba(0, 123, 255, 1)',
                        borderWidth: 0.2,
                      },
                    ]}
                    xAxis={[
                      {
                        scaleType: "band",
                        data: followerData.map(item => item.date),
                        tickPlacement: "middle",
                        tickLabelPlacement: "middle",
                        id: 'axis1',
                      },
                    ]}
                    tooltip={{ trigger: 'axis' }}
                    yAxis={[
                      {
                        label: "Post / Cryp Growth",
                        // label: "Post / Reel Growth",
                        valueFormatter: (value) => {
                          if (value >= 1000000) {
                            return (value / 1000000).toFixed(1) + "m";
                          } else if (value >= 1000) {
                            return (value / 1000).toFixed(0) + "k";
                          } else {
                            return value.toString();
                          }
                        },
                      },
                    ]}
                    {...chartSetting}
                  />
                ) : (
                  <>
                    <BarChart
                      dataset={dataset}
                      height={350}
                      series={[
                        {
                          data: dataset.map(item => item.num),
                          label: "Record",
                        },
                      ]}
                      xAxis={[
                        {
                          scaleType: "band",
                          dataKey: "month",
                          tickPlacement: "middle",
                          tickLabelPlacement: "middle",
                          id: 'axis1',
                        },
                      ]}
                      yAxis={[{ max: 100 }]}
                    />
                    <Index.Box className="user-activity-norecord-main">
                      <Index.Typography className="user-activity-norecord">
                        No Record Found
                      </Index.Typography>
                    </Index.Box>
                  </>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
};

export default TrendingPostGraph;
