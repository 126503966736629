import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PageIndex";
import { imageUrl } from "../../../../../config/dataService";
import ReactPlayer from "react-player";
import { getDeviceHistoryAction, getJoinedContest } from "../../../../../redux/slices/adminService";
import moment from "moment";

const DeviceHistory = ({ userId }) => {
    //all state
    const [pageData, setPageData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(true)
    const [deviceData, setDeviceData] = useState([])


    const getDeviceHistory = () => {
        getDeviceHistoryAction(userId).then((response) => {
            if (response?.status == 200) {
                setDeviceData(response?.data);
                setLoader(false);
            } else {
                setLoader(false);
                setDeviceData({});
            }
        });
    };

    useEffect(() => {
        getDeviceHistory()
    }, [])

    useEffect(() => {
        const filterData = deviceData?.filter((item) => {
            return search
                ? item?.contestName
                    ?.toLowerCase()
                    .includes(search.toLocaleLowerCase()) ||
                item?.contestID
                    ?.toLowerCase()
                    .includes(search.toLocaleLowerCase())
                : item;
        });
        setFilterData(filterData);
    }, [search, deviceData]);



    return (
        <>
            <Index.Box className="dashboard-content">
                <Index.Box className="user-list-flex">
                    <Index.Box className="admin-page-title-main">
                        <Index.Typography
                            className="admin-page-title"
                            component="h2"
                            variant="h2"
                        >
                            Device History
                        </Index.Typography>
                    </Index.Box>
                    <Index.Box className="userlist-btn-flex">
                        <Index.Box className="user-search-main">
                            <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder="Search"
                                        name="search"
                                        value={search}
                                        onChange={(e) => {
                                            const newValue = e.target.value
                                                .replace(/^\s+/, "")
                                                .replace(/\s\s+/g, " ");
                                            setSearch(newValue);
                                        }}
                                    />
                                    <span className="search-icon-box">
                                        <img src={Index.Svg.search} className="search-icon" />
                                    </span>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="admin-dashboard-list-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                            display="grid"
                            className="display-row-userlist"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                            <Index.Box
                                gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                }}
                                className="grid-column"
                            >
                                <Index.Box className="admin-dash-box">
                                    <Index.Box className="page-table-main user-joined-table ">
                                        <Index.TableContainer
                                            component={Index.Paper}
                                            className="table-container"
                                        >
                                            <Index.Table
                                                sx={{ minWidth: 650 }}
                                                aria-label="simple table"
                                                className="table"
                                            >
                                                <Index.TableHead className="table-head cus-table-head">
                                                    <Index.TableRow className="table-row">
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            S.No.
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Device Brand
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                             Model Name
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Device Name
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            Device Version
                                                        </Index.TableCell>

                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            LogIn Date
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                            align="center"
                                                        >
                                                            LogIn Time
                                                        </Index.TableCell>

                                                    </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody className="table-body">
                                                    {!loader ? (
                                                        pageData?.length > 0 ? (
                                                            pageData?.map((row, index) => (
                                                                <Index.TableRow
                                                                    key={row._id}
                                                                    sx={{
                                                                        "&:last-child td, &:last-child th": {
                                                                            border: 0,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        scope="row"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.sNo}
                                                                    </Index.TableCell>

                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        scope="row"
                                                                        className="table-td "
                                                                        align="center"

                                                                    >

                                                                        {row?.deviceBrand ? row?.deviceBrand : "-"}
                                                                    </Index.TableCell>

                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >

                                                                        {row?.deviceModelName ? row?.deviceModelName : "-"}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.deviceName ? row?.deviceName : "-"}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.deviceVersion ? row?.deviceVersion : "-"}
                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.loginTime?moment(row?.loginTime).format(
                                                                            "DD/MM/YYYY"
                                                                        ):'-'}

                                                                    </Index.TableCell>
                                                                    <Index.TableCell
                                                                        component="td"
                                                                        variant="td"
                                                                        className="table-td"
                                                                        align="center"
                                                                    >
                                                                        {row?.loginTime?moment(row?.loginTime).format(" hh:mm A"):'-'}
                                                                    </Index.TableCell>
                                                                 
                                                                </Index.TableRow>
                                                            ))
                                                        ) : (
                                                            <PageIndex.RecordNotFound colSpan={9} />
                                                        )
                                                    ) : (
                                                        <PageIndex.TableLoader colSpan={9} />
                                                    )}
                                                </Index.TableBody>
                                            </Index.Table>
                                        </Index.TableContainer>
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            {/* Pagination */}
            <PageIndex.Pagination
                fetchData={filterData}
                setPageData={setPageData}
                pageData={pageData}
            />
        </>
    );
};

export default DeviceHistory;
