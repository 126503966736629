import React from "react";
import Index from "../Index";
import PageIndex from "../../container/PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const ArchiveModel = ({
  openArchive,
  handleCloseArchive,
  handleArchiveRecord,
  isDeleteButtonDisabled,
  isArchive,
}) => {
  return (
    <>
      <Index.Modal
        open={openArchive}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main cus-modal-margin">
            <Index.IconButton onClick={handleCloseArchive}>
              <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Are you sure?
          </Index.Typography>
          <Index.Typography
            className="delete-modal-para common-para"
            component="p"
            variant="p"
          >
            {`Do you really want to ${
              isArchive == true ? "archive" : "restore"
            }?`}
          </Index.Typography>
          <Index.Box className="delete-modal-btn-flex">
            <Index.Button
              className="modal-cancel-btn modal-btn"
              onClick={handleCloseArchive}
            >
              Cancel
            </Index.Button>

            <Index.Button
              className="modal-delete-btn modal-btn"
              onClick={handleArchiveRecord}
              disabled={isDeleteButtonDisabled}
            >
              {isDeleteButtonDisabled ? (
                <PageIndex.ButtonLoader
                  color="white"
                  size={14}
                  loading={isDeleteButtonDisabled}
                />
              ) : (
                `${isArchive == true ? "Archive" : "Restore"}`
              )}
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default ArchiveModel;
