import React from "react";
import Index from "../../../Index";

const Statistics = () => {
  return (
    <>
      <Index.Box className="stat-section-grid-box">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 4",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column cus-stat-center-box"
            >
              <Index.Box className="stat-download-count-box">
                <Index.Typography className="stat-down-count-text">
                  100K
                </Index.Typography>
              </Index.Box>
              <Index.Box className="stat-download-text-box">
                <Index.Typography className="stat-download-text">
                  Downloads
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 4",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="stat-download-count-box">
                <Index.Typography className="stat-down-count-text">
                  +10K
                </Index.Typography>
              </Index.Box>
              <Index.Box className="stat-download-text-box">
                <Index.Typography className="stat-download-text">
                  Videos
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 4",
                md: "span 4",
                lg: "span 4",
              }}
              className="grid-column"
            >
              <Index.Box className="stat-download-count-box">
                <Index.Typography className="stat-down-count-text">
                  +98.50K
                </Index.Typography>
              </Index.Box>
              <Index.Box className="stat-download-text-box">
                <Index.Typography className="stat-download-text">
                  Happy Users
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Statistics;
