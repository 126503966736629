import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import UserList from "./UserList";
import UnverifiedUser from "./UnverifiedUser";
import { autoLogout } from "../../../../redux/slices/adminService";
import { logout, updateRolePermission } from "../../../../redux/slices/AdminSlice";



const Users = () => {
  const params = PageIndex.useLocation();
  const navigate = PageIndex.useNavigate()
const dispatch=PageIndex.useDispatch()
const { token } = PageIndex.useSelector((state) => state.admin);
  const [value, setValue] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      }
      else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    })
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    if (params?.state?.btnValue) {
        setValue(params?.state?.btnValue);
    }
  }, [params?.state?.btnValue]);
  return (
    <Index.Box className="table-wrape">
      <Index.Box className="external-tab-box">
        <Index.Box className="admin-page-title-main">
          <Index.Typography
            className="admin-page-title tittle-like-costam"
            component="h2"
            variant="h2"
          >
            Users List
          </Index.Typography>
        </Index.Box>
        <Index.Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="admin-tabs-main"
        >
          <Index.Tab label="Verified Users" {...a11yProps(0)} className="admin-tab" />
          <Index.Tab label="Unverified Users" {...a11yProps(1)} className="admin-tab" />
        
        </Index.Tabs>

      </Index.Box>
      {value == 0 ? (
        <>
          <UserList btnValue={value} setBtnValue={setValue} />
        </>
      )
       :  <UnverifiedUser btnValue={value} setBtnValue={setValue} />
      }
    </Index.Box>
  );
};
export default Users;
