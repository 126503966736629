import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getCurrencyListAction } from "../../../../redux/slices/adminService";
import { imageUrl } from "../../../../config/dataService";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const ContestDetailView = () => {
  const roleTypeData = [
    { id: "1", name: "Popular" },
    { id: "2", name: "Random" },
  ];
  const SubCategoryListData = [
    { id: "1", name: "Cryp", value: "Reel" },
    { id: "2", name: "Post", value: "Post" },
  ];
  // const SubCategoryListData = [
  //   { id: "1", name: "Reel", value: "Reel" },
  //   { id: "2", name: "Post", value: "Post" },
  // ];
  const contestType = [
    { id: "1", name: "Quick play", value: "quickPlay" },
    { id: "2", name: "Mega play", value: "megaPlay" },
  ];
  const currencyTypeData = [
    { id: "1", name: "Crypto" },
    { id: "2", name: "Fiat" },
  ];

  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const [loading, setLoading] = useState(true);
  const [currencyList, setCurrencyList] = useState([]);

  function convertTimeToMinutes(timeString) {
    var [hour, minute, second] = timeString?.split(":");
    return parseInt(hour) * 60 + parseInt(minute) + parseInt(second) / 60;
  }
  const state = location?.state?.data;

  let initialValues;
  if (state) {
    initialValues = {
      contestFees: state ? state?.contestFees : "",
      startDate: state ? state?.startDate : "",
      startTime: state ? state?.startTime : "",
      entryTime: state
        ? convertTimeToMinutes(
            state?.entryTime ? state?.entryTime : state?.entryTime
          )
        : "",
      resultTime: state
        ? convertTimeToMinutes(
            state?.resultTime ? state?.resultTime : state?.resultTime
          )
        : "",
      subCategory: state ? state?.subCategory : "",
      category: state ? state?.category : "",
      contestType: state ? state?.contestType : "",
      contestName: state ? state?.contestName : "",
      spotNumber: state ? state?.spotNumber : "",
      currency: state?.currency,
      limit: state ? state?.limit : "",
      currencyType: state ? state?.fiatType : state?.cryptoType?._id,
      // currencyType: state
      //   ? state?.fiatType
      //     ? state?.fiatType?._id
      //     : state?.cryptoType?._id
      //   : "",
      adminComission: state ? state?.adminComission : "",
      winningPrice:
        state && Array.isArray(state.winningPrice)
          ? [...state.winningPrice]
          : [],
      banner: state ? state?.banner : "",
      description: state ? state?.description : "",
      isEdit: true,
      ruleId: state ? state?.ruleId : "",
    };
  }

  const getcurrencyType = (data) => {
    getCurrencyListAction(data).then((res) => {
      setCurrencyList(res?.data);
    });
  };
  useEffect(() => {
    getcurrencyType(state?.currency);
  }, [state]);

  const handleBack = () => {
    navigate("/dashboard/contest-list");
  };

  useEffect(() => {
    if (state) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [state]);

  return (
    <>
      {!loading ? (
        <>
          <Index.Box className="dashboard-content add-user-containt">
            <PageIndex.BackButton
              onClick={() => navigate("/dashboard/contest-list")}
            />
            <Index.Box className="barge-common-box">
              <Index.Typography
                className="admin-page-title cus-heading"
                component="h2"
                variant="h2"
              >
                View Contest
              </Index.Typography>

              <Index.Box className="add-user-data-main">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row-add-user"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Contest Name
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>

                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            disabled
                            className="form-control"
                            placeholder="Contest Name"
                            name="contestName"
                            value={initialValues.contestName}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input ">
                        <Index.FormHelperText className="form-lable">
                          Contest Fee{" "}
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group contest-fee-currency">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            className="form-control contest-fee contest-currency"
                            placeholder="Contest Fee"
                            name="currency"
                            value={initialValues.currency}
                            disabled
                          />
                          <Index.Select
                            className="form-control select-drop-list contest-currency"
                            name="currencyType"
                            value={initialValues?.currencyType}
                            disabled
                            displayEmpty
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                          >
                            <Index.MenuItem
                              value=""
                              // disabled
                              className="menuitem"
                            >
                              Select currency
                            </Index.MenuItem>

                            {currencyList &&
                              currencyList?.map((val) => (
                                <Index.MenuItem
                                  value={val?._id}
                                  key={val?._id}
                                  className="menuitem"
                                >
                                  {initialValues.currency != "Crypto"
                                    ? val?.coins
                                    : val?.tokenName}
                                </Index.MenuItem>
                              ))}
                          </Index.Select>
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            disabled
                            className="form-control contest-fee"
                            placeholder="Contest Fee"
                            name="contestFees"
                            value={initialValues.contestFees}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Start Date{" "}
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group custom-datepicker">
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            className="date-adapter-main"
                          >
                            <DemoContainer
                              components={["DatePicker", "DatePicker"]}
                              className="date-adapter-container"
                            >
                              <MobileDatePicker
                                className="date-adapter-picker"
                                name="startDate"
                                value={
                                  initialValues?.startDate
                                    ? dayjs(initialValues?.startDate)
                                    : ""
                                }
                                format="DD/MM/YYYY"
                                disabled
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Start Time{" "}
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            placeholder="Start Time"
                            name="startTime"
                            value={initialValues.startTime}
                            disabled
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Entry Time (In Minute){" "}
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            placeholder="Entry Time"
                            name="entryTime"
                            value={initialValues.entryTime}
                            disabled
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Result Time (In Minute){" "}
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="form-control"
                            placeholder="Result Time"
                            name="resultTime"
                            value={initialValues.resultTime}
                            disabled
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Category
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.FormControl className="radio-main">
                            <Index.Select
                              className="form-control select-drop-list"
                              name="category"
                              disabled
                              value={initialValues?.category}
                              defaultValue={
                                initialValues ? initialValues?.category : ""
                              }
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                            >
                              <Index.MenuItem
                                value=""
                                // disabled
                                className="menuitem"
                              >
                                Select category
                              </Index.MenuItem>
                              {roleTypeData &&
                                roleTypeData?.map((val) => (
                                  <Index.MenuItem
                                    value={val?.name}
                                    key={val?.id}
                                    className="menuitem"
                                  >
                                    {val?.name}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Sub-Category
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.FormControl className="radio-main">
                            <Index.Select
                              className="form-control select-drop-list"
                              name="subCategory"
                              disabled
                              value={initialValues?.subCategory}
                              defaultValue={
                                initialValues ? initialValues?.subCategory : ""
                              }
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                            >
                              <Index.MenuItem value="" className="menuitem">
                                Select Sub-Category
                              </Index.MenuItem>
                              {SubCategoryListData &&
                                SubCategoryListData?.map((val) => (
                                  <Index.MenuItem
                                    value={val?.value}
                                    key={val?.id}
                                    className="menuitem"
                                  >
                                    {val?.name}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Contest Type{" "}
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.FormControl className="radio-main">
                            <Index.Select
                              className="form-control select-drop-list"
                              name="category"
                              value={initialValues?.contestType}
                              defaultValue={
                                initialValues ? initialValues?.contestType : ""
                              }
                              disabled
                              displayEmpty
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                            >
                              <Index.MenuItem
                                value=""
                                // disabled
                                className="menuitem"
                              >
                                Select contest type
                              </Index.MenuItem>
                              {contestType &&
                                contestType?.map((val) => (
                                  <Index.MenuItem
                                    value={val?.value}
                                    key={val?.id}
                                    className="menuitem"
                                  >
                                    {val?.name}
                                  </Index.MenuItem>
                                ))}
                            </Index.Select>
                          </Index.FormControl>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Admin Commission
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group ">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            className="form-control"
                            placeholder="Admin Commission"
                            name="adminComission"
                            value={initialValues.adminComission}
                            disabled
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Rule Book
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group ">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            className="form-control"
                            placeholder="Rule Book"
                            name="ruleId"
                            value={initialValues?.ruleId?.ruleName}
                            disabled
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input">
                        <Index.FormHelperText className="form-lable">
                          Spot Number (No. of Seats)
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            className="form-control"
                            placeholder="Spot Number"
                            name="spotNumber"
                            value={initialValues.spotNumber}
                            disabled
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="input-box add-user-input ">
                        <Index.FormHelperText className="form-lable">
                          Limit(min limit)
                          <span className="mandatory-asterisk">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            type="text"
                            disabled
                            className="form-control"
                            placeholder="Limit"
                            name="limit"
                            value={initialValues.limit}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column "
                    >
                      {initialValues?.winningPrice?.map((field, index) => (
                        <Index.Box
                          // gridColumn={{ xs: "span 8" }}
                          className="grid-column rank_dis_input "
                          key={index}
                        >
                          <Index.Box
                            display="grid"
                            className="display-row-add-user"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                          >
                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column "
                            >
                              <Index.Box className="rank_distribution_wrap">
                                <Index.Box className="input-box add-user-input rank_dis_input  price-box-main custom-price-box-main ">
                                  <Index.Box className="form-group">
                                    <Index.FormHelperText className="form-lable">
                                      Rank
                                      <span className="mandatory-asterisk">
                                        *
                                      </span>
                                    </Index.FormHelperText>

                                    <Index.TextField
                                      fullWidth
                                      id={`rankInput-${index}`}
                                      type="text"
                                      className="form-control rank-input"
                                      placeholder="Rank (1 to 5)"
                                      name={`winningPrice[${index}].Rank`}
                                      value={
                                        initialValues?.winningPrice[index]?.Rank
                                      }
                                      disabled
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box
                              gridColumn={{
                                xs: "span 12",
                                sm: "span 6",
                                md: "span 6",
                                lg: "span 6",
                              }}
                              className="grid-column "
                            >
                              <Index.Box
                                gridColumn={{
                                  xs: "span 12",
                                  sm: "span 6",
                                  md: "span 6",
                                  lg: "span 6",
                                }}
                                className="grid-column "
                              >
                                <Index.Box className="form-group">
                                  <Index.Box className="price-lable-box">
                                    <Index.FormHelperText className="form-lable">
                                      Price Distribution (%){" "}
                                      <span className="mandatory-asterisk">
                                        *
                                      </span>
                                    </Index.FormHelperText>
                                  </Index.Box>

                                  <Index.TextField
                                    fullWidth
                                    id={`fullWidth-${index}`}
                                    type="text"
                                    className="form-control percentage-input"
                                    placeholder={`Price Distribution`}
                                    name={`winningPrice[${index}].prize`}
                                    value={
                                      initialValues?.winningPrice[index]
                                        ?.prize || ""
                                    }
                                    disabled
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      ))}
                    </Index.Box>

                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box
                        display="grid"
                        className="display-row-add-user"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Description
                              <span className="mandatory-asterisk">*</span>
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextareaAutosizeed
                                fullWidth
                                disabled
                                id=""
                                className="form-control contest-text-autosize"
                                placeholder="Description"
                                name="description"
                                value={initialValues.description}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box add-user-input">
                            <Index.FormHelperText className="form-lable">
                              Banner Image
                            </Index.FormHelperText>
                            <Index.Box className="profile-header cus-banner-box">
                              <Index.Box className="profile-input-box">
                                <label htmlFor="btn-upload">
                                  <img
                                    src={
                                      initialValues?.banner
                                        ? initialValues?.banner instanceof Blob
                                          ? URL.createObjectURL(
                                              initialValues.banner
                                            )
                                          : `${imageUrl}${initialValues.banner}`
                                        : PageIndex.Png.cameraImage
                                    }
                                    alt="banner img"
                                    className={
                                      initialValues?.banner
                                        ? "banner-img"
                                        : "profile-img mb-15"
                                    }
                                  />
                                  <input
                                    id="btn-upload"
                                    name="banner"
                                    className="profile-upload-btn"
                                    type="file"
                                    accept="image/*"
                                    disabled
                                  />
                                </label>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </>
      ) : (
        <PageIndex.Loading />
      )}
    </>
  );
};

export default ContestDetailView;
