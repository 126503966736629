import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import {
  deleteProfileReport,
  getSingleProfileReportsList,
  sendMailProfileReports,
  suspendUser,
} from "../../../../redux/slices/adminService";
import moment from "moment/moment";
import numeral from "numeral";

const ViewProfileReport = () => {
  ////////////////////////////////////////////
  const location = PageIndex.useLocation();
  let navigate = PageIndex.useNavigate();
  const postId = location?.state?.postId;
  const reportId = location?.state?.reportId;
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);

  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [ProfileReportist, setProfileReportist] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [isMail, setIsMail] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [suspendUserId, setSuspendUserId] = useState();
  const [openSuspend, setOpenSuspend] = useState(false);
  const [imagePath, setImagePath] = useState();
  const [openImage, setOpenImage] = useState(false);
  const [openSendMailModel, setOpenSendMailModel] = useState(false);
  const [mailModelHeading, setMailModelHeading] = useState("");
  const [mailModelType, setMailModelType] = useState("Profile_Report");
  const [modelType, setModelType] = useState("");

  //Fetch Post Report List

  const fetchSinglePostReport = () => {
    setLoader(true);
    getSingleProfileReportsList(reportId).then((data) => {
      if (data?.status == 200) {
        setLoader(false);
        setProfileReportist(data?.data);
        setFilterData(data?.data);
      } else {
        setProfileReportist([]);
        setLoader(false);
      }
    });
  };
  useEffect(() => {
    fetchSinglePostReport();
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 4000);

  const handleOpenImage = (image) => {
    if (image) {
      setOpenImage(true);
      setImagePath(image);
    }
  };

  const handleImageClose = () => {
    setImagePath("");
    setOpenImage(false);
  };
  useEffect(() => {
    const filterData = ProfileReportist?.filter((item) => {
      return search
        ? item?.userId?.userName
            ?.toLowerCase()
            .includes(search?.toLowerCase()) ||
            item?.report?.toLowerCase().includes(search?.toLowerCase()) ||
            new Date(item?.createdAt)
              ?.toLocaleDateString("en-GB")
              ?.toString()
              .includes(search?.toLowerCase())
        : item;
    });
    setFilterData(filterData);
  }, [search, ProfileReportist]);

  const handleOpenDelete = () => {
    setOpenDelete(true);
    setDeleteId(reportId);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const deleteUser = (id) => {
    setIsDeleteButtonDisabled(true);
    deleteProfileReport({ id: deleteId }).then((res) => {
      handleCloseDelete();
      fetchSinglePostReport();
      setIsDeleteButtonDisabled(false);
      if (res?.status === 200) {
        navigate("/dashboard/user-report", {
          state: {
            postKey: 2,
          },
        });
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };

  const handleSendMail = () => {
    setIsMail(true);
    sendMailProfileReports(reportId).then((data) => {
    
      if (data?.status == 200) {
        setIsMail(false);
      } else {
        setTimeout(() => {
          setIsMail(false);
        }, 3000);
      }
    });
  };
  const suspendUserByAdmin = () => {
    setIsDeleteButtonDisabled(true);
    suspendUser(reportId).then((res) => {
      setIsDeleteButtonDisabled(false);
      fetchSinglePostReport();
      if (res?.status === 200) {
        handleCloseSuspend();
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };
  const handleOpenSuspend = (type) => {
    setOpenSuspend(true);
    setSuspendUserId(postId);
  };
  const handleCloseSuspend = () => setOpenSuspend(false);

  const handleOpenSendMailModel = (heading, type) => {
    setOpenSendMailModel(true);
    setMailModelHeading(heading);
    setModelType(type);
    setDeleteId(reportId);
  };

  const handleCloseSendMail = () => setOpenSendMailModel(false);
  ////////////////////////////////////////////////

  return (
    <>
      <Index.Box className="user-details-back-btn">
        <PageIndex.BackButton
          onClick={() =>
            navigate("/dashboard/user-report", {
              state: {
                postKey: 2,
              },
            })
          }
        />
      </Index.Box>
      <Index.Box className="user-detail-section">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
            // gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 3",
              }}
              className="grid-column"
            >
              <Index.Box className="left-wrape">
                <Index.Box className="profile-wrape">
                  <Index.Box
                    className="image-wrape"
                    onClick={() =>
                      handleOpenImage(ProfileReportist[0]?.reportId?.profile)
                    }
                  >
                    <img
                      src={
                        ProfileReportist[0]?.reportId?.profile
                          ? ProfileReportist[0]?.reportId?.profile instanceof
                            Blob
                            ? URL.createObjectURL(
                                ProfileReportist[0]?.reportId?.profile
                              )
                            : `${imageUrl}${ProfileReportist[0]?.reportId?.profile}`
                          : PageIndex?.Jpg?.dummyImage
                      }
                      className="user-post-post-img"
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="detail">
                  <label>Full Name</label>
                  <p>
                    {" "}
                    {ProfileReportist[0]?.reportId?.name
                      ? ProfileReportist[0]?.reportId?.name
                      : "-"}
                  </p>
                  <label> Email</label>
                  <p>
                    {" "}
                    {ProfileReportist[0]?.reportId?.email
                      ? ProfileReportist[0]?.reportId?.email
                      : "-"}
                  </p>
                  <label>Username</label>
                  <p>
                    <span
                      className=" cus-user-name-navigate"
                      onClick={() => {
                        navigate("/dashboard/user-view", {
                          state: {
                            data: ProfileReportist[0]?.reportId?._id,
                            reportId: reportId,
                            pathName: location.pathname,
                          },
                        });
                      }}
                    >
                      {ProfileReportist[0]?.reportId?.userName
                        ? ProfileReportist[0]?.reportId?.userName
                        : "-"}
                    </span>
                  </p>
                  <label>Phone Number</label>
                  <p>
                    {ProfileReportist[0]?.reportId?.phoneNumber
                      ? `${ProfileReportist[0]?.reportId?.countryCode} ${ProfileReportist[0]?.reportId?.phoneNumber}`
                      : "-"}
                  </p>
                  <label>Engagement Ratio</label>
                  <p>
                    {" "}
                    {ProfileReportist[0]?.allLikeComment
                      ? `${ProfileReportist[0]?.allLikeComment?.toFixed(2)}%`
                      : "-"}
                  </p>
                  <label>Country</label>
                  <p>
                    {" "}
                    {ProfileReportist[0]?.reportId?.country
                      ? ProfileReportist[0]?.reportId?.country
                      : "-"}
                  </p>

                  <label>Bio</label>
                  <p>
                    {ProfileReportist[0]?.reportId?.Bio &&
                    ProfileReportist[0]?.reportId?.Bio?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={ProfileReportist[0]?.reportId?.Bio}
                      >
                        {ProfileReportist[0]?.reportId?.Bio.slice(0, 25) +
                          "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      ProfileReportist[0]?.reportId?.Bio || "-"
                    )}
                  </p>
                  <label>Suspend</label>
                  <p>
                    {ProfileReportist[0]?.reportId?.isSuspended === true
                      ? "Yes"
                      : "No"}
                  </p>
                  <label>Links</label>
                  {/* <p>{ProfileReportist[0]?.reportId?.links ? ProfileReportist[0]?.reportId?.links : "-"}</p> */}
                  <p>
                    {ProfileReportist[0]?.reportId?.createdBy?.links &&
                    ProfileReportist[0]?.reportId?.createdBy?.links?.length >
                      25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={ProfileReportist[0]?.reportId?.createdBy?.links
                          ?.split(",")
                          .map((item) => (
                            <p>{item}</p>
                          ))}
                      >
                        {ProfileReportist[0]?.reportId?.createdBy?.links.slice(
                          0,
                          25
                        ) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      ProfileReportist[0]?.reportId?.createdBy?.links?.Bio ||
                      "-"
                    )}
                  </p>

                  <label>Following Count</label>
                  <p>
                    {/* {ProfileReportist[0]?.followingCount ? ProfileReportist[0]?.followingCount : 0} */}
                    {ProfileReportist[0]?.followingCount
                      ? Number.isInteger(ProfileReportist[0]?.followingCount)
                        ? numeral(ProfileReportist[0]?.followingCount).format(
                            "0"
                          )
                        : numeral(ProfileReportist[0]?.followingCount).format(
                            "0.000a"
                          )
                      : 0}
                  </p>
                  <label>Followers Count</label>
                  <p>
                    {/* {ProfileReportist[0]?.followersCount ? ProfileReportist[0]?.followersCount : 0} */}
                    {ProfileReportist[0]?.followersCount
                      ? Number.isInteger(ProfileReportist[0]?.followersCount)
                        ? numeral(ProfileReportist[0]?.followersCount).format(
                            "0"
                          )
                        : numeral(ProfileReportist[0]?.followersCount).format(
                            "0.000a"
                          )
                      : 0}
                  </p>
                </Index.Box>
                {(rolePermission &&
                  rolePermission?.roleType?.rolePermission?.includes(
                    "UserReport_view"
                  )) ||
                (rolePermission &&
                  rolePermission?.roleType?.rolePermission?.includes(
                    "UserReport_edit"
                  )) ||
                rolePermission?.roleType?.rolePermission?.includes(
                  "UserReport_delete"
                ) ||
                (rolePermission && rolePermission?.isAdmin === true) ? (
                  <>
                    <Index.Box className="userdata-btn-flex">
                      {(rolePermission &&
                        rolePermission?.roleType?.rolePermission?.includes(
                          "UserReport_edit"
                        )) ||
                      (rolePermission && rolePermission?.isAdmin === true) ? (
                        <>
                          <PageIndex.LightTooltip title="Warning mail">
                            <Index.IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                              disabled={isMail}
                              onClick={() => {
                                // handleSendMail();
                                const heading =
                                  "Do you really want to a send warning mail.";
                                handleOpenSendMailModel(heading, "Warning");
                              }}
                            >
                              <img
                                alt="img"
                                className="usermailwarning-img"
                                src={Index.Png.userMailWarning}
                              />
                            </Index.IconButton>
                          </PageIndex.LightTooltip>
                        </>
                      ) : (
                        ""
                      )}

                      {rolePermission?.roleType?.rolePermission?.includes(
                        "UserReport_delete"
                      ) ||
                      (rolePermission && rolePermission?.isAdmin === true) ? (
                        <>
                          <PageIndex.LightTooltip title="Delete User">
                            <Index.IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="label"
                              onClick={() => {
                                // handleOpenDelete();
                                const heading =
                                  "Do you really want to delete these records? This process cannot be undone.";
                                handleOpenSendMailModel(heading, "Delete");
                              }}
                            >
                              <Index.DeleteIcon className="usermailwarning-img" />
                            </Index.IconButton>
                          </PageIndex.LightTooltip>
                        </>
                      ) : (
                        ""
                      )}

                      {rolePermission && rolePermission?.isAdmin === true ? (
                        <PageIndex.LightTooltip
                          title={
                            ProfileReportist[0]?.reportId?.isSuspended != true
                              ? "Suspend"
                              : "Unsuspend"
                          }
                        >
                          <Index.IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="label"
                            // disabled={userdata?.isSuspended === true}
                            onClick={() => {
                              // handleOpenSuspend();
                              const heading =
                                " Do you really want to Suspend this user? This process cannot be undone.";
                              handleOpenSendMailModel(heading, "Suspend");
                            }}
                          >
                            <img
                              src={
                                ProfileReportist[0]?.reportId?.isSuspended !=
                                true
                                  ? Index.Png.blockUser
                                  : Index.Png.unBlockUser
                              }
                              className="dash-icons"
                              alt="dashboard icon"
                            />
                            {/* <Index.DeleteIcon className="usermailwarning-img" /> */}
                          </Index.IconButton>
                        </PageIndex.LightTooltip>
                      ) : (
                        ""
                      )}
                    </Index.Box>
                  </>
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 9",
              }}
              className="grid-column"
            >
              <Index.Box className="right-wrape">
                <Index.Box className="table-wrape">
                  <Index.Box className="external-tab-box">
                    <Index.Box className="barge-common-box">
                      <Index.Box className="common-box">
                        <Index.Box className="user-list-flex">
                          <Index.Box className="admin-page-title-main">
                            <Index.Typography
                              className="admin-page-title tittle-like-costam"
                              component="h2"
                              variant="h2"
                            >
                              Profile Report
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="userlist-btn-flex">
                            <Index.Box className="user-search-main">
                              <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    value={search}
                                    className="form-control"
                                    placeholder="Search user"
                                    onChange={(e) => {
                                      const newValue = e.target.value
                                        .replace(/^\s+/, "")
                                        .replace(/\s\s+/g, " ");

                                      setSearch(newValue);
                                    }}
                                  />
                                  <span className="search-icon-box">
                                    <img
                                      src={Index.Svg.search}
                                      className="search-icon"
                                    />
                                  </span>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="admin-dash-box">
                          <Index.Box className="page-table-main like-table-main">
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              <Index.Table
                                stickyHeader
                                aria-label="simple table"
                                className="table"
                              >
                                <Index.TableHead className="table-head cus-table-head">
                                  <Index.TableRow className="table-row">
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      S.No.
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Report
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Report By
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Created Date
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Created Time
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody className="table-body">
                                  {!loader ? (
                                    pageData?.length > 0 ? (
                                      pageData?.map((row, index) => (
                                        <Index.TableRow key={row?._id}>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {index + 1}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.report &&
                                            row?.report?.length > 30 ? (
                                              <PageIndex.LightTooltip
                                                title={row?.report}
                                                arrowPlacement="top"
                                              >
                                                {row?.report.slice(0, 30)}...
                                              </PageIndex.LightTooltip>
                                            ) : (
                                              row?.report || "-"
                                            )}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            <span
                                              className="cus-user-name-navigate"
                                              onClick={() => {
                                                navigate(
                                                  "/dashboard/user-view",
                                                  {
                                                    state: {
                                                      data: row?.userId?._id,
                                                      reportId: reportId,
                                                      pathName:
                                                        location.pathname,
                                                    },
                                                  }
                                                );
                                              }}
                                            >
                                              {row?.userId?.userName}
                                            </span>
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {moment(row?.createdAt).format(
                                              "DD/MM/YYYY "
                                            )}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {moment(row?.createdAt).format(
                                              "hh:mm A"
                                            )}
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      ))
                                    ) : (
                                      <PageIndex.RecordNotFound colSpan={6} />
                                    )
                                  ) : (
                                    <PageIndex.TableLoader colSpan={6} />
                                  )}
                                </Index.TableBody>
                              </Index.Table>
                            </Index.TableContainer>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="pagination-costom">
                          <PageIndex.Pagination
                            fetchData={filterData}
                            setPageData={setPageData}
                            pageData={pageData}
                            search={search}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteUser}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
      <PageIndex.SuspendModal
        openSuspend={openSuspend}
        handleCloseSuspend={handleCloseSuspend}
        handleSuspendRecord={suspendUserByAdmin}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
      <PageIndex.ImageViewModel
        handleClose={handleImageClose}
        open={openImage}
        image={imagePath}
      />
      <PageIndex.SendMailModel
        openSendMailModel={openSendMailModel}
        handleCloseSendMail={handleCloseSendMail}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
        setIsDeleteButtonDisabled={setIsDeleteButtonDisabled}
        mailModelHeading={mailModelHeading}
        mailModelType={mailModelType}
        modelType={modelType}
        suspendUserByAdmin={suspendUserByAdmin}
        deletePost={deleteUser}
        handleSendMail={handleSendMail}
      />
    </>
  );
};

export default ViewProfileReport;
