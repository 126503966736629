import React from "react";
import Index from "../Index";
import PageIndex from "../../container/PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const WarningModel = ({
  openDelete,
  handleCloseDelete,
  handleDeleteRecord,
  isDeleteButtonDisabled,
}) => {
  return (
    <>
      <Index.Modal
        open={openDelete}
        // onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
          <Index.Box className="modal-circle-main cus-modal-margin">
            <Index.IconButton onClick={handleCloseDelete}>
              <img src={Index.Png.close} className="user-circle-img" />
            </Index.IconButton>
          </Index.Box>
          <Index.Typography
            className="delete-modal-title"
            component="h2"
            variant="h2"
          >
            Note
          </Index.Typography>
          <Index.Typography
            className="delete-Warning-modal-para common-para-warning-model"
            component="p"
            variant="p"
          >
            To delete this contest, please first deactivate it. Deactivation is
            required before deletion.
          </Index.Typography>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default WarningModel;
