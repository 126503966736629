import React from "react";
import Index from "../../../Index";

const DownloadButton = () => {
  return (
    <>
      <Index.Box className="download-custom-main">
        <Index.Box className="download-btn-heading-box">
          <Index.Typography className="download-btn-heading">
            Don’t Wait, Elevate Your Social Presence Today! Start Downloading
            app now
          </Index.Typography>
        </Index.Box>
        <Index.Box className="download-btn-container custom-download-btn-container">
          <Index.Box className="user-btn-flex hero-section-btn-flex">
            <Index.Box className="save-btn-main apple-btn-box">
              <Index.Button className="save-user-btn hero-section-custom-btn-back apple-btn">
                <>
                  <img
                    src={Index.Svg.appleIcon}
                    className="user-save-icon"
                    alt="Save Icon"
                  />
                  <Index.Box className="btn-text-box">
                    <Index.Typography className="download-text">
                      Download on the
                    </Index.Typography>
                    <Index.Typography className="apple-text">
                      App Store
                    </Index.Typography>
                  </Index.Box>
                </>
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main apple-btn-box">
              <Index.Button className="save-user-btn hero-section-custom-btn-back apple-btn">
                <>
                  <img
                    src={Index.Svg.playStore}
                    className="user-save-icon play-store-icon"
                    alt="Save Icon"
                  />
                  <Index.Box className="btn-text-box google-play-box">
                    <Index.Typography className="download-text google-text">
                      Get it on
                    </Index.Typography>
                    <Index.Typography className="apple-text play-text">
                      Google Play
                    </Index.Typography>
                  </Index.Box>
                </>
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default DownloadButton;
