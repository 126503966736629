import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import "./LandingPage.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer.js";

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("home");
  const handleScrollOrNavigate = (sectionId, route) => {
    if (route && location.pathname !== route) {
      navigate(route); // Agar different route hai, toh uspar navigate karein
      setActiveSection(sectionId);
      if (sectionId) {
        // Ensure scrolling happens after navigation
        setTimeout(() => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 100); // Small delay to ensure DOM is ready
      }
    } else if (sectionId) {
      // Same route ke andar scroll karein
      setActiveSection(sectionId);
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Index.Box className="main-landing-page">
        <Index.Box className="main-header-box">
          <Index.Box className="header-left-box">
            <Index.Box className="header-left-logo-box">
              <img src={Index.Png.logo} className="header-left-logo" />
            </Index.Box>
            <Index.Box className="header-left-title-box">
              <Index.Typography className="header-left-title">
                Crypconect
              </Index.Typography>
            </Index.Box>
          </Index.Box>
          <Index.Box className="header-right-box">
            <Index.Box className="header-right-list-box">
              <Index.List className="header-right-list">
                <Index.ListItem
                  className={`header-right-list-item ${
                    activeSection == "home" &&
                    location.pathname.includes("home")
                      ? "header-active-class"
                      : ""
                  }`}
                  onClick={() => {
                    handleScrollOrNavigate("home", "home");
                  }}
                >
                  <Index.Box className="list-item-icon-box">
                    <img
                      src={`${
                        activeSection == "home" &&
                        location.pathname.includes("home")
                          ? Index.Png.homePurpleIcon
                          : Index.Png.homeIcon
                      }`}
                      className="list-item-icon"
                    />
                  </Index.Box>
                  <Index.Box className="list-item-text-box">
                    <Index.Typography className="list-item-text">
                      Home
                    </Index.Typography>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem
                  className={`header-right-list-item ${
                    activeSection == "about" ? "header-active-class" : ""
                  }`}
                  onClick={() => {
                    handleScrollOrNavigate("about", "home");
                  }}
                >
                  <Index.Box className="list-item-icon-box">
                    <img
                      src={`${
                        activeSection == "about"
                          ? Index.Png.aboutPurpleIcon
                          : Index.Png.aboutIcon
                      }`}
                      className="list-item-icon about-icon"
                    />
                  </Index.Box>
                  <Index.Box className="list-item-text-box">
                    <Index.Typography className="list-item-text">
                      About
                    </Index.Typography>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem
                  className={`header-right-list-item ${
                    activeSection == "feature" ? "header-active-class" : ""
                  }`}
                  onClick={() => {
                    handleScrollOrNavigate("feature", "home");
                  }}
                >
                  <Index.Box className="list-item-icon-box">
                    <img
                      src={`${
                        activeSection == "feature"
                          ? Index.Png.featurePurpleIcon
                          : Index.Png.featureIcon
                      }`}
                      className="list-item-icon feature-icon"
                    />
                  </Index.Box>
                  <Index.Box className="list-item-text-box">
                    <Index.Typography className="list-item-text">
                      Features
                    </Index.Typography>
                  </Index.Box>
                </Index.ListItem>
                <Index.ListItem
                  className={`header-right-list-item ${
                    location.pathname.includes("contact-us")
                      ? "header-active-class"
                      : ""
                  }`}
                  onClick={() => {
                    handleScrollOrNavigate(null, "contact-us");
                  }}
                >
                  <Index.Box className="list-item-icon-box">
                    <img
                      src={`${
                        location.pathname.includes("contact-us")
                          ? Index.Png.infoPurpleIcon
                          : Index.Png.infoIcon
                      }`}
                      className="list-item-icon info-icon"
                    />
                  </Index.Box>
                  <Index.Box className="list-item-text-box">
                    <Index.Typography className="list-item-text">
                      Contact Us
                    </Index.Typography>
                  </Index.Box>
                </Index.ListItem>
              </Index.List>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Outlet />
        <Index.Box className="footer-section">
          <Footer handleScrollOrNavigate={handleScrollOrNavigate} />
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default LandingPage;
