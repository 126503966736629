import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  deleteSubAdmin,
  getAllSubAdmin,
} from "../../../../redux/slices/adminService";
import { debounce } from "lodash";
import moment from "moment";
import FileExcel from "../../../../component/common/Exportexcel";

const AdminMasterList = () => {
  const navigate = PageIndex.useNavigate();
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  //all state
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [subAdminList, setSubAdminList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [excel, setExcel] = useState([]);
  const [isDeleteButton, setIsDeleteButton] = useState(false);
  //Delete Modal
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const handleOpen = () => {
    navigate("/dashboard/admin-master-add");
  };

  const fetchAllSubAdminList = () => {
    setLoader(true);

    getAllSubAdmin({ currentPage, search, rowsPerPage }).then((data) => {
      if (data?.status == 200) {
        if (data?.status == 200) {
          setSubAdminList(data?.data);
          if (data?.currentPage) {
            setCurrentPage(data?.currentPage);
          }
          setPageCount(data?.totalCount ? data?.totalCount : 0);
          setLoader(false);
        }
        // setLoader(false);
        // setSubAdminList(response?.data);
        // setCurrentPage(response?.currentPage);
        // setPageCount(response?.totalCount ? response?.totalCount : 0);
      } else {
        setLoader(false);
        setSubAdminList([]);
      }
    });
  };

  const deletesubAdminMaster = () => {
    setIsDeleteButton(true);
    deleteSubAdmin({ id: deleteId }).then((data) => {
      setOpenDelete(false);
      if (data == 200) {
        setIsDeleteButton(false);
        fetchAllSubAdminList();
      } else {
        setTimeout(() => {
          setIsDeleteButton(false);
        }, 1000);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(fetchAllSubAdminList, 300);
    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchAllSubAdminList();
    }

    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);

  const fileName = "Admin_Master Report";
  useEffect(() => {
    if (subAdminList?.length > 0) {
      const customHeadings = subAdminList?.map((item, index) => ({
        "S.No": index + 1,
        Name: item?.name,
        Email: item?.email,
        "Phone Number": item?.phoneNumber,
        "Role Type": item?.roleType?.roleName,
        "Created Date & Time": moment(item?.createdAt).format(
          "DD/MM/YYYY hh:mm A"
        ),
      }));

      setExcel(customHeadings);
    }
  }, [subAdminList]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Admin Master
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      // name="search"
                      autoComplete="search"
                      value={search}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setCurrentPage(1);
                        setSearch(newValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img src={Index.Svg.search} className="search-icon" />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="flex-all user-list-inner-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {(rolePermission &&
                    rolePermission?.roleType?.rolePermission?.includes(
                      "AdminMaster_add"
                    )) ||
                  (rolePermission && rolePermission?.isAdmin === true) ? (
                    <>
                      <Index.Button
                        className="adduser-btn btn-primary"
                        onClick={handleOpen}
                      >
                        <img
                          src={Index.Svg.plus}
                          className="plus-icon"
                          alt="plus icon"
                        />
                        Add Admin Master
                      </Index.Button>
                    </>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
              <Index.Box
                Box
                // className="res-auto-left common-button blue-button multiple-download-btn res-blue-button"
              >
                <FileExcel apiData={excel} fileName={fileName} />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="admin-sub-master-table-main action-column page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          sx={{ minWidth: 650 }}
                          aria-label="simple table"
                          className="table"
                        >
                          <Index.TableHead className="table-head cus-table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                S.No
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Email
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Phone Number
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Role Type
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Created Date & Time
                              </Index.TableCell>

                              {(rolePermission &&
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "AdminMaster_view"
                                )) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "AdminMaster_edit"
                              ) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "AdminMaster_delete"
                              ) ||
                              (rolePermission &&
                                rolePermission?.isAdmin === true) ? (
                                <>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="center"
                                  >
                                    Actions
                                  </Index.TableCell>
                                </>
                              ) : (
                                ""
                              )}
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {!loader ? (
                              subAdminList?.length > 0 ? (
                                subAdminList?.map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.name}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.email}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.phoneNumber}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.roleType?.roleName}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {moment(row?.createdAt).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </Index.TableCell>
                                    {(rolePermission &&
                                      rolePermission?.roleType?.rolePermission?.includes(
                                        "AdminMaster_view"
                                      )) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "AdminMaster_edit"
                                    ) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "AdminMaster_delete"
                                    ) ||
                                    (rolePermission &&
                                      rolePermission?.isAdmin === true) ? (
                                      <>
                                        <Index.TableCell
                                          component="td"
                                          variant="td"
                                          className="table-td"
                                          // align="center"
                                        >
                                          <Index.Box className="userdata-btn-flex admin-master-btn">
                                            {(rolePermission &&
                                              rolePermission?.roleType?.rolePermission?.includes(
                                                "AdminMaster_view"
                                              )) ||
                                            (rolePermission &&
                                              rolePermission?.isAdmin ===
                                                true) ? (
                                              <>
                                                <PageIndex.LightTooltip title="View">
                                                  <Index.IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() => {
                                                      navigate(
                                                        "/dashboard/admin-master-view",
                                                        {
                                                          state: row,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <Index.RemoveRedEyeIcon />
                                                  </Index.IconButton>
                                                </PageIndex.LightTooltip>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {(rolePermission &&
                                              rolePermission?.roleType?.rolePermission?.includes(
                                                "AdminMaster_edit"
                                              )) ||
                                            (rolePermission &&
                                              rolePermission?.isAdmin ===
                                                true) ? (
                                              <>
                                                <PageIndex.LightTooltip title="Edit">
                                                  <Index.IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() => {
                                                      navigate(
                                                        "/dashboard/admin-master-add",
                                                        {
                                                          state: row,
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <Index.EditIcon />
                                                  </Index.IconButton>
                                                </PageIndex.LightTooltip>
                                              </>
                                            ) : (
                                              ""
                                            )}{" "}
                                            {(rolePermission &&
                                              rolePermission?.roleType?.rolePermission?.includes(
                                                "AdminMaster_delete"
                                              )) ||
                                            (rolePermission &&
                                              rolePermission?.isAdmin ===
                                                true) ? (
                                              <>
                                                <PageIndex.LightTooltip title="Delete">
                                                  <Index.IconButton
                                                    color="primary"
                                                    aria-label="upload picture"
                                                    component="label"
                                                    onClick={() => {
                                                      handleOpenDelete(row._id);
                                                    }}
                                                  >
                                                    <Index.DeleteIcon />
                                                  </Index.IconButton>
                                                </PageIndex.LightTooltip>
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </Index.Box>
                                        </Index.TableCell>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PageIndex.RecordNotFound colSpan={7} />
                              )
                            ) : (
                              <PageIndex.TableLoader colSpan={7} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Pagination */}
          {subAdminList?.length ? (
            <Index.Box className="pagination-main">
              <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>
      {/* Delete Modal */}
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deletesubAdminMaster}
        isDeleteButtonDisabled={isDeleteButton}
      />
    </>
  );
};

export default AdminMasterList;
