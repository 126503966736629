import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  deleteUserMaster,
  getAllUserMasterList,
} from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";
import moment from "moment/moment";
import { debounce } from "lodash";
import numeral from "numeral";
import FileExcel from "../../../../component/common/Exportexcel";

export default function UserList({ btnValue, setBtnValue }) {
  const typeList = [
    { id: 1, value: "today", key: "Today" },
    { id: 2, value: "overview", key: "Overview" },
  ];
  const statusList = [
    { id: 1, value: "all", key: "All" },
    { id: 2, value: "suspended", key: "Suspended" },
    { id: 3, value: "non-suspended", key: "Active" },
  ];

  let navigate = PageIndex.useNavigate();
  const dispatch = PageIndex.useDispatch();
  const params = PageIndex.useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));

  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [loader, setLoader] = useState(true);
  const [deleteId, setDeleteId] = useState();
  const [userList, setUserList] = useState([]);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [type, setType] = useState(state?.type ? state?.type : "overview");
  const [status, setStatus] = useState(state?.status ? state?.status : "all");
  const abortController = new AbortController();
  const [excel, setExcel] = useState([]);

  // add user modal
  const handleOpen = () => {
    navigate("/dashboard/add-user");
  };

  //Delete Modal
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const deleteUser = (id) => {
    setIsDeleteButtonDisabled(true);
    deleteUserMaster({ id: deleteId }).then((data) => {
      handleCloseDelete();
      setIsDeleteButtonDisabled(false);
      setLoader(true);
      fetchUserList();
    });
  };

  //Fetch User
  const fetchUserList = (value) => {
    const data = {
      page: value ? value : currentPage,
      search: search,
      today: type,
      status: status,
      limit: rowsPerPage,
    };
    setLoader(true);
    getAllUserMasterList(data, { signal: abortController.signal }).then(
      (data) => {
        if (data?.status == 200) {
          setUserList(data?.data);
          setPageCount(data?.totalCount ? data?.totalCount : 0);
          setLoader(false);
        } else {
          // setUserList([]);
          // setLoader(false);
        }
      }
    );
  };

  useEffect(() => {
    setLoader(true);
    const debouncedFetch = debounce(fetchUserList, 300);
    if (search != "" || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchUserList();
    }
    return () => {
      debouncedFetch.cancel();
      abortController.abort();
    };
  }, [search, currentPage, type, status, rowsPerPage]);

  const handleType = (e) => {
    setType(e.target.value);
    navigate({ state: "" });
    setCurrentPage(1);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
    navigate({ state: "" });
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const fileName = "Verified Users List";
  useEffect(() => {
    if (userList?.length > 0) {
      const customHeadings = userList?.map((item, index) => ({
        "S.No": index + 1,
        Username: item?.userName,
        Email: item?.email,
        "Phone Number": item?.phoneNumber,
        "Created Date": moment(item.createdAt).format("DD/MM/YYYY"),
        "Suspend Status": item?.isSuspended === true ? "Suspended" : "Active",
        "No. Of Post": item?.postCount,
      }));

      setExcel(customHeadings);
    }
  }, [userList]);

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Verified Users
              </Index.Typography>
            </Index.Box>

            <Index.Box className="userlist-btn-flex">
              <Index.Box className="input-box add-user-input request-input-box">
                <Index.Box className="form-group">
                  <Index.FormControl className="request-dropdown">
                    <Index.Select
                      className="form-control select-drop-list request-drop-list"
                      name="type"
                      value={status}
                      fullWidth
                      onChange={handleStatus}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                    >
                      {statusList &&
                        statusList?.map((val) => (
                          <Index.MenuItem
                            value={val?.value}
                            key={val?.id}
                            className="menuitem"
                          >
                            {val?.key}
                          </Index.MenuItem>
                        ))}
                    </Index.Select>
                  </Index.FormControl>
                </Index.Box>
              </Index.Box>
              <Index.Box className="input-box add-user-input request-input-box">
                <Index.Box className="form-group">
                  <Index.FormControl className="request-dropdown">
                    <Index.Select
                      className="form-control select-drop-list request-drop-list"
                      name="type"
                      value={type}
                      fullWidth
                      onChange={handleType}
                      displayEmpty
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                    >
                      {typeList &&
                        typeList?.map((val) => (
                          <Index.MenuItem
                            value={val?.value}
                            key={val?.id}
                            className="menuitem"
                          >
                            {val?.key}
                          </Index.MenuItem>
                        ))}
                    </Index.Select>
                  </Index.FormControl>
                </Index.Box>
              </Index.Box>
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth_123"
                      type="text"
                      // value={search}
                      autoComplete="search"
                      className="form-control"
                      placeholder="Search"
                      name="search"
                      value={search}
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setCurrentPage(1);
                        setSearch(newValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img src={Index.Svg.search} className="search-icon" />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="flex-all user-list-inner-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {(rolePermission &&
                    rolePermission?.roleType?.rolePermission?.includes(
                      "UserMaster_add"
                    )) ||
                  (rolePermission && rolePermission?.isAdmin === true) ? (
                    <>
                      <Index.Button
                        className="adduser-btn btn-primary"
                        onClick={handleOpen}
                      >
                        <img
                          src={Index.Svg.plus}
                          className="plus-icon"
                          alt="plus icon"
                        />
                        Add User
                      </Index.Button>
                    </>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
              <Index.Box>
                <FileExcel apiData={excel} fileName={fileName} />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="userlist-table-main action-column page-table-main">
                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                      >
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          sx={{ minWidth: 650 }}
                          className="table"
                        >
                          <Index.TableHead className="table-head cus-table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                S.No
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Username
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Email
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Phone Number
                              </Index.TableCell>

                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Created Date
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Suspend Status
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                No. Of Post
                              </Index.TableCell>

                              {(rolePermission &&
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "UserMaster_view"
                                )) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "UserMaster_edit"
                              ) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "UserMaster_delete"
                              ) ||
                              (rolePermission &&
                                rolePermission?.isAdmin === true) ? (
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Actions
                                </Index.TableCell>
                              ) : (
                                ""
                              )}
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {!loader ? (
                              userList?.length > 0 ? (
                                userList?.map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.userName ? row?.userName : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.email ? row?.email : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.phoneNumber
                                        ? row?.phoneNumber
                                        : "-"}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {moment(row.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row.isSuspended === true
                                        ? "Suspended"
                                        : "Active"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      className="table-td"
                                      align="center"
                                    >
                                      {/* {row.postCount} */}
                                      {row?.postCount
                                        ? Number.isInteger(row.postCount)
                                          ? numeral(row.postCount).format("0")
                                          : numeral(row.postCount).format(
                                              "0.000a"
                                            )
                                        : 0}
                                    </Index.TableCell>
                                    {(rolePermission &&
                                      rolePermission?.roleType?.rolePermission?.includes(
                                        "UserMaster_view"
                                      )) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "UserMaster_edit"
                                    ) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "UserMaster_delete"
                                    ) ||
                                    (rolePermission &&
                                      rolePermission?.isAdmin === true) ? (
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "UserMaster_view"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="View">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    navigate(
                                                      "/dashboard/user-view",
                                                      {
                                                        state: {
                                                          data: row._id,
                                                          pathName:
                                                            params.pathname,
                                                          btnValue: btnValue,
                                                        },
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <Index.RemoveRedEyeIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "UserMaster_edit"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="Edit">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    navigate(
                                                      "/dashboard/edit-user",
                                                      {
                                                        state: {
                                                          data: row,
                                                          btnValue: btnValue,
                                                        },
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <Index.EditIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "UserMaster_delete"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="Delete">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    handleOpenDelete(row._id);
                                                  }}
                                                >
                                                  {" "}
                                                  <Index.DeleteIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Index.Box>
                                      </Index.TableCell>
                                    ) : (
                                      ""
                                    )}
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PageIndex.RecordNotFound colSpan={8} />
                              )
                            ) : (
                              <PageIndex.TableLoader colSpan={8} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                      {/* </Paper> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {userList?.length ? (
            <Index.Box className="pagination-main">
              <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100,
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      {/* Delete Modal */}
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteUser}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
    </>
  );
}
