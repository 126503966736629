import * as yup from "yup";
// const passwordRegex = {
//   lowercase: /^(?=.*[a-z])/,
//   uppercase: /^(?=.*[A-Z])/,
//   number: /^(?=.*\d)/,
//   specialChar: /^(?=.*[!@#$%^&*])/,
//   length: /^(?=.{8,})/,
// };
const startSpace = /^(?!\s).*$/;
// const space=/^\w+(?:\s\w+)*$/;
const space = /^(?!.* {2}).*$/;
// const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const phoneRegExp = /^(?!.*(\d)\1{6,})[6-9][0-9]{9}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

const zeroStart = /^[1-9]\d*$/;
// const coinNameRegex =/^(?=(?:.*\D){1,3}$)[\w ]+$/
const coinNameRegex = /^(?=.*[a-zA-Z])(?!.*\d{4})[0-9a-zA-Z]*$/;
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 20 MB in bytes

const emailRegex =
  /^(?!.*\.\.)(?!.*--)(?!\.)[a-zA-Z0-9._-]+(?<![-.])@[a-zA-Z0-9-]+(?<!-)\.[a-zA-Z]{2,}$/;

export const adminLoginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter email")
    .email("Please enter valid email")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  password: yup
    .string()
    .required("Please enter password")
    // .min(8, "Password must be at least 8 characters")
    .max(20, "Password cannot exceed 20 characters"),
});

//forgot password
export const adminForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter email")
    .email("Please enter valid email")
    .matches(
      // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      emailRegex,
      "Please enter valid email"
    )
    .max(50, "Email at most 50 characters"),
});

export const adminSendOtpSchema = yup.object().shape({
  otp: yup
    .string()
    .required("Please enter your OTP")
    .matches(/^\d{4}$/, "OTP must be a 4-digit number"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter new password")
    .min(8, "New password must be at least 8 characters")
    .matches(
      passwordRegex,
      "New Password must contain at least one uppercase & lowercase letter, one numeric & one special character"
    )
    .max(20, "New password cannot exceed 20 characters"),
  confirm_password: yup
    .string()
    .required("Please enter confirm password")
    .oneOf(
      [yup.ref("password"), null],
      "Confirm password does not match with password"
    ),
});

export const EditProfileSchema = yup.object().shape({
  name: yup
    .string()
    .required("Please enter name")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name must have only capital and small letters"
    )
    .min(3, "Name must be at least 3 characters")
    .max(20, "Name cannot exceed 20 characters"),
  email: yup
    .string()
    .required("Please enter email")
    .email("Please enter valid email")
    .matches(
      // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      emailRegex,
      "Please enter valid email"
    )
    .max(50, "Email at most 50 characters"),
  phoneNumber: yup
    .string()
    .required("Please enter phone number")
    .matches(phoneRegExp, "Please enter valid phone number"),
  profile: yup
    .mixed()
    .nullable()
    .test("FileType", "File type should be jpeg/jpg/png", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value?.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file);
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
      }
    })
    .test("FileSize", "File size should be less than 2 MB", (value) => {
      if (!value) return true;
      if (value?.size) {
        return value.size <= MAX_FILE_SIZE;
      }
      return true;
    }),
});

export const ChangePasswordSchemaProfile = yup.object().shape({
  confirmPassword: yup
    .string()
    .required("Please enter  confirm password")
    .min(8, "Must contain 8 characters")
    .oneOf(
      [yup.ref("newPassword"), null],
      "Confirm password does not match with password"
    ),
  newPassword: yup
    .string()
    .required("Please enter new password")
    .min(8, "Password must be at least 8 characters")
    .matches(
      passwordRegex,
      "New Password must contain at least one uppercase & lowercase letter, one numeric & one special character"
    )

    .max(20, "Password cannot exceed 20 characters"),
  oldPassword: yup.string().required("Please enter old password"),
});

export const addUserSchema = yup.object().shape({
  email: yup
    .string()
    .required("Please enter email")
    .email("Please enter valid email")
    .matches(
      // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      emailRegex,
      "Please enter valid email"
    )
    .required("Please enter email")
    .max(50, "Email at most 50 characters"),
  password: yup
    .string()
    .required("Please enter password")
    .min(8, "Password must be at least 8 characters")
    .matches(
      passwordRegex,
      "Password must contain at least one uppercase & lowercase letter, one numeric & one special character"
    )
    .max(20, "Password cannot exceed 20 characters"),
  name: yup
    .string()
    .required("Please enter name")
    .transform((value) => value.replace(/\s/g, ""))
    .min(3, "Name must be at least 3 characters")
    .max(20, "Name cannot exceed 20 characters")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name must have only capital and small letters"
    ),
  userName: yup
    .string()
    .required("Please enter username")
    .matches(
      /^[a-zA-Z0-9._]{2,50}$/,
      "Username can only contain letters, numbers, dots, and underscores."
    )
    .min(3, "Username must be at least 3 characters")
    .max(20, "Username cannot exceed 20 characters"),
  phoneNumber: yup
    .string()
    .required("Please enter phone number")
    .matches(phoneRegExp, "Please enter valid phone number"),
});

//terms and condition

// Role
export const roleSchema = yup.object().shape({
  roleName: yup.string().required("Please enter role name"),
});

export const GeneralSettingSchema = yup.object().shape({
  minimum_Payment: yup.number().required("Minimum amount is required"),
  maximum_Payment: yup.number().required("Maximum amount is required"),
});

export const roleMasterSchema = yup.object().shape({
  roleName: yup
    .string()
    .required("Please enter role name")
    .matches(/^(?!\s)[\s\S]*$/, "No spaces allowed at the start")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Please enter valid role name. Only characters are allowed"
    )
    .min(3, "Role name must be at least 3 characters")
    .max(20, "Role name cannot exceed 20 characters"),
  rolePermission: yup
    .array()
    .of(yup.string().required())
    .required("Please select permissions")
    .min(1, "Please select permissions"),
});

//admin master schema
export const addSubAdminSchema = ({ isEdit }) =>
  yup.object().shape({
    email: yup
      .string()
      .email("Please enter valid email")
      .matches(
        // /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        emailRegex,
        "Please enter valid email"
      )
      // .oneOf([yup.ref("email"), ""], "Email must match")
      .max(50, "Email at most 50 characters")
      .required("Please enter email"),
    password: isEdit
      ? yup.string()
      : yup
          .string()
          .required("Please enter password")
          .matches(
            passwordRegex,
            "Password must contain at least one uppercase & lowercase letter, one numeric & one special character"
          )
          .min(8, "Password must be at least 8 characters")
          .max(20, "Password cannot exceed 20 characters"),
    confirmPassword: isEdit
      ? yup.string()
      : yup
          .string()
          .required(" Please enter confirm password")
          .oneOf(
            [yup.ref("password")],
            "Confirm password does not match with password"
          ),

    name: yup
      .string()
      .required("Please enter name")
      .transform((value) => value.replace(/\s/g, ""))
      .matches(
        /^(?!\s)[A-Za-z\s]+$/,
        "Please enter valid name. Only characters are allowed"
      )
      .min(3, "Name must be at least 3 characters")
      .max(20, "Name cannot exceed 20 characters"),
    address: yup
      .string()
      .required("Please enter address")
      .transform((value) => value.replace(/\s/g, ""))

      .min(3, "Address must be at least 3 characters")
      .max(50, "Address cannot exceed 50 characters"),
    phoneNumber: yup
      .string()
      .required("Please enter phone number")
      .matches(phoneRegExp, "Please enter valid phone number"),
    roleType: yup.string().required("Please select role type"),
  });

//edit user master

export const editUserMasterSchema = yup.object().shape({
  name: yup
    .string()
    .required("Please enter name")
    .transform((value) => value.replace(/\s/g, ""))
    .min(3, "Name must be at least 3 characters")
    .max(20, "Name cannot exceed 20 characters")
    .matches(
      /^(?!\s)[A-Za-z\s]+$/,
      "Name must have only capital and small letters"
    ),
  userName: yup
    .string()
    .required("Please enter username")
    .matches(
      /^[a-zA-Z0-9._]{2,50}$/,
      "Username can only contain letters, numbers, dots, and underscores."
    )
    .min(3, "Username must be at least 3 characters")
    .max(20, "Username cannot exceed 20 characters"),

  // profile: yup
  //   .mixed()
  //   .required("Please uploade image")
  //   .nullable()
  //   .test("FileType", "Please upload jpeg/jpg/png file", (value) => {
  //     if (!value) return true;
  //     if (!value?.name) {
  //       const file = value?.split(".")[1];
  //       return ["jpeg", "jpg", "png"].includes(file);
  //     } else {
  //       return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
  //     }
  //   })
  //   .test("size", "Please upload image file below 5MB", (value) => {
  //     if (value?.size) {
  //       return value?.size <= 5 * 1024 * 1024;
  //     }
  //     return true;
  //   }),

  // links: yup
  // .string()
  // .matches(
  //   /^(https?:\/\/)?(www\.)?([^\s]+(\.[^\s]+)+)(\/[^\s]*)?$/i,
  //   "Invalid URL format."
  // )
  // .nullable(),
  Bio: yup.string().max(150, "Bio cannot exceed 150 characters").nullable(),
});

/////////////////////////  Social Media ///////////////////////////

export const termsConditionSchema = yup.object().shape({
  title: yup
    .string()
    // .required("Please enter title")
    .transform((value) => value.replace(/\s/g, ""))
    .min(2, "Title must be atleast 2 characters")
    .max(50, "Title should not exceed 50 characters"),
  description: yup
    .string()
    .transform((value) => value.replace(/\s/g, ""))
    .min(5, "Description must be atleast 5 characters")
    .max(2000, "Description should not exceed 2000 words")
    .required("Please enter description"),
});
export const privacyPolicySchema = yup.object().shape({
  title: yup
    .string()
    // .required("Please enter title")
    .transform((value) => value.replace(/\s/g, ""))
    .min(2, "Title must be atleast 2 characters")
    .max(50, "Title should not exceed 50 characters"),
  description: yup
    .string()
    .required("Please enter description")
    .transform((value) => value.replace(/\s/g, ""))
    .max(2000, "Description should not exceed 2000 words")
    .min(5, "Description must be atleast 5 characters"),
});
export const cmsContactUsSchema = yup.object().shape({
  website: yup
    .string()
    .required("Please enter website URL")
    .url("Enter a valid website URL")
    // .matches(
    //   /^(ftp|http|https):\/\/[^ "]+$/,
    //   "Invalid website URL. Please enter a valid URL"
    // )
    .matches(
      /^(?:ftp|https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.(?:com|org|in)(?:\/[^\s]*)?$/,
      // /^(ftp|http(s)?)\:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
      "Please enter a valid URL"
    ),

  email: yup
    .string()
    .email("Please enter valid email")
    .matches(
      // /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      emailRegex,
      "Please enter valid email"
    )
    .max(50, "Email at most 50 characters")
    .required("Please enter email"),
  mobile: yup
    .string()
    .required("Please enter mobile number")
    .matches(phoneRegExp, "Please enter valid mobile number"),
});
export const cmsAdminSupport = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email")
    .matches(
      // /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      emailRegex,
      "Please enter valid email"
    )
    .max(50, "Email at most 50 characters")
    .required("Please enter email"),
  mobileNo: yup
    .string()
    .required("Please enter mobile number")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  description: yup
    .string()
    .required("Please enter description")
    .transform((value) => value.replace(/\s/g, ""))
    .min(5, "Description must be atleast 5 characters")
    .max(2000, "Description should not exceed 2000 characters"),
});
export const cmsAboutUsSchema = yup.object().shape({
  description: yup
    .string()
    .required("Please enter description")
    .transform((value) => value.replace(/\s/g, ""))
    .min(5, "Description must be atleast 5 characters")
    .max(2000, "Description should not exceed 2000 words"),
  title: yup
    .string()
    // .required("Please enter title")
    .transform((value) => value.replace(/\s/g, ""))
    .min(2, "Title must be atleast 2 characters")
    .max(50, "Title should not exceed 50 characters"),
});

export const addNotificationSchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter title")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")
    .min(2, "Title must be atleast 2 characters")
    .max(50, "Title should not exceed 50 characters"),
  notificationType: yup.string().required("Please select notification type"),
  description: yup
    .string()
    .required("Please enter message")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")
    .matches(space, "More than one space not allowed")

    .min(5, "Message must be atleast 5 characters")
    .max(250, "Message should not exceed 250 characters"),
});

export const diamondPriceSchema = yup.object().shape({
  monthlyPrice: yup.string().required("Please enter monthly price"),
  quarterlyPrice: yup.string().required("Please enter quarterly price"),
  yearlyPrice: yup.string().required("Please enter yearly price"),
  monthlyDescription: yup
    .string()
    .required("Please enter monthly plan description")
    .min(2, "Description must be at least 2 characters")
    .max(100, "Description should not exceed 100 characters"),

  quarterlyDescription: yup
    .string()
    .required("Please enter quarterly plan description")
    .min(2, "Description must be at least 2 characters")
    .max(100, "Description should not exceed 100 characters"),

  yearlyDescription: yup
    .string()
    .required("Please enter yearly plan description")
    .min(2, "Description must be at least 2 characters")
    .max(100, "Description should not exceed 100 characters"),
  currency: yup.object().required("Please select currency type"),

  monthlyPriceInUsdt: yup.string().required("Please enter monthly price"),
  quarterlyPriceInUsdt: yup.string().required("Please enter quarterly price"),
  yearlyPriceInUsdt: yup.string().required("Please enter yearly price"),
  monthlyDescriptionInUsdt: yup
    .string()
    .required("Please enter monthly plan description")
    .min(2, "Description must be at least 2 characters")
    .max(100, "Description should not exceed 100 characters"),

  quarterlyDescriptionInUsdt: yup
    .string()
    .required("Please enter quarterly plan description")
    .min(2, "Description must be at least 2 characters")
    .max(100, "Description should not exceed 100 characters"),

  yearlyDescriptionInUsdt: yup
    .string()
    .required("Please enter yearly plan description")
    .min(2, "Description must be at least 2 characters")
    .max(100, "Description should not exceed 100 characters"),
  currencyInUsdt: yup.object().required("Please select currency type"),
});

export const addEditContestSchema = yup.object().shape({
  contestName: yup
    .string()
    .matches(startSpace, "Start space is not allowed")
    .transform((value) => value.replace(/\s/g, ""))
    .required("Please enter contest name")
    .min(2, "Contest name must be at least 2 characters")
    .max(50, "Contest name should not exceed 50 characters"),

  description: yup
    .string()
    .matches(startSpace, "Start space is not allowed")
    .required("Please enter description")
    .min(5, "Description must be at least 5 characters")
    .max(200, "Description should not exceed 200 characters"),

  spotNumber: yup
    .string()
    .required("Please enter spot number")
    .matches(zeroStart, "Please enter valid spot number")
    .test(
      "less-than-or-equal",
      "Spot number should be greater than limit(min limit)",
      function (value) {
        return parseInt(value) > parseInt(this.options.context.limit);
      }
    ),

  adminComission: yup
    .string()
    .required("Please enter admin commission")
    .matches(zeroStart, "Please enter valid admin commission"),

  winningPrices: yup.array().of(
    yup.object().shape({
      Rank: yup
        .string()
        .required("Please enter rank")
        .test("unique", "Rank has already been declared", function (value) {
          const existingRanks = this.options.context.winningPrices || [];
          const currentIndex = parseInt(this.path.match(/\d+/)[0]);
          const filteredRanks = existingRanks.filter(
            (_, index) => index !== currentIndex
          );
          const isDuplicate = filteredRanks.some((item) => item.Rank === value);

          return !isDuplicate;
        })

        .test("unique", "Rank no. has already been declared", function (value) {
          const existingRanks = this.options.context.winningPrices || [];
          const currentIndex = parseInt(this.path.match(/\d+/)[0]);
          const filteredRanks = existingRanks.filter(
            (_, index) => index !== currentIndex
          );
          const isDuplicate = filteredRanks.some((item) => {
            let temp = "0 to 0";
            const [startRank, endRank] =
              item?.Rank?.split(" to ").map(Number) ||
              temp.split(" to ").map(Number);
            const [startValue, endValue] =
              value?.split(" to ").map(Number) ||
              temp.split(" to ").map(Number);
            if (currentIndex != 0) {
              return endRank == startValue;
            }
          });

          return !isDuplicate;
        })

        .test(
          "less-than-or-equal",
          "Rank must be less than or equal to spot number",
          function (value) {
            const spotNumber = parseInt(this.options.context.spotNumber || 0);

            const rankRange = this.options.context?.winningPrices?.reduce(
              (acc, cur) => {
                let temp = "0 to 0";
                let total = 0;
                const [start, end] =
                  cur?.Rank?.split(" to ").map(Number) ||
                  temp?.split(" to ").map(Number);
                if (parseInt(start) == 0) {
                  total += acc + (parseInt(end) - parseInt(start));
                  return total;
                } else {
                  total += acc + (parseInt(end) - parseInt(start) + 1);

                  return total;
                }
                // total += acc + (parseInt(end) - parseInt(start) + 1);
                // return total
              },
              0
            );

            return parseInt(rankRange) <= spotNumber;
          }
        ),
      prize: yup
        .string()
        .required("Please enter prize")
        // .matches(/^\d+$/, 'Please enter a valid prize')
        .test(
          "less-than-or-equal",
          "Prize must be less than or equal to remaining prize",
          function (value) {
            const remainingPrize = parseInt(
              this.options.context.remainingPrize || 100
            );
            return parseInt(value) <= remainingPrize;
          }
        ),
    })
  ),
  limit: yup
    .string()
    .required("Please enter limit")
    .matches(zeroStart, "Please enter valid limit")
    .test(
      "less-than-or-equal",
      "Limit should not exceed spot numbers",
      function (value) {
        return parseInt(value) < parseInt(this.options.context.spotNumber);
      }
    ),

  contestFees: yup
    .string()
    .required("Please enter contest fees")
    .matches(zeroStart, "Please enter valid contest fees"),
  currency: yup.string().required("Please select currency type"),
  currencyType: yup.string().required("Please select currency"),

  startDate: yup.date().required("Please select start date"),

  startTime: yup.date().required("Please select start time"),
  // endDate: yup.date().required("Please select end date"),

  // endTime: yup.date().required("Please select end time"),
  entryTime: yup.string().required("Please enter entry time"),

  resultTime: yup.string().required("Please enter result time"),

  category: yup.string().required("Please select category"),
  contestType: yup.string().required("Please select contest type"),
  ruleId: yup.string().required("Please select rule book"),
  subCategory: yup.string().required("Please select sub-category"),
  banner: yup
    .mixed()
    // .required("Please select banner image")
    .test(
      "is-valid-resolution",
      "Image must have a resolution of at least 1920x1080 pixels",
      async (value) => {
        if (!value) {
          return true; // Let the required validation handle empty values
        }

        if (value instanceof File) {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
              const width = this.width;
              const height = this.height;
              if (width >= 1920 && height >= 1080) {
                resolve(true); // Resolution is valid
              } else {
                resolve(false); // Resolution is invalid
              }
            };
            img.onerror = function () {
              resolve(false); // Error loading image
            };
            img.src = URL.createObjectURL(value);
          });
        } else {
          // Assume value is a file name and cannot be validated
          return true;
        }
      }
    )
    .test("FileType", "Please upload jpeg/jpg/png file", (value) => {
      if (!value) return true;

      if (!value?.name) {
        const file = value?.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file);
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
      }
    })
    .test("size", "Please upload image file below 5MB", (value) => {
      if (value?.size) {
        return value?.size <= 5 * 1024 * 1024;
      }
      return true;
    }),
});
// .test('rank-spotNumber', 'Rank must match Spot Number', function (values) {
//   const { winningPrices, spotNumber } = values;
//   if (!winningPrices) return true; // No need to validate if winningPrices is not provided
//   const hasError = winningPrices.some((price) => {
//     const rank = parseInt(price.Rank);
//     return isNaN(rank) || rank > spotNumber;
//   });
//   if (hasError) {
//     return this.createError({
//       path: 'spotNumber',
//       message: 'Rank must be less than or equal to Spot Number',
//     });
//   }
//   return true;
// });

export const addPlatformFeeSchema = yup.object().shape({
  withdrawalFee: yup
    .number()
    .required("Please enter withdrawal fee")
    .typeError("Please enter valid withdrawal fee")
    .test(
      "is-less-than-20",
      "Withdrawal fee be less than or equal to 20",
      (value) => {
        if (!value) return true; // Allow empty values
        return parseFloat(value) <= 20;
      }
    )
    .nullable(),

  sendFiatFee: yup
    .number()
    .required("Please enter send fiat fee")
    .typeError("Please enter a valid send fiat fee")
    .test(
      "is-less-than-20",
      "Send fiat fee must be less than or equal to 20",
      (value) => {
        if (!value) return true;
        return parseFloat(value) <= 20;
      }
    )
    // .test(
    //   "is-not-zero",
    //   "Withdrawal fee cannot be 0.00",
    //   (value) => {
    //     if (!value) return true; // Allow empty values
    //     return parseFloat(value) !== 0;
    //   }
    // )
    .test("is-valid", "Invalid fee amount", (value) => {
      if (!value) return true;
      return parseFloat(value) >= 0;
    })
    .nullable(),
  sendCryptoFee: yup
    .number()
    .required("Please enter send crypto fee")
    .typeError("Please enter a valid send crypto fee")
    .test(
      "is-less-than-20",
      "Send crypto fee must be less than or equal to 20",
      (value) => {
        if (!value) return true;
        return parseFloat(value) <= 20;
      }
    )
    .nullable(),
  convertToCrypto: yup
    .number()
    .required("Please enter convert to crypto fee")
    .typeError("Please enter a valid convert to crypto fee")
    .test(
      "is-less-than-20",
      "Convert to crypto fee must be less than or equal to 20",
      (value) => {
        if (!value) return true;
        return parseFloat(value) <= 20;
      }
    )
    .nullable(),
  convertToFiat: yup
    .number()
    .required("Please enter convert to fiat fee")
    .typeError("Please enter a valid convert to fiat fee")
    .test(
      "is-less-than-20",
      "Convert to fiat fee must be less than or equal to 20",
      (value) => {
        if (!value) return true;
        return parseFloat(value) <= 20;
      }
    )
    .nullable(),
  // thirdPartyURL: yup.string()
  // .required("Please enter thirdPartyURL")
  // .matches(startSpace, "start space is not allowed")
  // .matches(zeroStart, 'Please enter valid Platform Fee')
  // .test('is-less-than-20', 'thirdPartyURL must be less than or equal to 20', (value) => {
  //   if (!value) return true; // Allow empty values
  //   return parseFloat(value) <= 20;
  // }),
});

export const addCryptoSchema = yup.object().shape({
  tokenName: yup
    .string()
    .required("Please enter token name")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")

    .min(2, "Token name must be atleast 2 characters")
    .max(30, "Token name should not exceed 30 characters"),
  tokenId: yup
    .string()
    .required("Please enter token id")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")

    .min(2, "Token id must be atleast 2 characters")
    .max(30, "Token id should not exceed 50 characters"),
  tokenPrice: yup
    .string()
    .required("Please enter token price")
    // .test("not-all-zeros", "Please enter valid token price", (value) => {
    //   return !/^0+(\.[0-9]+)?$/.test(value);
    // })
    .matches(
      /^(?!0$)(?!.*(?:\.\d*[1-9])?\.?0+$)\d+(\.\d*[1-9])?$/,
      "Please enter valid token price"
    )
    .test("not-all-zeros", "Please enter valid token price", (value) => {
      return !/^0*\.?0*$/.test(value);
    }),

  tokenSymbol: yup.string().required("Please enter token symbol"),
  tokenAddress: yup
    .string()
    .required("Please enter token address")
    .min(2, "Token address must be atleast 2 characters"),
  chainType: yup
    .string()
    .required("Please enter chain type")
    .min(2, "Chain type must be atleast 2 characters"),
  tokenLogo: yup
    .mixed()
    .required("Please upload token logo")
    .test("FileType", "Please upload jpeg/jpg/png file", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value?.split(".")[1];
        return ["jpeg", "jpg", "png"].includes(file);
      } else {
        return ["image/jpeg", "image/jpg", "image/png"].includes(value?.type);
      }
    })
    .test("size", "Please upload an token logo below 2MB", (value) => {
      if (value?.size) {
        return value?.size <= 2 * 1024 * 1024;
      }
      return true;
    }),
  contractAddress: yup
    .string()
    .required("Please enter contract address")
    .min(20, "Contract address must be atleast 20 characters"),
});

export const addRejectionReason = yup.object().shape({
  reason: yup
    .string()
    .required("Please enter reason")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")
    .min(2, "Reason must be atleast 2 characters")
    .max(100, "Reason should not exceed 100 characters"),
  // actualTransactionId:yup.string().required("Please enter transacrion Id")
});
export const transactionIdSchema = yup.object().shape({
  actualTransactionId: yup.string().required("Please enter transacrion Id"),
});
export const streakNotificationSchema = yup.object().shape({
  message: yup
    .string()
    .required("Please enter message")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")
    .min(2, "Message must be atleast 2 characters")
    .max(100, "Message should not exceed 100 characters"),
  hours: yup.string().required("Please select hours"),
});
export const diamondTick = yup.object().shape({
  validity: yup.string().required("Please select validity"),
});

export const addEditStickersSchema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter title")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")
    .min(2, "Title must be atleast 2 characters")
    .max(30, "Title should not exceed 30 characters"),

  description: yup
    .string()
    .required("Please enter description")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")
    .min(2, "Description must be atleast 2 characters")
    .max(200, "Description should not exceed 200 characters"),

  image: yup
    .mixed()
    .required("Please upload media")
    .test("FileType", "Please upload jpeg/jpg/png/gif file", (value) => {
      if (!value) return true;
      if (!value?.name) {
        const file = value?.split(".")[1];
        return ["jpeg", "jpg", "png", "gif"].includes(file);
      } else {
        return ["image/jpeg", "image/jpg", "image/png", "image/gif"].includes(
          value?.type
        );
      }
    })
    .test("size", "Please upload image file below 5MB", (value) => {
      if (value?.size) {
        return value?.size <= 5 * 1024 * 1024;
      }
      return true;
    }),
});

export const helpCenterSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email")
    .matches(
      // /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      emailRegex,
      "Please enter valid email"
    )
    .max(50, "Email at most 50 characters")
    .required("Please enter email"),
  phoneNumber: yup
    .string()
    .required("Please enter mobile number")
    .matches(phoneRegExp, "Please enter valid mobile number"),
});

export const removeDiamondTickSchema = yup.object().shape({
  reason: yup
    .string()
    .required("Please enter reason")
    .transform((value) => value.replace(/\s/g, ""))
    .matches(startSpace, "start space is not allowed")
    .min(2, "Reason must be atleast 2 characters")
    .max(100, "Reason should not exceed 100 characters"),
});

export const rulesSchema = yup.object().shape({
  ruleName: yup
    .string()
    .required("Please enter rule name")
    .min(3, "Rule name must be at least 3 characters")
    .max(50, "Rule name must not exceed 50 characters"),
  rules: yup.array().of(
    yup.object().shape({
      numberOfEnteries: yup.string().required("Please enter number of entries"),
      numberOfWinners: yup
        .string()
        .required("Please enter number of winners")
        .test(
          "max-winners",
          "Number of winners must not exceed the number of entries",
          function (value) {
            const [from, to] = this.parent.numberOfEnteries
              .split(" to ")
              .map(Number);
            const winners = parseInt(value || 0, 10);
            return winners <= to;
          }
        ),
      winningPrice: yup
        .array()
        .of(
          yup.object().shape({
            rank: yup
              .string()
              .required("Rank is required")
              .matches(
                /^\d+\s*to\s*\d+$/,
                "Rank must be in the format 'X to Y'"
              )
              .test("unique-rank", "Rank must be unique", function (value) {
                const testPath = this?.path;
                const [ruleIndex, winningPriceIndex] = testPath
                  .match(/\[(\d+)\]/g)
                  .map((match) => match.replace(/\[|\]/g, ""));
                const ruleData =
                  this.options.context?.rules[parseInt(ruleIndex)];

                const currentWinningPrice = ruleData?.winningPrice;
                if (currentWinningPrice?.length == 1) {
                  return true;
                }
                const currentWinningObj =
                  currentWinningPrice[winningPriceIndex];
                const prevWinnObj = currentWinningPrice[winningPriceIndex - 1];

                if (prevWinnObj === undefined) {
                  return true;
                }

                const filteredRanks = currentWinningPrice?.filter(
                  (val, index) => {
                    return index !== +winningPriceIndex;
                  }
                );
                const isDuplicate = filteredRanks?.some(
                  (item) => item.rank === value
                );

                return !isDuplicate;
              })
              .test(
                "unique",
                "Rank no. has already been declared",
                function (value) {
                  const { context } = this.options;
                  const [ruleIndex, currentIndex] = this.path
                    .match(/\[(\d+)\]/g)
                    .map((match) => match.replace(/\[|\]/g, ""));

                  // Get the current rule object and its winning prices
                  const ruleData = context?.rules[parseInt(ruleIndex)];
                  const currentWinningPrice = ruleData?.winningPrice || [];

                  // Exclude the current rank being checked from the comparison
                  const filteredRanks = currentWinningPrice.filter(
                    (_, index) => index !== +currentIndex
                  );

                  // Parse the new rank's range (start and end values)
                  const [startValue, endValue] = value
                    .split(" to ")
                    .map(Number);

                  // Check if the new rank overlaps with any existing rank in the same rule
                  const isDuplicate = filteredRanks.some((item) => {
                    const [existingStart, existingEnd] = item.rank
                      .split(" to ")
                      .map(Number); // Parse the existing rank's range

                    // Check if the new range overlaps with the existing range
                    if (
                      (startValue >= existingStart &&
                        startValue <= existingEnd) || // New range start is within existing range
                      (endValue >= existingStart && endValue <= existingEnd) || // New range end is within existing range
                      (startValue <= existingStart && endValue >= existingEnd) // New range fully overlaps existing range
                    ) {
                      return true; // Overlapping ranks found
                    }
                    return false;
                  });

                  return !isDuplicate; // Return false if overlap found, true if no overlap
                }
              ),
            prize: yup
              .string()
              .required("Please enter prize amount")
              .matches(
                /^[0-9]+(\.[0-9]{1,2})?$/,
                "Prize must be a valid number"
              )
              .test(
                "has-winner",
                "Please enter number of winners first",
                function (value) {
                  const { context } = this.options;
                  const [ruleIndex, currentIndex] = this.path
                    .match(/\[(\d+)\]/g)
                    .map((match) => match.replace(/\[|\]/g, ""));

                  const ruleData = context?.rules[parseInt(ruleIndex)];
                  const numberOfWinners = ruleData?.numberOfWinners || [];

                  return numberOfWinners > 0 || !value;
                }
              ),
          })
        )
        .test(
          "exact-winners",
          "The total number of ranks must match the specified number of winners",
          function (value) {
            const { context } = this.options;
            const [ruleIndex] = this.path
              .match(/\[(\d+)\]/)
              .map((match) => match.replace(/\[|\]/g, ""));

            const ruleData = context?.rules[parseInt(ruleIndex)];
            const numberOfWinners = parseInt(
              ruleData?.numberOfWinners || 0,
              10
            );

            // Calculate the total number of winners derived from the ranks
            const totalWinnersFromRanks = value?.reduce((count, item) => {
              const [start, end] = item.rank.split(" to ").map(Number);

              // Calculate winners as (end - start), excluding 0
              return count + (start === 0 ? end : end - start + 1);
            }, 0);

            return totalWinnersFromRanks === numberOfWinners; // Ensure total winners match the specified value
          }
        ),
    })
  ),
});
