import React from 'react'
import Index from '../../../Index'

const FailedLoader = () => {
  return (
  <>
  <Index.Box className='payment-main'>
     <Index.Box class="container-failed">
    <Index.Box class="printer-top"></Index.Box>

    <Index.Box class="paper-container-failed">
        <Index.Box class="printer-bottom"></Index.Box>

        <Index.Box class="paper-failed">
            <Index.Box class="main-contents-failed">
                <Index.Box class="failed-icon">&#10006;</Index.Box>
                <Index.Box class="failed-title">
                    Payment Failed
                </Index.Box>
                
                <Index.Box class="order-details">
                   
                    <Index.Box class="complement-failed">Try again later</Index.Box>
                </Index.Box>
            </Index.Box>
            <Index.Box class="jagged-edge"></Index.Box>
        </Index.Box>
    </Index.Box>
</Index.Box>
</Index.Box>
  </>
  )
}

export default FailedLoader