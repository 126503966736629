import React from 'react'
import Index from '../Index'

import { PuffLoader } from 'react-spinners';

const TableLoader = (props) => {
    return (
        <Index.TableRow className='loading-row'>
            <Index.TableCell className='loading-cell' colSpan={props.colSpan}  >
                <Index.Box className="flex-loader">
                    <PuffLoader
                        color='#d75ccd'
                        className='loader'

                    /> 
                </Index.Box>
            </Index.TableCell>
        </Index.TableRow>
    )
}

export default TableLoader