import React from "react";
import Index from "../../../Index";

const HeroSection = () => {
  return (
    <>
      <Index.Box className="hero-section-grid-box">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="hero-section-content-box">
                <Index.Box className="hero-section-main-heading-box">
                  <Index.Typography className="hero-section-main-heading">
                    Connecting hearts, bridging distances.
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="hero-section-sub-heading-box">
                  <Index.Typography className="hero-section-sub-heading">
                    Stay connected with those who matter most and explore what
                    you love, all in one place.
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="hero-section-button-box">
                <Index.Box className="user-btn-flex hero-section-btn-flex">
                  <Index.Box className="save-btn-main apple-btn-box">
                    <Index.Button className="save-user-btn hero-section-custom-btn-back apple-btn">
                      <>
                        <img
                          src={Index.Svg.appleIcon}
                          className="user-save-icon"
                          alt="Save Icon"
                        />
                        <Index.Box className="btn-text-box">
                          <Index.Typography className="download-text">
                            Download on the
                          </Index.Typography>
                          <Index.Typography className="apple-text">
                            App Store
                          </Index.Typography>
                        </Index.Box>
                      </>
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="save-btn-main apple-btn-box">
                    <Index.Button className="save-user-btn hero-section-custom-btn-back apple-btn">
                      <>
                        <img
                          src={Index.Svg.playStore}
                          className="user-save-icon play-store-icon"
                          alt="Save Icon"
                        />
                        <Index.Box className="btn-text-box google-play-box">
                          <Index.Typography className="download-text google-text">
                            Get it on
                          </Index.Typography>
                          <Index.Typography className="apple-text play-text">
                            Google Play
                          </Index.Typography>
                        </Index.Box>
                      </>
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="hero-section-image-box">
                <img
                  src={Index.Png.heroSectionImage}
                  className="hero-section-image"
                />
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default HeroSection;
