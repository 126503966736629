import React from "react";
import Index from "../../../Index";

const Features = () => {
  return (
    <>
      <Index.Box className="feature-section-grid-box">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="custom-feature-text-main">
                <Index.Box className="feature-text-main-box">
                  <Index.Box className="feature-text-box">
                    <Index.Typography className="feature-text">
                      Features
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="feature-sub-heading-box">
                    <Index.Typography className="featuren-sub-heading">
                      Unlock a World of Possibilities – Elevate Your Social
                      Experience with Seamless Connectivity, Engaging Content,
                      and Real-Time Interactions!
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box
                display="grid"
                className="display-row-add-user"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="feature-main-box">
                    <Index.Box className="feature-prop-icon-box">
                      <img
                        src={Index.Svg.connectIcon}
                        className="feature-prop-icon"
                      />
                    </Index.Box>
                    <Index.Box className="feature-prop-heading-box">
                      <Index.Typography className="feature-prop-heading">
                        Capture moments, Connect Instantly
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="feature-prop-sub-heading-box">
                      Cryp is a collection of short video clips that you may
                      share and enjoy with your friends and community.
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="feature-main-box">
                    <Index.Box className="feature-prop-icon-box">
                      <img
                        src={Index.Svg.insightIcon}
                        className="feature-prop-icon"
                      />
                    </Index.Box>
                    <Index.Box className="feature-prop-heading-box">
                      <Index.Typography className="feature-prop-heading">
                        Profile Insights
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="feature-prop-sub-heading-box">
                      Lorem ipsum dolor sit amet nulla adipiscing elit. Nunc
                      maximus, nec ut commodo
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="feature-main-box">
                    <Index.Box className="feature-prop-icon-box">
                      <img
                        src={Index.Svg.jobIcon}
                        className="feature-prop-icon"
                      />
                    </Index.Box>
                    <Index.Box className="feature-prop-heading-box">
                      <Index.Typography className="feature-prop-heading">
                        Stay close to your world
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="feature-prop-sub-heading-box">
                      Stay connected with friends and followers through
                      high-quality video calls, making it easier to engage with
                      your network in real-time.
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="feature-main-box">
                    <Index.Box className="feature-prop-icon-box">
                      <img
                        src={Index.Svg.contestIcon}
                        className="feature-prop-icon"
                      />
                    </Index.Box>
                    <Index.Box className="feature-prop-heading-box">
                      <Index.Typography className="feature-prop-heading">
                        Diversified Contest
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="feature-prop-sub-heading-box">
                      Enter fascinating video contests, Showcase your ideas,
                      receive likes, and earn incentives through social
                      interaction.
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Features;
