
import Index from "../../../Index";
import { useState } from "react";
import PropTypes from "prop-types";
import { TodayReport } from "./TodayReport";
import { OverviewReport } from "./OverviewReport";
import { autoLogout } from "../../../../redux/slices/adminService";
import { logout, updateRolePermission } from "../../../../redux/slices/AdminSlice";
import PageIndex from "../../../PageIndex";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Home() {
  const [value, setValue] = useState(0);

  const { token } = PageIndex.useSelector((state) => state.admin);
  const navigate = PageIndex.useNavigate()
  const dispatch = PageIndex.useDispatch()

  const handleChange = (event, newValue) => {
    setValue(newValue);
    autoLogout(token).then((res) => {
        if (res?.data?.status == 200) {
          dispatch(updateRolePermission(res?.data?.data));
        }
        else if (res?.response?.data?.status == 401) {
          dispatch(logout());
          navigate("/");
        }
      })
    };

    return (
      <>
        <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Index.Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="admin-tabs-main"
          >
            <Index.Tab
              label="Today Report"
              {...a11yProps(0)}
              className="admin-tab"
            />
            <Index.Tab
              label="Overview Report"
              {...a11yProps(1)}
              className="admin-tab"
            />
          </Index.Tabs>
        </Index.Box>
        <Index.Box>
          {
            value == 0 ? <TodayReport /> : <OverviewReport />
          }
        </Index.Box>
      </>
    );
  }


