import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import PageIndex from "../../../../PageIndex";
import moment from "moment";
const UserEngagement = ({ engagementData ,engagementLoader}) => {
  //all state
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    const filterData = engagementData?.filter((item) => {
      return search
        ? item?.type?.toLowerCase()?.includes(search?.toLowerCase())||
        item?.details?.toLowerCase()?.includes(search?.toLowerCase())
        || new Date(item?.createdAt)
          ?.toLocaleDateString("en-GB")
          ?.toString()
          .includes(search?.toLowerCase())
        : item;
    });
    setFilterData(filterData);
  }, [search, engagementData]);

  return (
    <> 
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Box className="admin-page-title-main">
            <Index.Typography
              className="admin-page-title"
              component="h2"
              variant="h2"
            >
              User Engagement
            </Index.Typography>
          </Index.Box>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    onChange={(e) => {
                      const newValue = e.target.value
                        .replace(/^\s+/, "")
                        .replace(/\s\s+/g, " ");
                      setSearch(newValue);
                    }}
                  />
                  <span className="search-icon-box">
                    <img src={Index.Svg.search} className="search-icon" />
                  </span>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-userlist"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="engagemenet-activity-table page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head cus-table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              S.No.
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Activity Type
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Details
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                              align="center"
                            >
                              Date & Time
                            </Index.TableCell>
                      
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {!engagementLoader ? (
                            pageData?.length > 0 ? (
                              pageData?.map((row, index) => (
                                <Index.TableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.sNo}
                                    {/* {index + 1} */}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >

                                    {row?.type}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                    align="center"
                                  >
                                    {row?.details &&
                                      row?.details?.length > 40 ? (
                                      <PageIndex.LightTooltip
                                        title={
                                          row?.details
                                        }
                                        arrowPlacement="top"
                                      >
                                        {row?.details.slice(0, 40) + "..."}
                                      </PageIndex.LightTooltip>
                                    ) : (
                                      row?.details ?? "-"
                                    )}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                    align="center"
                                  >
                                    {moment(row?.createdAt).format("DD/MM/YYYY ")}
                                    {moment(row?.createdAt).format(" hh:mm A")}
                                  </Index.TableCell>

                                </Index.TableRow>
                              ))
                            ) : (
                              <PageIndex.RecordNotFound colSpan={6} />
                            )
                          ) : (
                            <PageIndex.TableLoader colSpan={6} />
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.Pagination
        fetchData={filterData}
        search={search}
        setPageData={setPageData}
        pageData={pageData}
      />
    </>
  );
};

export default UserEngagement;
