import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getAllPostListByAdmin } from "../../../../redux/slices/adminService";
import moment from "moment";
import { debounce } from "lodash";
import dayjs from "dayjs";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import ReactPlayer from "react-player";

import { imageUrl } from "../../../../config/dataService";

const typeList = [
  { id: 1, value: "today", key: "Today" },
  { id: 2, value: "overview", key: "Overview" },
];

const UserPost = () => {
  const abortController = new AbortController();
  const navigate = PageIndex.useNavigate();
  const location = PageIndex.useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const stateParam = queryParams.get("state");
  const state = JSON.parse(decodeURIComponent(stateParam));
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [loader, setLoader] = useState(true);
  const [postList, setPostList] = useState();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [selectedFromDate, setSelectedFromDate] = useState(
    state?.firstDayOfMonth ? dayjs(state.firstDayOfMonth) : ""
  );
  const [selectedToDate, setSelectedToDate] = useState(
    state?.currentDate ? dayjs(state?.currentDate) : ""
  );
  const [type, setType] = useState(state?.type ? state?.type : "overview");
  const [imagePath, setImagePath] = useState();
  const [imageOpen, setImageOpen] = useState(false);

  const listOfPost = (page) => {
    const data = {
      startDate: selectedFromDate
        ? dayjs(selectedFromDate).format("DD/MM/YYYY")
        : "",
      endDate: selectedToDate ? dayjs(selectedToDate).format("DD/MM/YYYY") : "",
      page: page ? page : currentPage,
      search: search,
      today: type,
      rowsPerPage: rowsPerPage,
    };
    setLoader(true);
    getAllPostListByAdmin(data, { signal: abortController.signal }).then(
      (res) => {
        if (res?.status === 200) {
          setPostList(res?.data);
          if (res?.currentPage) {
            setCurrentPage(res?.currentPage);
          }
          
          setPageCount(res?.totalCount ? res?.totalCount : 0);
          setTimeout(() => {
            setLoader(false);
          }, 1000);
        }
      }
    );
  };
  useEffect(() => {
    setLoader(true);
    const debouncedFetch = debounce(listOfPost, 300);

    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      listOfPost();
    }
    return () => {
      debouncedFetch.cancel();
      abortController.abort();
    };
  }, [search, currentPage, type, rowsPerPage]);

  useEffect(() => {
    setLoader(true);
    const debouncedFetch = debounce(listOfPost, 300);
    if (selectedFromDate || selectedToDate || rowsPerPage) {
      debouncedFetch();
    } else if (selectedFromDate == null || selectedToDate == null) {
      listOfPost();
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [selectedFromDate, selectedToDate, type, rowsPerPage]);

  const handleClearFromDate = () => {
    setSelectedFromDate(null);
    navigate({ state: "" });
    setCurrentPage(1);
  };
  const handleClearToDate = () => {
    setSelectedToDate(null);
    setCurrentPage(1);
    navigate({ state: "" });
  };
  const handleType = (e) => {
    setType(e.target.value);
    setSelectedToDate("");
    setSelectedFromDate("");
    setPostList();
    navigate({ state: "" });
    setCurrentPage(1);
  };

  const handleVideoClick = (index, id) => {
    if (index === currentVideoIndex && id === currentVideoId) {
      setCurrentVideoIndex(null);
      setCurrentVideoId(null);
    } else {
      setCurrentVideoIndex(index);
      setCurrentVideoId(id);
    }
  };
  const handleOpenImage = (image) => {
    if (image) {
      setImageOpen(true);
      setImagePath(image);
    }
  };
  const handleImageClose = () => {
    setImageOpen(false);
    setImagePath("");
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  return (
    <>
      {/* { !loader? */}
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="common-box">
            <Index.Box className="user-list-flex">
              <Index.Box className="admin-page-title-main">
                <Index.Typography
                  className="admin-page-title"
                  component="h2"
                  variant="h2"
                >
                  User Post List
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="contest-search-list user-list-flex">
              {/* <Index.Box className="userlist-btn-flex"> */}
              <Index.Box className="contest-search-list-1">
                {type != "today" && (
                  <>
                    <Index.Box className="admin-text-field-main from-input mb-0">
                      <Index.Box className="input-box input-box-admin input-box-admin2">
                        <Index.Box className="form-group transaction-fieldset">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.InputLabel
                              className="form-lable"
                              id="demo-simple-label"
                            >
                              From
                            </Index.InputLabel>

                            <Index.Stack className="date-picker-mui cus-datepicker">
                              {/* From Date */}

                              <Index.MobileDatePicker
                                className="form-control datepicker"
                                format="DD-MM-YYYY"
                                value={selectedFromDate}
                                disableFuture
                                maxDate={selectedToDate}
                                onChange={(newValue) => {
                                  setSelectedFromDate(newValue);
                                  setCurrentPage(1);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField {...params} />
                                )}
                              />

                              {selectedFromDate ? (
                                <Index.IconButton
                                  className="clear-icon"
                                  onClick={handleClearFromDate}
                                >
                                  <Index.ClearIcon className="icon-clear" />
                                </Index.IconButton>
                              ) : (
                                ""
                              )}
                            </Index.Stack>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="admin-text-field-main from-input mb-0">
                      <Index.Box className="input-box input-box-admin input-box-admin2">
                        <Index.Box className="form-group transaction-fieldset">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.InputLabel
                              className="form-lable"
                              id="demo-simple-label"
                            >
                              To
                            </Index.InputLabel>
                            <Index.Stack className="sale-field date-picker-mui cus-datepicker">
                              {/* To Date */}
                              <Index.MobileDatePicker
                                className=" form-control datepicker"
                                format="DD-MM-YYYY"
                                value={selectedToDate}
                                disableFuture
                                minDate={selectedFromDate}
                                onChange={(newValue) => {
                                  setSelectedToDate(newValue);
                                  setCurrentPage(1);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField {...params} />
                                )}
                              />
                              {selectedToDate ? (
                                <Index.IconButton
                                  className="clear-icon"
                                  onClick={handleClearToDate}
                                >
                                  <Index.ClearIcon className="icon-clear" />
                                </Index.IconButton>
                              ) : (
                                ""
                              )}
                            </Index.Stack>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </>
                )}
              </Index.Box>
              <Index.Box className="contest-search-list-2">
                <Index.Box className="input-box add-user-input request-input-box">
                  <Index.Box className="form-group">
                    <Index.FormControl className="request-dropdown">
                      <Index.Select
                        className="form-control select-drop-list request-drop-list reel-type-dropdown"
                        name="type"
                        value={type}
                        onChange={handleType}
                        displayEmpty
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                      >
                        {typeList &&
                          typeList?.map((val) => (
                            <Index.MenuItem
                              value={val?.value}
                              key={val?.id}
                              className="menuitem"
                            >
                              {val?.key}
                            </Index.MenuItem>
                          ))}
                      </Index.Select>
                    </Index.FormControl>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="user-search-main">
                  <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Search"
                        name="search"
                        value={search}
                        onChange={(e) => {
                          const newValue = e.target.value
                            .replace(/^\s+/, "")
                            .replace(/\s\s+/g, " ");
                          setCurrentPage(1);
                          setSearch(newValue);
                          setPostList();
                          setLoader(true);
                        }}
                      />
                      <span className="search-icon-box">
                        <img src={Index.Svg.search} className="search-icon" />
                      </span>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {/* </Index.Box> */}
            </Index.Box>
            <Index.Box className="admin-dashboard-list-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row-userlist"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dash-box">
                      <Index.Box className="page-table-main postList-table-main ">
                        <Index.TableContainer
                          component={Index.Paper}
                          className="table-container"
                        >
                          <Index.Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                            className="table"
                          >
                            <Index.TableHead className="table-head cus-table-head">
                              <Index.TableRow className="table-row">
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  S.No
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Name
                                </Index.TableCell>
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Username
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Post
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Location
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Created Date
                                </Index.TableCell>

                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Created Time
                                </Index.TableCell>
                                {(rolePermission &&
                                  rolePermission?.roleType?.rolePermission?.includes(
                                    "UserPost_view"
                                  )) ||
                                (rolePermission &&
                                  rolePermission?.isAdmin === true) ? (
                                  <>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Action
                                    </Index.TableCell>
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.TableRow>
                            </Index.TableHead>
                            <Index.TableBody className="table-body">
                              {!loader ? (
                                postList?.length > 0 ? (
                                  postList?.map((row, index) => (
                                    <Index.TableRow
                                      key={row._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        {(currentPage - 1) * 10 + index + 1}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        <span
                                          className="cus-user-name-navigate"
                                          onClick={() => {
                                            navigate("/dashboard/user-view", {
                                              state: {
                                                data: row?.createdBy?._id,
                                                pathName: location.pathname,
                                              },
                                            });
                                          }}
                                        >
                                          {row?.createdBy?.name}
                                        </span>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.createdBy?.userName}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td user-view-post-data"
                                        align="center"
                                      >
                                        <Index.Box className="cus-slider table-swiper">
                                          <Swiper
                                            navigation={true}
                                            modules={[Pagination, Navigation]}
                                            className="mySwiper"
                                            onSlideChange={(swiper) =>
                                              handleVideoClick(
                                                swiper.activeIndex,
                                                row._id
                                              )
                                            }
                                          >
                                            {row &&
                                              row?.profile?.map(
                                                (profile, profileIndex) => (
                                                  <SwiperSlide
                                                    key={`${row._id}_${profileIndex}`}
                                                  >
                                                    {profile?.endsWith(
                                                      ".mp4"
                                                    ) ||
                                                    profile?.endsWith(
                                                      ".mov"
                                                    ) ? (
                                                      <Index.Box className="video-swiper">
                                                        <ReactPlayer
                                                          component="video"
                                                          controls
                                                          width={150}
                                                          height={66}
                                                          className="react-player"
                                                          url={`${imageUrl}${profile}`}
                                                          playing={
                                                            profileIndex ===
                                                              currentVideoIndex &&
                                                            row._id ===
                                                              currentVideoId
                                                          }
                                                          onPlay={() =>
                                                            handleVideoClick(
                                                              profileIndex,
                                                              row._id
                                                            )
                                                          }
                                                        />
                                                      </Index.Box>
                                                    ) : (
                                                      <Index.Box
                                                        className="image-swiper"
                                                        onClick={() =>
                                                          handleOpenImage(
                                                            profile
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          src={`${imageUrl}${profile}`}
                                                        />
                                                      </Index.Box>
                                                    )}
                                                  </SwiperSlide>
                                                )
                                              )}
                                          </Swiper>
                                        </Index.Box>
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {row?.location &&
                                        row?.location?.length > 30 ? (
                                          <PageIndex.LightTooltip
                                            title={row?.location}
                                            arrow
                                          >
                                            <span>
                                              {row?.location.substring(0, 25)}
                                              ...
                                            </span>
                                          </PageIndex.LightTooltip>
                                        ) : (
                                          row?.location || "-"
                                        )}
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {moment(row.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </Index.TableCell>
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        {moment(row.createdAt).format(
                                          "hh:mm A"
                                        )}
                                      </Index.TableCell>
                                      {(rolePermission &&
                                        rolePermission?.roleType?.rolePermission?.includes(
                                          "UserPost_view"
                                        )) ||
                                      (rolePermission &&
                                        rolePermission?.isAdmin === true) ? (
                                        <>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td"
                                            align="center"
                                          >
                                            <Index.Box className="userdata-btn-flex">
                                              <PageIndex.LightTooltip title="View">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    navigate(
                                                      "/dashboard/user-post-view",
                                                      {
                                                        state: {
                                                          postId: row?._id,
                                                          userId:
                                                            row?.createdBy?._id,
                                                        },
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <Index.RemoveRedEyeIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </Index.Box>
                                          </Index.TableCell>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.TableRow>
                                  ))
                                ) : (
                                  <PageIndex.RecordNotFound colSpan={8} />
                                )
                              ) : (
                                <PageIndex.TableLoader colSpan={8} />
                              )}
                            </Index.TableBody>
                          </Index.Table>
                        </Index.TableContainer>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {postList?.length ? (
              <Index.Box className="pagination-main">
                <Index.TablePagination
                  rowsPerPageOptions={[
                    { label: "All", value: -1 },
                    10,
                    25,
                    50,
                    75,
                    100,
                  ]}
                  count={pageCount}
                  rowsPerPage={rowsPerPage}
                  page={currentPage - 1}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  variant="outlined"
                  shape="rounded"
                  className="pagination"
                />
              </Index.Box>
            ) : (
              ""
            )}
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.ImageViewModel
        handleClose={handleImageClose}
        open={imageOpen}
        image={imagePath}
      />
      {/* } */}
    </>
  );
};

export default UserPost;
