import React from "react";
import Index from "../../../Index";

const AboutUs = () => {
  return (
    <>
      <Index.Box sx={{ width: 1 }} className="grid-main">
        <Index.Box className="hero-section-grid-box">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row-add-user"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="hero-section-content-box section-content-box-about ">
                  <Index.Box className="hero-section-main-heading-box">
                    <Index.Typography className="hero-section-main-heading">
                      About Us
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="hero-section-sub-heading-box">
                    <Index.Typography className="hero-section-sub-heading">
                      Crypconect is an all-in-one social platform that combines
                      video contests, AI-driven contest, crypto currency
                      rewards, and social media features. Whether you're looking
                      to showcase your creativity, connect with friends, or
                      engage in unique contests, Crypconect offers a fun and
                      rewarding experience.
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 6",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="hero-section-image-box">
                  <img
                    src={Index.Svg.sampleImage}
                    className="hero-section-image"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AboutUs;
