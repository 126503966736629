import React from "react";
import Index from "../../../Index";

const SuccessLoader = () => {
  return (
    <>
      <Index.Box className="payment-main">
        <Index.Box class="container-success">
          <Index.Box class="printer-top"></Index.Box>

          <Index.Box class="paper-container-success">
            <Index.Box class="printer-bottom"></Index.Box>

            <Index.Box class="paper-success">
              <Index.Box class="main-contents-success">
                <Index.Box class="success-icon">&#10004;</Index.Box>
                <Index.Box class="success-title">Payment Complete</Index.Box>

                <Index.Box class="order-details">
                  <Index.Box class="complement-success">Thank You!</Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box class="jagged-edge"></Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default SuccessLoader;
