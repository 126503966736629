import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  addUpdateCryptoName,
  getCryptoList,
  deleteCryptoName,
  activeDeactiveCrypto,
} from "../../../../redux/slices/adminService";
import PageIndex from "../../../PageIndex";
import { imageUrl } from "../../../../config/dataService";
import { debounce } from "lodash";
import numeral from "numeral";
import FileExcel from "../../../../component/common/Exportexcel";

export default function CryptoList() {
  const { rolePermission } = PageIndex.useSelector((state) => state.admin);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [loader, setLoader] = useState(true);
  const [deleteId, setDeleteId] = useState();
  const [cryptoList, setCryptoList] = useState([]);
  const [liveCryptoList, setLiveCryptoList] = useState([]);
  const [singleCrypto, setSingleCrypto] = useState("");
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [isView, setIsView] = useState(false);
  const [open, setOpen] = useState(false);
  const [excel, setExcel] = useState([]);

  const handleOpen = (data, isViewCome = false) => {

    setSingleCrypto(data);
    setIsView(isViewCome);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsView(false);
  };
  // add user modal
  //Delete Modal
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setDeleteId(id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const deleteCryptoNam = (id) => {
    setIsDeleteButtonDisabled(true);
    deleteCryptoName({ cryptoId: deleteId }).then((data) => {
      handleCloseDelete();
      setIsDeleteButtonDisabled(false);
      setLoader(true);
      fetchCryptoList();
    });
  };

  const handleCryptoName = async (values) => {
    setIsDeleteButtonDisabled(true);

    setLoader(true);
    const urlencoded = new FormData();
    if (singleCrypto?._id) {
      urlencoded.append("id", singleCrypto?._id);
    }
    urlencoded.append("tokenId", values?.tokenId);
    urlencoded.append("tokenName", values?.tokenName);
    urlencoded.append("tokenPrice", values?.tokenPrice);
    urlencoded.append("tokenLogo", values?.tokenLogo);
    urlencoded.append("tokenSymbol", values?.tokenSymbol);
    urlencoded.append("chainType", values?.chainType);
    urlencoded.append("contractAddress", values?.contractAddress);
    urlencoded.append("tokenAddress", values?.tokenAddress);
    urlencoded.append("isPopular", values?.isPopular);

    addUpdateCryptoName(urlencoded).then((data) => {
      if (data?.status == 200) {
        handleClose();
        setIsDeleteButtonDisabled(false);
        fetchCryptoList();
        setLoader(false);
      } else {
        handleClose();
        setIsDeleteButtonDisabled(false);

        fetchCryptoList();
      }
    });
  };
  const handleCryptoStatus = (id) => {
    setLoader(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);

    activeDeactiveCrypto(urlencoded).then((data) => {
      if (data?.status == 200) {
        fetchCryptoList();
      } else {
        setIsDeleteButtonDisabled(false);
        fetchCryptoList();
      }
    });
  };

  const fetchCryptoList = () => {
    setLoader(true);
    getCryptoList({ currentPage, search, rowsPerPage }).then((data) => {
      if (data?.status == 200) {
        setCryptoList(data?.data);
        if (data?.currentPage) {
          setCurrentPage(data?.currentPage);
        }
        setPageCount(data?.totalCount ? data?.totalCount : 0);
        setLoader(false);
        setIsDeleteButtonDisabled(false);
      } else {
        setCryptoList([]);
        setTimeout(() => {
          setLoader(false);
        }, 1000);
      }
    });
  };
  useEffect(() => {
    const debouncedFetch = debounce(fetchCryptoList, 300);
    if (search || currentPage || rowsPerPage) {
      debouncedFetch();
    } else {
      fetchCryptoList();
    }
    return () => {
      debouncedFetch.cancel();
    };
  }, [search, currentPage, rowsPerPage]);

  const initialValues = {
    tokenId: singleCrypto?.tokenId ? singleCrypto?.tokenId : "",
    isPopular: singleCrypto?.isPopular || false,
    tokenName: singleCrypto?.tokenName ? singleCrypto?.tokenName : "",
    tokenPrice: singleCrypto?.tokenPrice ? singleCrypto?.tokenPrice : "",
    chainType: singleCrypto?.chainType ? singleCrypto?.chainType : "",
    tokenLogo: singleCrypto?.tokenLogo ? singleCrypto?.tokenLogo : "",
    tokenSymbol: singleCrypto?.tokenSymbol ? singleCrypto?.tokenSymbol : "",
    tokenAddress: singleCrypto?.tokenAddress ? singleCrypto?.tokenAddress : "",
    contractAddress: singleCrypto?.contractAddress
      ? singleCrypto?.contractAddress
      : "",
  };
  const fileName = "Crypto_list Report";
  useEffect(() => {
    if (cryptoList?.length > 0) {
      const customHeadings = cryptoList?.map((item, index) => ({
        "S.No": index + 1,
        "Crypto Name": item?.tokenName,
        Price: item?.tokenPrice,
        "Price Change % (24hr)": item?.priceChangePercentage,
        Popular: item?.isPopular ? "Popular" : "Non-popular",
      }));

      setExcel(customHeadings);
    }
  }, [cryptoList]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="barge-common-box">
          <Index.Box className="user-list-flex">
            <Index.Box className="admin-page-title-main">
              <Index.Typography
                className="admin-page-title"
                component="h2"
                variant="h2"
              >
                Crypto Coin List
              </Index.Typography>
            </Index.Box>
            <Index.Box className="userlist-btn-flex">
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth_123"
                      type="text"
                      value={search}
                      autoComplete="search"
                      className="form-control"
                      placeholder="Search"
                      onChange={(e) => {
                        const newValue = e.target.value
                          .replace(/^\s+/, "")
                          .replace(/\s\s+/g, " ");
                        setSearch(newValue);
                        setCurrentPage(1);
                        // getCryptoList(newValue);
                      }}
                    />
                    <span className="search-icon-box">
                      <img src={Index.Svg.search} className="search-icon" />
                    </span>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="flex-all user-list-inner-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {(rolePermission &&
                    rolePermission?.roleType?.rolePermission?.includes(
                      "CryptoList_add"
                    )) ||
                  (rolePermission && rolePermission?.isAdmin === true) ? (
                    <>
                      <Index.Button
                        className="adduser-btn btn-primary"
                        onClick={handleOpen}
                      >
                        <img
                          src={Index.Svg.plus}
                          className="plus-icon"
                          alt="plus icon"
                        />
                        Add Crypto
                      </Index.Button>
                    </>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
              <Index.Box>
                <FileExcel apiData={excel} fileName={fileName} />
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-userlist"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dash-box">
                    <Index.Box className="cryptolist-table-main action-column page-table-main">
                      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}

                      <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                        // sx={{ maxHeight:400 }}
                      >
                        <Index.Table
                          stickyHeader
                          aria-label="sticky table"
                          sx={{ minWidth: 650 }}
                          className="table"
                        >
                          <Index.TableHead className="table-head cus-table-head">
                            <Index.TableRow className="table-row">
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                S.No
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Token Logo
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Crypto Name
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Price
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Price Change % (24hr)
                              </Index.TableCell>
                              <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                                align="center"
                              >
                                Popular
                              </Index.TableCell>
                              {(rolePermission &&
                                rolePermission?.roleType?.rolePermission?.includes(
                                  "CryptoList_edit"
                                )) ||
                              (rolePermission &&
                                rolePermission?.isAdmin === true) ? (
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Status
                                </Index.TableCell>
                              ) : (
                                ""
                              )}
                              {rolePermission?.roleType?.rolePermission?.includes(
                                "CryptoList_view"
                              ) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "CryptoList_edit"
                              ) ||
                              rolePermission?.roleType?.rolePermission?.includes(
                                "CryptoList_delete"
                              ) ||
                              (rolePermission &&
                                rolePermission?.isAdmin === true) ? (
                                <Index.TableCell
                                  component="th"
                                  variant="th"
                                  className="table-th"
                                  align="center"
                                >
                                  Actions
                                </Index.TableCell>
                              ) : (
                                ""
                              )}
                            </Index.TableRow>
                          </Index.TableHead>
                          <Index.TableBody className="table-body">
                            {!loader ? (
                              cryptoList?.length > 0 ? (
                                cryptoList?.map((row, index) => (
                                  <Index.TableRow
                                    key={row._id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      <Index.Box className="crypto-token-image">
                                        <img
                                          src={`${imageUrl}${row?.tokenLogo}`}
                                        />
                                      </Index.Box>
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.tokenName ? row?.tokenName : "-"}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {/* {row?.tokenPrice ? row?.tokenPrice : "-"} */}
                                      {row?.tokenPrice
                                        ? parseFloat(
                                            row?.tokenPrice?.toFixed(8)
                                          )
                                        : 0}
                                      {/* {row?.tokenPrice
                                        ? Number.isInteger(row.tokenPrice)
                                          ? numeral(row.tokenPrice).format("0")
                                          : numeral(row.tokenPrice).format(
                                              "0.000a"
                                            )
                                        : 0} */}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {/* {row?.priceChangePercentage ? row?.priceChangePercentage : "-"} */}
                                      {row?.priceChangePercentage
                                        ? parseFloat(row?.priceChangePercentage)
                                            .toFixed(8)
                                            .replace(/\.?0+$/, "")
                                        : 0}
                                      {/* {row?.priceChangePercentage
                                        ? Number.isInteger(
                                            row.priceChangePercentage
                                          )
                                          ? numeral(
                                              row.priceChangePercentage
                                            ).format("0")
                                          : numeral(
                                              row.priceChangePercentage
                                            ).format("0.000a")
                                        : 0} */}
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="td"
                                      variant="td"
                                      scope="row"
                                      className="table-td"
                                      align="center"
                                    >
                                      {row?.isPopular
                                        ? "Popular"
                                        : "Non-popular"}
                                    </Index.TableCell>
                                    {(rolePermission &&
                                      rolePermission?.roleType?.rolePermission?.includes(
                                        "CryptoList_edit"
                                      )) ||
                                    (rolePermission &&
                                      rolePermission?.isAdmin === true) ? (
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="center"
                                      >
                                        <Index.Box className="table-view-toggle-btn">
                                          <Index.FormControlLabel
                                            disabled={isDeleteButtonDisabled}
                                            control={
                                              <Index.IOSSwitch
                                                checked={row?.isActive === true}
                                                sx={{ m: 1 }}
                                                className="MuiSwitch-track-active"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  handleCryptoStatus(row?._id);
                                                }}
                                              />
                                            }
                                            className="switch-lable"
                                          />
                                        </Index.Box>
                                      </Index.TableCell>
                                    ) : (
                                      ""
                                    )}
                                    {rolePermission?.roleType?.rolePermission?.includes(
                                      "CryptoList_view"
                                    ) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "CryptoList_edit"
                                    ) ||
                                    rolePermission?.roleType?.rolePermission?.includes(
                                      "CryptoList_delete"
                                    ) ||
                                    (rolePermission &&
                                      rolePermission?.isAdmin === true) ? (
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td"
                                        align="center"
                                      >
                                        <Index.Box className="userdata-btn-flex">
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "CryptoList_view"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="View">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    handleOpen(row, true);
                                                  }}
                                                >
                                                  <Index.RemoveRedEyeIcon className="view-eye-Icon" />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "CryptoList_edit"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="Edit">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    handleOpen(row);
                                                  }}
                                                >
                                                  <Index.EditIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {(rolePermission &&
                                            rolePermission?.roleType?.rolePermission?.includes(
                                              "CryptoList_delete"
                                            )) ||
                                          (rolePermission &&
                                            rolePermission?.isAdmin ===
                                              true) ? (
                                            <>
                                              <PageIndex.LightTooltip title="Delete">
                                                <Index.IconButton
                                                  color="primary"
                                                  aria-label="upload picture"
                                                  component="label"
                                                  onClick={() => {
                                                    handleOpenDelete(row._id);
                                                  }}
                                                >
                                                  <Index.DeleteIcon />
                                                </Index.IconButton>
                                              </PageIndex.LightTooltip>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </Index.Box>
                                      </Index.TableCell>
                                    ) : (
                                      ""
                                    )}
                                  </Index.TableRow>
                                ))
                              ) : (
                                <PageIndex.RecordNotFound colSpan={7} />
                              )
                            ) : (
                              <PageIndex.TableLoader colSpan={7} />
                            )}
                          </Index.TableBody>
                        </Index.Table>
                      </Index.TableContainer>
                      {/* </Paper> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {cryptoList.length ? (
            <Index.Box className="pagination-main">
              <Index.TablePagination
                rowsPerPageOptions={[
                  { label: "All", value: -1 },
                  10,
                  25,
                  50,
                  75,
                  100, 
                ]}
                count={pageCount}
                rowsPerPage={rowsPerPage}
                page={currentPage - 1}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                variant="outlined"
                shape="rounded"
                className="pagination"
              />
            </Index.Box>
          ) : (
            ""
          )}
        </Index.Box>
      </Index.Box>

      {/* Delete Modal */}
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deleteCryptoNam}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
      <PageIndex.AddModel
        title={singleCrypto?.tokenName ? "Edit" : "Add"}
        fieldName="cryptoName"
        placeholder="Crypto Name"
        open={open}
        initialValues={initialValues}
        schema={PageIndex.addCryptoSchema}
        singleCrypto={singleCrypto}
        handleSubmit={handleCryptoName}
        handleClose={handleClose}
        liveCryptoList={liveCryptoList}
        isView={isView}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
    </>
  );
}
