import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  Collapse,
  Stack,
  Pagination,
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Svg from "../assets/Svg";
import Png from "../assets/Png";
import PaidLable from "./common/PaidLable";
import FailedLable from "./common/FailedLable";
import PendingLable from "./common/PendingLable";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PrimaryButton from "../component/common/PrimaryButton.js";
import { ToastContainer, toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';
import "react-toastify/dist/ReactToastify.css";
import Header from "./admin/defaulLayout/Header";
import Sidebar from "./admin/defaulLayout/Sidebar";
import SettingsIcon from '@mui/icons-material/Settings';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import ReportIcon from '@mui/icons-material/Report';
import ContactsIcon from '@mui/icons-material/Contacts';
import DiamondIcon from '@mui/icons-material/Diamond';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';


import Jpg from "../assets/Jpg.js"
const Toast = (message) => {
  toast("Wow so easy!");
  return <ToastContainer />;
};

const Index = {
  NotificationsIcon,
  SettingsIcon,
  Box,
  FormHelperText,
  Link,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  List,
  ListItem,
  Svg,
  Png,
  Jpg,
  Sidebar,
  Header,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  PaidLable,
  FailedLable,
  PendingLable,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,
  PrimaryButton,
  Toast,
  LoadingOverlay,
  EditIcon,
  DeleteIcon,
  RemoveRedEyeIcon,
  CurrencyBitcoinIcon,
  ReportIcon,
  ContactsIcon,
  DiamondIcon,
  ReceiptLongIcon,
  DashboardRoundedIcon,

};
export default Index
