import React from "react";
import HeroSection from "./HeroSection";
import Statistics from "./Statistics";
import Features from "./Features";
import Connection from "./Connection";
import AboutUs from "./AboutUs";
import Testimonial from "./Testimonial";
import DownloadButton from "./DownloadButton";
import Index from "../../../Index";

const LandingPageContent = () => {
  return (
    <>
      <Index.Box className="main-hero-section" id="home">
        <HeroSection />
      </Index.Box>
      <Index.Box className="main-stats-section">
        <Statistics />
      </Index.Box>
      <Index.Box className="feature-main-section" id="feature">
        <Features />
      </Index.Box>
      <Index.Box className="connection-main-section">
        <Connection />
      </Index.Box>
      <Index.Box className="about-main-section" id="about">
        <AboutUs />
      </Index.Box>
      <Index.Box className="testimonial-section">
        <Testimonial />
      </Index.Box>
      <Index.Box className="download-btn-section">
        <DownloadButton />
      </Index.Box>
    </>
  );
};

export default LandingPageContent;
