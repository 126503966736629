import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {autoLogout, deleteReel, getSingleReelByAdmin, suspendUser } from "../../../../redux/slices/adminService";
import { imageUrl } from "../../../../config/dataService";
import moment from "moment/moment";
import "./userReelsView.css";
import UserReelComments from "./UserReelComments";
import { logout, updateRolePermission } from "../../../../redux/slices/AdminSlice";
import numeral from "numeral";

const UserReelsView = () => {
  const divRef = useRef(null);
  const location = PageIndex.useLocation();
  const dispatch = PageIndex.useDispatch();
  const [reeldata, setReeldata] = useState({});
  const [loader, setLoader] = useState(true);
  const [value, setValue] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [search, setSearch] = useState("");
  const [suspendUserId, setSuspendUserId] = useState();
  const [openSuspend, setOpenSuspend] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [imagePath, setImagePath] = useState();
  const [openImage, setOpenImage] = useState(false);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const singledata = location?.state?.postId
  const navigate = PageIndex.useNavigate()
  const { rolePermission,token } = PageIndex.useSelector((state) => state.admin);
  

  const singleReelData = () => {
    setLoader(true);
    getSingleReelByAdmin(singledata).then((res) => {
      if (res) {
        setReeldata(res?.data);
        setFilterData(res?.data);
        setPageData(res?.data?.likes);
        setLoader(false);
      }
    });
  };



  const handleChange = (event, newValue) => {
    setValue(newValue);
    autoLogout(token).then((res) => {
      if (res?.data?.status == 200) {
        dispatch(updateRolePermission(res?.data?.data));
      }
      else if (res?.response?.data?.status == 401) {
        dispatch(logout());
        navigate("/");
      }
    })
  };


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    let filterData;
    if (value == 0) {
      filterData = reeldata?.likes?.filter((item) => {
        return search
          ? item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
          item?.userName?.toLowerCase()?.includes(search.toLowerCase()) ||
          new Date(item?.createdAt)
            ?.toLocaleDateString("en-GB")
            ?.toString()
            .includes(search?.toLowerCase())
          : item;
      });
    } else {
      filterData = reeldata?.commentId?.filter((item) => {
        return search
          ? item?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
          item?.userName?.toLowerCase()?.includes(search.toLowerCase()) ||
          new Date(item?.createdAt)
            ?.toLocaleDateString("en-GB")
            ?.toString()
            .includes(search?.toLowerCase())
          : item;
      });
    }
    setPageData(filterData);
  }, [search, value]);

  useEffect(() => {
    singleReelData();
  }, []);


  // suspend user
  const handleOpenSuspend = (type) => {
    setOpenSuspend(true);
    setSuspendUserId(singledata);
  };
  const handleCloseSuspend = () => setOpenSuspend(false);
  const suspendUserByAdmin = () => {
    setIsDeleteButtonDisabled(true);
    suspendUser(reeldata?.createdBy?._id).then((res) => {
      setIsDeleteButtonDisabled(false);
      singleReelData();
      if (res?.status === 200) {
        handleCloseSuspend();
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };

  // post delete

  const handleOpenDelete = () => {
    setOpenDelete(true);
    setDeleteId(reeldata?._id);
  };
  const handleCloseDelete = () => setOpenDelete(false);

  const deletePost = (id) => {
    setIsDeleteButtonDisabled(true);
    deleteReel({ id: deleteId }).then((res) => {
      handleCloseDelete();
      setIsDeleteButtonDisabled(false)
      if (res?.status === 200) {
        navigate("/dashboard/user-reels");
      } else {
        setTimeout(() => {
          setIsDeleteButtonDisabled(false);
        }, 3000);
      }
    });
  };

  const handleOpenImage = (image) => {
    if (image) {
      setOpenImage(true);
      setImagePath(image);
    }
  };

  const handleImageClose = () => {
    setImagePath('')
    setOpenImage(false);
  }

  return (
    <><Index.Box className='user-details-back-btn'>
      <PageIndex.BackButton onClick={() => navigate("/dashboard/user-reels")} />
    </Index.Box>
      <Index.Box className="user-detail-section">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            className="display-row-add-user"
            gridTemplateColumns="repeat(12, 1fr)"
          // gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 3",
              }}
              className="grid-column"
            >
              <Index.Box className="left-wrape">
                <Index.Box className="profile-wrape">
                  <Index.Box className="image-wrape"
       onClick={() => handleOpenImage(reeldata?.createdBy?.profile)}>
                    <img
                      src={
                        reeldata?.createdBy?.profile &&
                          reeldata?.createdBy?.profile.length > 0
                          ? reeldata?.createdBy?.reeldata instanceof Blob
                            ? URL.createObjectURL(reeldata?.createdBy?.profile)
                            : `${imageUrl}${reeldata?.createdBy?.profile}`
                          : PageIndex?.Jpg?.dummyImage
                      }
                      className="user-post-post-img"
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="detail">

                  <label>Full Name</label>
                  <p> {reeldata?.createdBy?.name ? reeldata?.createdBy?.name : "-"}</p>
                  <label> Email</label>
                  <p> {reeldata?.createdBy?.email ? reeldata?.createdBy?.email : "-"}</p>
                  <label>Username</label>
                  <p >
                    <span
                    className=" cus-user-name-navigate"
                    onClick={() => {
                      navigate('/dashboard/user-view',
                        {
                          state: {
                            data:reeldata?.createdBy?._id,
                            postId: singledata,
                            pathName: location.pathname
                          },
                        }
                      )
                    }}>
                    {reeldata?.createdBy?.userName ? reeldata?.createdBy?.userName : '-'}
                    </span>
                  </p>
                  <label>Phone Number</label>
                  <p>
                    {reeldata?.createdBy?.phoneNumber ? `${reeldata?.createdBy?.countryCode} ${reeldata?.createdBy?.phoneNumber}` : '-'}
                  </p>
                  <label>Engagement Ratio</label>
                  <p> {reeldata?.allLikeComment ? `${reeldata?.allLikeComment?.toFixed(2)}%`: '-'}</p>
                  <label>Country</label>
                  <p>  {reeldata?.createdBy?.country ? reeldata?.createdBy?.country : "-"}</p>

                  <label>Bio</label>
                  <p>
                    {reeldata?.createdBy?.Bio &&
                      reeldata?.createdBy?.Bio?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={reeldata?.createdBy?.Bio}
                      >
                        {reeldata?.createdBy?.Bio.slice(0, 25) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      reeldata?.createdBy?.Bio || "-"
                    )}
                  </p>
                  <label>Suspend</label>
                  <p>
                    {reeldata?.createdBy?.isSuspended === true
                      ? "Yes"
                      : "No"}
                  </p>
                  <label>Links</label>
                  {/* <p>{reeldata?.createdBy?.links ? reeldata?.createdBy?.links : "-"}</p> */}
                  <p>
                    {reeldata?.createdBy?.links &&
                      reeldata?.createdBy?.links?.length > 25 ? (
                      <PageIndex.LightTooltip
                        placement="right"
                        title={reeldata?.createdBy?.links?.split(',').map((item)=><p>{item}</p>)}
                      >
                        {reeldata?.createdBy?.links.slice(0, 25) + "..."}
                      </PageIndex.LightTooltip>
                    ) : (
                      reeldata?.createdBy?.links?.Bio || "-"
                    )}

                  </p>
                  <label>Following Count</label>
                  <p>
                    {/* {reeldata?.followingCount ? reeldata?.followingCount : 0} */}
                    {reeldata?.followingCount 
                              ? Number.isInteger(reeldata?.followingCount )
                                ? numeral(reeldata?.followingCount ).format('0')
                                : numeral(reeldata?.followingCount ).format('0.000a')
                              : 0}

                  </p>
                  <label>Followers Count</label>
                  <p>
                    {/* {reeldata?.followersCount ? reeldata?.followersCount : 0} */}
                    {reeldata?.followersCount 
                              ? Number.isInteger(reeldata?.followersCount )
                                ? numeral(reeldata?.followersCount ).format('0')
                                : numeral(reeldata?.followersCount ).format('0.000a')
                              : 0}

                  </p>
                </Index.Box>

                {(rolePermission && rolePermission?.isAdmin === true) ? (
                  <PageIndex.LightTooltip title={reeldata?.createdBy?.isSuspended != true ? "Suspend" : "Unsuspend"}>
                    <Index.IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      // disabled={userdata?.isSuspended === true}
                      onClick={() => {
                        handleOpenSuspend();
                      }}
                    >

                      <img
                        src={reeldata?.createdBy?.isSuspended != true ? Index.Png.blockUser : Index.Png.unBlockUser}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                      {/* <Index.DeleteIcon className="usermailwarning-img" /> */}
                    </Index.IconButton>
                  </PageIndex.LightTooltip>) : ""}
              </Index.Box>
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 12",
                md: "span 12",
                lg: "span 9",
              }}
              className="grid-column"
            >
              <Index.Box className="right-wrape">
                <Index.Box className="owner-detail-wrap">
                  <Index.Box className="mini-card">
                    <Index.Box className="owner-image-wrape ">
                      <Index.Box className="user-post-post-img-box user-reel-video-box">
                        <Index.Box className='video-swiper' >
                          <Index.ReactPlayer
                            component="video"
                            controls
                            width={200}
                            height={150}
                            className="react-player"
                            url={`${imageUrl}${reeldata?.media}`}
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="start-section owener-detail">
                    <Index.Box className="detail profile-detail">
                      <label>Caption</label>
                      <p className="post-description">
                        {reeldata?.caption && reeldata?.caption?.length > 40 ? (
                          <PageIndex.LightTooltip
                            title={
                              <Index.Box
                              ref={divRef} 
                                dangerouslySetInnerHTML={{
                                  __html: reeldata?.caption ? reeldata?.caption : "-",
                                }}
                              ></Index.Box>
                            }
                            placement="right"
                          >
                            <Index.Box
                                  ref={divRef} 
                              dangerouslySetInnerHTML={{
                                __html: reeldata?.caption
                                  ? reeldata?.caption?.slice(0, 45) + "..."
                                  : "-",
                              }}
                            ></Index.Box>
                          </PageIndex.LightTooltip>
                        ) : (
                          <Index.Box
                          ref={divRef} 
                            dangerouslySetInnerHTML={{
                              __html: reeldata?.caption ? reeldata?.caption : "-",
                            }}
                          ></Index.Box>
                        )}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Location</label>
                      <p className="post-description">
                        {reeldata?.location && reeldata?.location?.length > 30 ? (
                          <PageIndex.LightTooltip
                            placement="top"
                            title={reeldata?.location}
                          >
                            {reeldata?.location.slice(0, 30) + "..."}
                          </PageIndex.LightTooltip>
                        ) : (
                          reeldata?.location || "-"
                        )}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Created at</label>
                      <p className="post-description">
                        {moment(reeldata?.createdAt).format("DD/MM/YYYY hh:mm A")}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Allow Comments</label>
                      <p className="post-description">
                        {reeldata?.allowComments == true ? "Yes" : "No"}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Visible to Everyone</label>
                      <p className="post-description">
                        {reeldata?.allowComments == true ? "Yes" : "No"}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Total Views</label>
                      <p className="post-description">
                        {/* {reeldata?.totalViews ? reeldata?.totalViews : 0} */}
                        {reeldata?.totalViews 
                              ? Number.isInteger(reeldata?.totalViews )
                                ? numeral(reeldata?.totalViews ).format('0')
                                : numeral(reeldata?.totalViews ).format('0.000a')
                              : 0}
                      </p>
                    </Index.Box>
                    <Index.Box className="detail profile-detail">
                      <label>Engagement Ratio</label>
                      <p className="post-description">
                        {reeldata?.engamentRatio ?reeldata?.engamentRatio  : 0}
                      </p>
                    </Index.Box>
                    <Index.Box className="userdata-btn-flex">
                      <PageIndex.LightTooltip placement="right" title="Delete post">
                        <Index.IconButton
                          color="primary"
                          aria-label="upload picture"
                          disabled={loader}
                          component="label"
                          onClick={() => {
                            handleOpenDelete();
                          }}
                        >
                          <Index.DeleteIcon className="usermailwarning-img" />
                        </Index.IconButton>
                      </PageIndex.LightTooltip>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="table-wrape">
                  <Index.Box className="external-tab-box">
                    <Index.Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      className="admin-tabs-main"
                    >
                      <Index.Tab
                        label="Likes"
                        {...a11yProps(0)}
                        className="admin-tab"
                      />

                      <Index.Tab
                        label="Comments"
                        {...a11yProps(1)}
                        className="admin-tab"
                      />
                    </Index.Tabs>
                  </Index.Box>
                  <Index.Box className="barge-common-box">
                    <Index.Box className="common-box">
                      <Index.Box className="user-list-flex">
                        <Index.Box className="admin-page-title-main">
                          <Index.Typography
                            className="admin-page-title tittle-like-costam"
                            component="h2"
                            variant="h2"
                          >
                            {value == 0 ? "Likes" : "Comments"}
                          </Index.Typography>
                        </Index.Box>
                        {value == 0 && 1 ? (
                          <Index.Box className="userlist-btn-flex">
                            <Index.Box className="user-search-main">
                              <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                  <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Search user"
                                    onChange={handleSearch}
                                  />
                                  <span className="search-icon-box">
                                    <img
                                      src={Index.Svg.search}
                                      className="search-icon"
                                    />
                                  </span>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        ) : (
                          ""
                        )}
                      </Index.Box>

                      {value == 0 ? (
                        <Index.Box className="admin-dash-box">
                          <Index.Box className="page-table-main like-table-main">
                            <Index.TableContainer
                              component={Index.Paper}
                              className="table-container"
                            >
                              <Index.Table
                                stickyHeader
                                aria-label="simple table"
                                className="table"
                              >
                                <Index.TableHead className="table-head cus-table-head">
                                  <Index.TableRow className="table-row">
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      S.No.
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Name
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Username
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Profile
                                    </Index.TableCell>

                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Created at
                                    </Index.TableCell>
                                    <Index.TableCell
                                      component="th"
                                      variant="th"
                                      className="table-th"
                                      align="center"
                                    >
                                      Bio
                                    </Index.TableCell>
                                  </Index.TableRow>
                                </Index.TableHead>
                                <Index.TableBody className="table-body">
                                  {!loader ? (
                                    pageData?.length > 0 ? (
                                      pageData?.map((row, index) => (
                                        <Index.TableRow key={row?._id}>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {index + 1}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.name}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                           >
                                            <span className=" cus-user-name-navigate"
                                             onClick={() => {
                                              navigate('/dashboard/user-view',
                                                {
                                                  state: {
                                                    data: row?.userId,
                                                    postId: singledata,
                                                    pathName: location.pathname
                                                  },
                                                }
                                              )
                                            }}
                                            >{row?.userName}</span>
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            <img
                                              src={
                                                row?.profile
                                                  ? row?.profile instanceof Blob
                                                    ? URL.createObjectURL(
                                                      row?.profile
                                                    )
                                                    : `${imageUrl}${row?.profile}`
                                                  : PageIndex?.Jpg?.dummyImage
                                              }
                                              className="user-post-user-img"
                                              onClick={() => handleOpenImage(row?.profile)}
                                           />
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {moment(row.createdAt).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="center"
                                          >
                                            {row?.Bio &&
                                              row?.Bio?.length > 15 ? (
                                              <PageIndex.LightTooltip
                                                placement="top"
                                                title={row?.Bio}
                                              >
                                                {row?.Bio.slice(0, 15) + "..."}
                                              </PageIndex.LightTooltip>
                                            ) : (
                                              row.Bio || "-"
                                            )}
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      ))
                                    ) : (

                                      <PageIndex.RecordNotFound colSpan={6} />
                                    )
                                  ) : (
                                    <PageIndex.TableLoader colSpan={6} />
                                  )}
                                </Index.TableBody>
                              </Index.Table>
                            </Index.TableContainer>
                          </Index.Box>
                        </Index.Box>
                      ) : (
                        <UserReelComments
                         comments={reeldata.commentsData}
                          postId={singledata}
                          setImageOpen={setOpenImage}
                          setImagePath={setImagePath}
                          />
                      )}
                    </Index.Box>
                    {value == 0 && 1 ? (
                      <Index.Box className="pagination-costom">
                        <PageIndex.Pagination
                          fetchData={
                            value == 0
                              ? filterData?.likes
                              : filterData?.commentId
                          }
                          setPageData={setPageData}
                          pageData={
                            value == 0 ? pageData?.likes : pageData?.commentId
                          }
                        />
                      </Index.Box>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <PageIndex.SuspendModal
        openSuspend={openSuspend}
        handleCloseSuspend={handleCloseSuspend}
        handleSuspendRecord={suspendUserByAdmin}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
      <PageIndex.DeleteModal
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        handleDeleteRecord={deletePost}
        isDeleteButtonDisabled={isDeleteButtonDisabled}
      />
       <PageIndex.ImageViewModel
          handleClose={handleImageClose}
          open={openImage}
          image={imagePath}
        />
    </>
  );
};

export default UserReelsView;
