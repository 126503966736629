import Index from "../../../Index";
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";

const Testimonial = () => {
  return (
    <>
      <Index.Box className="test-main-section">
        <Index.Box className="test-heading-box">
          <Index.Typography className="test-heading">
            Testimonials
          </Index.Typography>
        </Index.Box>
        <Index.Box className="test-sub-head-box">
          <Index.Typography className="test-sub-heading">
            Voices That Inspire, Stories That Shine!
          </Index.Typography>
        </Index.Box>
        <Index.Box className="test-slider-box">
          <Swiper
            className="mySwiper testimonials-slider"
            slidesPerView={3}
            spaceBetween={50}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000, // Adjust delay as needed (3000ms = 3 seconds)
              disableOnInteraction: false, // Continue autoplay even after user interaction
            }}
            modules={[Pagination, Autoplay]}
          >
            <SwiperSlide>
              <Index.Box className="testimonials-card-main">
                <Index.Box className="testimonials-prop-icon-box">
                  <img
                    src={Index.Svg.personIcon}
                    className="feature-prop-icon"
                  />
                </Index.Box>
                <Index.Box className="testimonials-card-content">
                  <Index.Typography
                    className="testimonials-card-text"
                    component="p"
                  >
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc maximus nulla ut commodo sagittis"
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="testimonials-card-heading">
                  <Index.Typography
                    component="h4"
                    variant="h4"
                    className="heading-card-text"
                  >
                    Adam Smith
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </SwiperSlide>
            <SwiperSlide>
              <Index.Box className="testimonials-card-main">
                <Index.Box className="testimonials-prop-icon-box">
                  <img
                    src={Index.Svg.personIcon}
                    className="feature-prop-icon"
                  />
                </Index.Box>
                <Index.Box className="testimonials-card-content">
                  <Index.Typography
                    className="testimonials-card-text"
                    component="p"
                  >
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc maximus nulla ut commodo sagittis"
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="testimonials-card-heading">
                  <Index.Typography
                    component="h4"
                    variant="h4"
                    className="heading-card-text"
                  >
                    Adam Smith
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </SwiperSlide>
            <SwiperSlide>
              <Index.Box className="testimonials-card-main">
                <Index.Box className="testimonials-prop-icon-box">
                  <img
                    src={Index.Svg.personIcon}
                    className="feature-prop-icon"
                  />
                </Index.Box>
                <Index.Box className="testimonials-card-content">
                  <Index.Typography
                    className="testimonials-card-text"
                    component="p"
                  >
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc maximus nulla ut commodo sagittis"
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="testimonials-card-heading">
                  <Index.Typography
                    component="h4"
                    variant="h4"
                    className="heading-card-text"
                  >
                    Adam Smith
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </SwiperSlide>
            <SwiperSlide>
              <Index.Box className="testimonials-card-main">
                <Index.Box className="testimonials-prop-icon-box">
                  <img
                    src={Index.Svg.personIcon}
                    className="feature-prop-icon"
                  />
                </Index.Box>
                <Index.Box className="testimonials-card-content">
                  <Index.Typography
                    className="testimonials-card-text"
                    component="p"
                  >
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc maximus nulla ut commodo sagittis"
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="testimonials-card-heading">
                  <Index.Typography
                    component="h4"
                    variant="h4"
                    className="heading-card-text"
                  >
                    Adam Smith
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </SwiperSlide>
            <SwiperSlide>
              <Index.Box className="testimonials-card-main">
                <Index.Box className="testimonials-prop-icon-box">
                  <img
                    src={Index.Svg.personIcon}
                    className="feature-prop-icon"
                  />
                </Index.Box>
                <Index.Box className="testimonials-card-content">
                  <Index.Typography
                    className="testimonials-card-text"
                    component="p"
                  >
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc maximus nulla ut commodo sagittis"
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="testimonials-card-heading">
                  <Index.Typography
                    component="h4"
                    variant="h4"
                    className="heading-card-text"
                  >
                    Adam Smith
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </SwiperSlide>
          </Swiper>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default Testimonial;
