import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getSingleSubAdmin } from "../../../../redux/slices/adminService";

const ViewSubAdmin = () => {
  const navigate = PageIndex.useNavigate()
  const params = PageIndex.useLocation();
  const editData = params?.state;
  const [loading, setLoading] = useState(true)
  const [subAdminData, setSubAdminData] = useState(true)

  const fetchSubAdminList = () => {
    getSingleSubAdmin(editData?._id).then((data) => {
      if (data?.status == 200) {
        setSubAdminData(data?.data);

        setLoading(false);
      } else {
        setSubAdminData([]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchSubAdminList();
  }, []);

  return (
    <>
      {!loading ? (<Index.Box className="dashboard-content add-user-containt">
        <PageIndex.BackButton onClick={() => navigate("/dashboard/admin-master-list")} />
        <Index.Box className="barge-common-box">
          <Index.Typography
            className="admin-page-title cus-heading"
            component="h2"
            variant="h2"
          >
             Admin Master Details
          </Index.Typography>
          <Index.Box className="add-user-data-main">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row-add-user"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        disabled
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder=""
                        name="name"
                        value={subAdminData?.name}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Email
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        disabled
                        id="fullWidth"
                        className="form-control email-input "
                        placeholder=""
                        name="email"
                        value={subAdminData?.email}

                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Phone Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        disabled
                        id="fullWidth"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="form-control"
                        placeholder=""
                        name="phoneNumber"
                        value={subAdminData.phoneNumber}

                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 6",
                  }}
                  className="grid-column"
                >

                  <Index.Box className="input-box add-user-input">
                    <Index.FormHelperText className="form-lable">
                      Role Type
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        disabled
                        id="fullWidth"
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="roleName"
                        value={subAdminData?.roleType?.roleName}

                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="input-box add-user-input">
                          <Index.FormHelperText className="form-lable">
                            Address{" "}
                            <span className="mandatory-asterisk">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextareaAutosizeed
                              fullWidth
                              id="fullWidth"
                              // type="text"
                              className="form-control contest-text-autosize"
                              placeholder="Address"
                              name="address"
                              disabled
                              value={subAdminData.address}
                         
                            />
                          </Index.Box>
                       
                        </Index.Box>
                      </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>) : <PageIndex.Loading />}

    </>
  );
};

export default ViewSubAdmin;
