import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getTermsCondtion } from "../../../../redux/slices/adminService";

const TermsCondition = () => {
  const [cmsText, setCmsText] = useState("");
  const fetchTermsAndCondition = () => {
    getTermsCondtion().then((data) => {
      if (data?.status == 200) {
        setCmsText(data?.data?.description);
      } else {
      }
    });
  };
  useEffect(() => {
    fetchTermsAndCondition();
  }, []);
  return (
    <>
      <Index.Box className="main-terms">
        <Index.Box className="cus-container">
          <Index.Box className="terms-header">
            <Index.Typography
              variant="h1"
              component="h1"
              className="terms-header-title"
            >
              Terms & Conditions
            </Index.Typography>
          </Index.Box>
          <Index.Box className="terms-body">
            <Index.Box dangerouslySetInnerHTML={{ __html: cmsText }} />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default TermsCondition;
